import React from "react";
import { styled } from "@mui/material/styles";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";
import clsx from "clsx";

import { PrimaryButton, SecondaryButton } from "@/components/buttons";
import { colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";

const PREFIX = "CTA";

const classes = {
  centered: `${PREFIX}-centered`,
  button: `${PREFIX}-button`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
};

const CtaStyled = styled("div", {
  shouldForwardProp: notForwarded(["styles"]),
})<{
  styles: { theme: string };
}>(({ theme, styles }) => ({
  "& > *": {
    margin: "1em 0 0",
  },
  "& > *:first-child": {
    marginRight: "1em",
  },

  [`&.${classes.centered}`]: {
    textAlign: "center",
  },

  [`& .${classes.button}`]: {
    borderRadius: 8,
    fontSize: "17px",
    padding: "0.7em 2em",
    [theme.breakpoints.down(392)]: {
      padding: "0.7em 0.5em",
    },
  },

  [`& .${classes.primary}`]: {
    background: themes?.[styles.theme]?.primaryBackground,
    color: themes?.[styles.theme]?.primaryColor,
    "&:hover": {
      background: themes?.[styles.theme]?.primaryHoverBackground,
    },
  },

  [`& .${classes.secondary}`]: {
    color: themes?.[styles.theme]?.secondaryColor,
  },
}));

const themes = {
  white01: {
    primaryBackground: colors.white01,
    primaryColor: colors.blueIris,
    primaryHoverBackground: colors.white01,
    secondaryColor: colors.gray01,
  },
  blueIris: {
    primaryBackground: colors.blueIris,
    primaryColor: colors.white01,
    primaryHoverBackground: colors.blueIris,
    secondaryColor: colors.blueIris,
  },
  deepPurple: {
    primaryBackground: colors.deepPurple,
    primaryColor: colors.white01,
    primaryHoverBackground: colors.blueIris,
    secondaryColor: colors.deepPurple,
  },
};

export interface CTAProps {
  centered: boolean;
  primary: {
    link: string;
    text: string;
  };
  secondary: {
    link: string;
    text: string;
  };
  tertiary: {
    link: string;
    text: string;
  };
  theme: Extract<keyof typeof colors, "white01" | "blueIris" | "deepPurple">;
}

const CTA = ({
  primary,
  secondary,
  tertiary,
  centered,
  theme = "white01",
}: CTAProps) => {
  return (
    <CtaStyled
      className={centered ? classes.centered : undefined}
      styles={{ theme }}
    >
      <PrimaryButton
        className={clsx(classes.button, classes.primary)}
        component={Link}
        to={primary.link}
      >
        {primary.text}
      </PrimaryButton>
      {secondary ? (
        <SecondaryButton
          className={clsx(classes.button, classes.secondary)}
          component={Link}
          to={secondary.link}
        >
          {secondary.text}
        </SecondaryButton>
      ) : null}
    </CtaStyled>
  );
};

export default CTA;
