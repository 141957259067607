import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";

const ButtonStyled = styled(MuiButton, {
  shouldForwardProp: notForwarded(["styles"]),
})<{
  styles: ButtonProps;
}>(({ theme, styles }) => ({
  background: transformColor(styles.background) || colors.periwinkle,
  color: transformColor(styles.color) || colors.white01,
  "&:has(+ &)": {
    marginRight: theme.spacing(1.5),
  },
  "&:hover": {
    background: transformColor(styles.background) || colors.periwinkle,
    opacity: 0.7,
  },
}));

export interface ButtonProps {
  background?: Color | string;
  color?: Color | string;
  label?: string;
}

export const Button = ({
  background = "periwinkle",
  color = "white01",
  label,
  ...props
}: ButtonProps & Omit<MuiButtonProps, "color">) => {
  return (
    <ButtonStyled
      disableElevation
      variant="contained"
      size="medium"
      styles={{
        background,
        color,
      }}
      children={label}
      {...props}
    />
  );
};
