import React from "react";
import { styled } from "@mui/material/styles";

import { Tag, TagProps } from "./Tag";

const TagsStyled = styled("div")(({ theme }) => ({
  display: "block",
}));

export interface TagsProps {
  activeTag?: string;
  size?: TagProps["size"];
  tags: TagProps[];
}

export const Tags = ({
  activeTag,
  size: sizeBase = "small",
  tags,
}: TagsProps) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <TagsStyled>
      {tags?.map(({ name, slug, color, size = sizeBase }) => (
        <Tag
          key={name}
          name={name}
          slug={slug}
          color={color}
          size={size}
          isActive={activeTag === slug}
        />
      ))}
    </TagsStyled>
  );
};
