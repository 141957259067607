import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

import { colors } from "@/theme/colors";
import { PrimaryButton, PrimaryButtonProps } from "@/components/buttons";
import { notForwarded } from "@/utils/notForwarded";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";

const DownloadStyled = styled("div", {
  shouldForwardProp: notForwarded(["hasMedia", "spacing"]),
})<{ hasMedia: boolean; spacing?: Spacing }>(
  ({ theme, hasMedia, spacing = "default" }) => ({
    backgroundColor: colors.white01,
    border: `1px solid ${colors.gray04}`,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(spacingMap[spacing], "auto"),
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], "auto"),
    },
    ...(hasMedia
      ? {
          [theme.breakpoints.up("sm")]: {
            textAlign: "left",
          },
        }
      : {}),
    ".download__image": {
      border: "none",
      borderRadius: theme.shape.borderRadius,
      display: "block",
      margin: "0 auto",
      maxWidth: 170,
      height: "auto",
    },
    ".download__title": {
      color: colors.prismaticBlack,
      fontSize: theme.spacing(3),
      fontWeight: 700,
      marginBottom: theme.spacing(0.5),
    },
    ".download__copy": {
      color: colors.gray08,
      fontSize: theme.spacing(2),
    },
    ".download__primary": {
      marginTop: theme.spacing(2),
    },
  })
);

interface DownloadProps extends Pick<PrimaryButtonProps, "to" | "label"> {
  copy?: string;
  image?: string;
  spacing?: Spacing;
  title?: string;
}

export const Download = ({
  copy,
  image,
  label,
  spacing,
  title,
  to,
}: DownloadProps) => {
  return (
    <DownloadStyled hasMedia={Boolean(image)} spacing={spacing}>
      <Grid container spacing={3} alignItems="center">
        {image ? (
          <Grid item xs={12} sm={3}>
            <img
              className="download__image"
              src={image}
              alt={title}
              loading="lazy"
              width={153}
              height={198}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm>
          <Typography className="download__title">{title}</Typography>
          <Typography className="download__copy">{copy}</Typography>
          <PrimaryButton
            component={Link}
            to={to}
            className="download__primary"
            size="large"
          >
            {label}
          </PrimaryButton>
        </Grid>
      </Grid>
    </DownloadStyled>
  );
};
