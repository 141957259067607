import React from "react";
import {
  styled,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";

export const TooltipStyled = styled(
  ({ className, ...props }: MuiTooltipProps) => (
    <MuiTooltip
      enterTouchDelay={0}
      classes={{ tooltip: className }}
      {...props}
    />
  )
)(({ theme }) => ({
  "&.MuiTooltip-tooltip": {
    padding: theme.spacing(2),
    fontSize: 14,
    fontWeight: 400,
  },
}));

interface TooltipProps
  extends Pick<
    MuiTooltipProps,
    "title" | "children" | "placement" | "enterDelay"
  > {
  disabled?: boolean;
}

export const Tooltip = ({ disabled, children, ...props }: TooltipProps) =>
  disabled ? children : <TooltipStyled {...props}>{children}</TooltipStyled>;
