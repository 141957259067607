import React from "react";
import { styled } from "@mui/material";

import { notForwarded } from "@/utils/notForwarded";

const MediaStylesStyled = styled("div", {
  shouldForwardProp: notForwarded(["styles"]),
})<{ styles: Omit<MediaStylesProps, "children"> }>(({ theme, styles }) => ({
  margin: styles.margin ?? theme.spacing(2, "auto"),
  maxWidth: styles.maxWidth ?? "100%",
  width: "100%",
  "img, video": {
    border: styles.noBorder ? "none !important" : undefined,
    boxShadow: styles.shadow || "none !important",
    margin: "0  !important",
    width: "100% !important",
  },
}));

interface MediaStylesProps {
  children?: React.ReactNode;
  margin?: string;
  maxWidth?: string | number;
  noBorder?: boolean;
  shadow?: string;
}

export const MediaStyles = ({
  children,
  margin,
  maxWidth,
  noBorder,
  shadow,
}: MediaStylesProps) => {
  return (
    <MediaStylesStyled styles={{ noBorder, margin, maxWidth, shadow }}>
      {children}
    </MediaStylesStyled>
  );
};
