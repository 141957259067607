import React from "react";
import { Field as FormikFieldBase } from "formik";

const Field = ({ children, label: labelInput, ...props }) => (
  <FormikFieldBase {...props}>
    {({ field, form }) => {
      const { name } = field;
      const { dirty, errors, touched } = form;

      const fieldError = errors[name];
      const error = dirty && touched[name] && Boolean(fieldError);

      const label = error
        ? `${labelInput.split("*").join("")} ${fieldError}`
        : labelInput;

      return children({ field: { ...field, error, label }, form });
    }}
  </FormikFieldBase>
);

export default Field;
