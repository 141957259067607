import { createTheme, Theme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

import { colors } from "@/theme/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.periwinkle,
    },
    secondary: {
      main: colors.turquoise,
    },
    error: red,
    background: {
      default: colors.white01,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
        a: {
          transition: "all 0.08s",
          color: colors.blueIris,
          textDecoration: "none",
          "&:hover": {
            opacity: 0.6,
          },
        },
        img: {
          maxWidth: "100%",
        },
        blockquote: {
          color: colors.deepPurple,
          borderLeft: `2px solid ${colors.blueIris}`,
          padding: "0.1em 1em",
          margin: "2em 0",
          fontSize: "1.5em",
          "@media (max-width: 700)": {
            fontSize: "1.2em",
          },
        },
        ".gatsby-image-wrapper-constrained": {
          display: "block !important",
        },
        ".MuiPopover-paper": {
          maxWidth: "none !important",
        },
        "::selection": {
          backgroundColor: colors.bluePurple,
          color: colors.white01,
          padding: 2,
          borderRadius: 5,
        },
        "div[data-consent-manager-dialog]": {
          "> div": {
            zIndex: 2147483002,
          },
          "table, p, form": {
            color: colors.gray08,
            fontFamily: "Roboto,Helvetica,Arial,sans-serif",
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "0.00938em",
            lineHeight: 1.6,
          },
          "h2, th": {
            color: colors.gray09,
          },
          a: {
            color: colors.blueIris,
            textDecoration: "none",
            "&:hover, &:focus, &:active": {
              color: colors.blueIris,
              opacity: 0.75,
            },
          },
          button: {
            fontFamily: "Roboto,Helvetica,Arial,sans-serif",
            fontWeight: 500,
            lineHeight: 1.75,
            textTransform: "uppercase",
          },
          'button[type="submit"]': {
            background: colors.periwinkle,
          },
          "form label": {
            cursor: "pointer",
          },
          'input[type="radio"]': {
            cursor: "pointer",
            marginTop: 0,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          willChange: "unset !important",
        },
        tooltip: {
          color: colors.gray09,
          backgroundColor: "rgba(255, 255, 255, 0.98)",
          "@supports (backdrop-filter: blur(20px))": {
            backdropFilter: "blur(20px)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          },
          padding: "2em",
          fontSize: "1.2em",
          boxShadow: "rgb(218, 218, 218) 0px 0px 13px 0px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "@supports (backdrop-filter: blur(20px))": {
            backdropFilter: "blur(10px)",
          },
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        },
        paper: {
          width: "100%",
          minHeight: "20em",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(19, 23, 24, 0.75)",
          "@supports (backdrop-filter: blur(20px))": {
            backgroundColor: "rgba(19, 23, 24, 0.75)",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "3.5em",
        },
        h3: {
          fontSize: "2.5em",
        },
        h6: {
          fontWeight: 100,
          fontSize: "1.5em",
        },
      },
    },
  },
});

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}
