import * as React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { MDXProvider } from "@mdx-js/react";

import components from "@/components/mdx";
import { Banner } from "@/components/banner";
import { theme } from "@/theme/index";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Banner>
          <MDXProvider components={components}>{props.children}</MDXProvider>
        </Banner>
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
