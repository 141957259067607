import React from "react";
import { styled } from "@mui/material/styles";
import { useWindowSize } from "react-use";

import Animate from "@/components/animate";
import { SectionPoints } from "@/components/section/section-points";

const PREFIX = "SectionMedia";

const classes = {
  content: `${PREFIX}-content`,
};

const SectionMediaStyled = styled(Animate)(({ theme }) => ({
  order: 2,
  maxWidth: "50%",

  [theme.breakpoints.down(1000)]: {
    maxWidth: "90%",
    order: 2,
    paddingBottom: "2em",
    paddingTop: "2em",
  },

  [`& .${classes.content}`]: {
    position: "relative",
    [theme.breakpoints.down(1000)]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
    [theme.breakpoints.down(500)]: {
      paddingTop: "1em",
    },
  },
}));

export const SectionMedia = ({
  side,
  children,
  points,
  animation,
  style = {},
  ...props
}) => {
  const styles = { ...style };

  return (
    <SectionMediaStyled animation={animation} style={styles} {...props}>
      <div className={classes.content}>
        <SectionPoints points={points} />
        {children}
      </div>
    </SectionMediaStyled>
  );
};
