import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Grid, styled } from "@mui/material";

import { notForwarded } from "@/utils/notForwarded";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";

interface MediaStyledProps {
  children: React.ReactNode;
  to?: MediaProps["to"];
}

const MediaStyled = styled(
  ({ to, ...props }: MediaStyledProps) =>
    to ? <a href={to} {...props} /> : <div {...props} />,
  {
    shouldForwardProp: notForwarded([
      "flexDirection",
      "imageBorder",
      "imageHeight",
      "spacing",
    ]),
  }
)<{
  flexDirection?: "row" | "column";
  imageBorder?: boolean;
  imageHeight?: number;
  spacing?: Spacing;
}>(
  ({
    flexDirection,
    imageBorder,
    imageHeight,
    spacing = "default",
    theme,
  }) => ({
    color: "currentColor",
    display: "block",
    margin: theme.spacing(spacingMap[spacing], 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], 0),
      textAlign: "center",
    },
    ".media__grid": {
      alignItems: "center",
      ...(flexDirection === "row"
        ? {
            flexDirection: "row",
          }
        : {
            flexDirection: "column",
            textAlign: "center",
          }),
    },
    ".media__img": {
      display: "flex",
      img: {
        ...(!imageBorder
          ? {
              border: 0,
              borderRadius: 0,
            }
          : {}),
        maxHeight: imageHeight ?? "auto",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
        width: 475,
        [theme.breakpoints.down("md")]: {
          width: 275,
        },
        [theme.breakpoints.down("sm")]: {
          width: 175,
        },
      },
    },
    ".media__title": {
      ...theme.typography.h6,
      margin: theme.spacing(0, 0, 0.5),
    },
    ".media__copy": {
      ...theme.typography.body1,
      margin: 0,
    },
  })
);

interface MediaProps {
  children?: React.ReactNode;
  copy?: string;
  image: string;
  imageBorder?: boolean;
  imageHeight?: number;
  imageWidth?: number;
  imagePlacement?: "left" | "top";
  spacing?: Spacing;
  title?: string;
  to?: string;
}

export const Media = ({
  children,
  copy,
  image,
  imageBorder = true,
  imageHeight,
  imageWidth,
  imagePlacement = "left",
  title,
  to,
}: MediaProps) => {
  const flexDirection =
    imagePlacement === "left" && (title || copy) ? "row" : "column";

  const imageProps: { width?: number; height?: number } = {};

  if (imageWidth) imageProps.width = imageWidth;
  if (imageHeight) imageProps.height = imageHeight;

  return (
    <MediaStyled
      to={to}
      flexDirection={flexDirection}
      imageBorder={Boolean(imageBorder)}
      imageHeight={imageHeight}
    >
      <Grid container spacing={3} className="media__grid">
        <Grid
          className="media__img"
          item
          sm={flexDirection === "row" ? 4 : undefined}
          xs={12}
        >
          <img
            src={image}
            alt={title || "prismatic image"}
            loading="lazy"
            {...imageProps}
          />
        </Grid>
        {title || copy || children ? (
          <Grid item sm={flexDirection === "row" ? 8 : undefined} xs={12}>
            {title ? <h6 className="media__title">{title}</h6> : null}
            {copy ? <p className="media__copy">{copy}</p> : null}
            {children}
          </Grid>
        ) : null}
      </Grid>
    </MediaStyled>
  );
};
