import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "gatsby";
import { rgbToHex } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";

import hexToRgba from "@/utils/hexToRgba";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";
import { Tags, TagsProps } from "@/page-components/blog/Tags";

const PREFIX = "Card";

const classes = {
  badge: `${PREFIX}-badge`,
  backgroundImageContainer: `${PREFIX}-backgroundImageContainer`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  overline: `${PREFIX}-overline`,
  underline: `${PREFIX}-underline`,
  tags: `${PREFIX}-tags`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  link: `${PREFIX}-link`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
};

const CharlieCardStyled = styled(Link, {
  shouldForwardProp: notForwarded(["styles"]),
})<{
  styles: {
    backgroundColor?: Color | string;
    backgroundImage?: boolean;
    backgroundOpacity?: number;
    color?: Color | string;
  };
}>(({ theme, styles }) => ({
  border: 0,
  borderRadius: 8,
  boxShadow: `0 0 0 1px ${colors.gray04}`,
  color: transformColor(styles.color) || colors.gray12,
  display: "flex",
  flexDirection: "column",
  margin: 0,
  minHeight: 300,
  padding: 0,
  position: "relative",
  transition: "all .15s ease-in-out",
  width: "100%",
  "&:hover": {
    boxShadow: theme.shadows[10],
    opacity: "initial",
  },
  [`& .${classes.badge}`]: {
    width: 85,
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      width: 105,
    },
  },
  [`& .${classes.backgroundImageContainer}`]: {
    borderRadius: 8,
    bottom: 0,
    height: "100%",
    left: 0,
    objectFit: "cover",
    objectPosition: "right bottom",
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    zIndex: 0,
  },
  [`& .${classes.backgroundImage}`]: {
    borderRadius: 8,
    height: "100%",
    overflow: "hidden",
    img: {
      border: 0,
      borderRadius: 8,
      bottom: 0,
      height: "100%",
      left: 0,
      margin: 0,
      maxWidth: "none",
      objectFit: "cover",
      objectPosition: "right bottom",
      padding: 0,
      position: "absolute",
      right: 0,
      top: 0,
      width: "100%",
    },
  },
  [`& .${classes.container}`]: {
    backgroundColor: styles.backgroundColor
      ? hexToRgba(
          rgbToHex(transformColor(styles.backgroundColor)),
          styles.backgroundOpacity || 1
        )
      : "none",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    fontSize: 16,
    fontWeight: 400,
    height: "100%",
    justifyContent: "center",
    padding: theme.spacing(5, 15, 5, 4),
    position: "relative",
    width: "100%",
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 19, 5, 4),
    },
  },
  [`& .${classes.content}`]: {},
  [`& .${classes.overline}`]: {
    display: "block",
    fontSize: 14,
    fontWeight: 600,
    margin: "0 0 1.1em",
    textTransform: "uppercase",
    color:
      !styles.backgroundImage && !styles.backgroundColor
        ? colors.turquoise
        : "inherit",
  },
  [`& .${classes.tags}`]: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.underline}`]: {
    display: "block",
    fontSize: 13,
    margin: "0 0 1.25em",
  },
  [`& .${classes.title}`]: {
    color: "currentColor",
    fontSize: 22,
    fontWeight: 700,
    margin: "0 0 .50em",
  },
  [`& .${classes.description}`]: {
    lineHeight: 1.8,
    margin: "0 0 2em",
  },
  [`& .${classes.link}`]: {
    fontWeight: 700,
  },
  [`&.${classes.small}`]: {
    [theme.breakpoints.up(960)]: {
      minHeight: 300,
      "& $container": {
        fontSize: 16,
        padding: "3em",
      },
      "& $title": {
        fontSize: 28,
      },
    },
  },
  [`&.${classes.medium}`]: {
    [theme.breakpoints.up(960)]: {
      minHeight: 325,
      "& $container": {
        fontSize: 18,
        padding: "3em",
      },
      "& $title": {
        fontSize: 32,
      },
    },
  },
  [`&.${classes.large}`]: {
    [theme.breakpoints.up(960)]: {
      minHeight: 575,
      "& $container": {
        fontSize: 22,
        padding: "5em",
        paddingRight: "30%",
      },
      "& $title": {
        fontSize: 48,
      },
    },
  },
}));

export interface CharlieCardProps {
  backgroundColor?: Color | string;
  badge?: string | GraphQLQuery;
  backgroundImage?: string | GraphQLQuery;
  backgroundOpacity?: number;
  color?: Color | string;
  description?: string;
  link: string;
  linkLabel?: string;
  linkTarget?: string;
  overline?: string;
  size?: "small" | "medium" | "large";
  tags: TagsProps["tags"];
  title: string;
  underline?: string;
}

export const CharlieCard = ({
  backgroundColor,
  backgroundImage,
  backgroundOpacity = 1,
  badge,
  color = "gray12",
  description,
  link,
  linkLabel = "Read More",
  linkTarget,
  overline,
  size = "small",
  tags,
  title,
  underline,
}: CharlieCardProps) => {
  return (
    <CharlieCardStyled
      className={classes[size]}
      styles={{
        backgroundColor,
        backgroundImage: Boolean(backgroundImage),
        backgroundOpacity,
        color,
      }}
      target={linkTarget}
      to={link}
    >
      {badge ? (
        <div className={classes.badge}>
          {typeof badge === "string" ? (
            <img src={badge} alt={title} loading="lazy" />
          ) : (
            <GatsbyImage
              objectFit="cover"
              image={badge?.childImageSharp?.gatsbyImageData}
              alt={title}
            />
          )}
        </div>
      ) : null}
      {backgroundImage ? (
        <div className={classes.backgroundImageContainer}>
          {typeof backgroundImage === "string" ? (
            <div className={classes.backgroundImage}>
              <img src={backgroundImage} alt={title} loading="lazy" />
            </div>
          ) : (
            <GatsbyImage
              objectFit="cover"
              objectPosition="right bottom"
              className={classes.backgroundImage}
              image={backgroundImage?.childImageSharp?.gatsbyImageData}
              alt={title}
            />
          )}
        </div>
      ) : null}
      <div className={classes.container}>
        <div className={classes.content}>
          {overline && <span className={classes.overline}>{overline}</span>}
          {tags?.length ? (
            <span className={classes.tags}>
              <Tags tags={tags} />
            </span>
          ) : null}
          <h3 className={classes.title}>{title}</h3>
          {underline && <span className={classes.underline}>{underline}</span>}
          <p className={classes.description}>{description}</p>
          <span className={classes.link}>{linkLabel}</span>
        </div>
      </div>
    </CharlieCardStyled>
  );
};
