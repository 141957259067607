import React from "react";
import { styled } from "@mui/material/styles";
import {
  UilCheck,
  UilMinus,
  UilStar,
  UilQuestionCircle,
} from "@iconscout/react-unicons";
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "./mdx";
import { Tooltip } from "@/components/Tooltip";
import { colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import clsx from "clsx";

const ComparisonTableStyled = styled(TableContainer, {
  shouldForwardProp: notForwarded(["maxWidth", "columnWidth"]),
})<{ maxWidth?: number; columnWidth?: number }>(
  ({ theme, maxWidth, columnWidth = 250 }) => ({
    maxWidth: maxWidth || "auto",
    margin: theme.spacing(5, "auto"),
    ".comparisonTable": {
      minWidth: 750,
    },
    ".comparisonTable__head": {
      background: colors.lightGray,
      fontWeight: 500,
    },
    ".comparisonTable__head__featuredCell": {
      borderLeft: `3px solid transparent`,
      borderRight: `3px solid transparent`,
      color: colors.white01,
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: -0.5,
      padding: 0,
      position: "relative",
    },
    ".comparisonTable__head__featuredCell__box": {
      backgroundColor: colors.mintGreen,
      borderLeft: `3px solid ${colors.mintGreen}`,
      borderRight: `3px solid ${colors.mintGreen}`,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      bottom: -1,
      left: -3,
      padding: theme.spacing(2),
      position: "absolute",
      width: columnWidth + 3,
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    ".comparisonTable__cell": {
      textAlign: "center",
      width: columnWidth,
    },
    ".comparisonTable__cell__head": {
      fontSize: 15,
      minWidth: columnWidth,
    },
    ".comparisonTable__cell__head__groupName": {
      fontSize: 15,
    },
    ".comparisonTable__featuredCell": {
      borderLeft: `3px solid ${colors.mintGreen}`,
      borderRight: `3px solid ${colors.mintGreen}`,
    },
    ".comparisonTable__featuredCell-noCTA": {
      borderLeft: `3px solid ${colors.mintGreen}`,
      borderRight: `3px solid ${colors.mintGreen}`,
      borderBottom: `3px solid ${colors.mintGreen}`,
    },
    ".comparisonTable__footer__cell": {
      height: 68,
      verticalAlign: "top",
    },
    [`.comparisonTable__footer__cta`]: {
      display: "block",
      marginTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "&:first-of-type": {
        marginTop: 0,
      },
    },
    ".comparisonTable__footer__featuredCell": {
      borderLeft: `3px solid transparent`,
      borderRadius: 8,
      borderRight: `3px solid transparent`,
      padding: 0,
      position: "relative",
    },
    ".comparisonTable__footer__featuredCell__box": {
      backgroundColor: colors.mintGreen,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderLeft: `3px solid ${colors.mintGreen}`,
      borderRight: `3px solid ${colors.mintGreen}`,
      left: -3,
      padding: theme.spacing(2),
      position: "absolute",
      top: -1,
      width: columnWidth + 3,
    },
    ".comparisonTable__footer": {
      ".MuiTableCell-root": {
        borderBottom: 0,
      },
    },
    ".comparison__available": {
      color: colors.mintGreen,
    },
    ".comparison__unavailable": {
      color: colors.gray07,
    },
    ".comparisonTable__infoIcon": {
      color: colors.gray07,
      height: 20,
      width: 20,
    },
    ".comparisonTable__image": {
      border: 0,
      borderRadius: 0,
      height: 25,
      marginRight: theme.spacing(0.75),
    },
    ".comparisonTable__tag": {
      borderColor: colors.mintGreen,
      color: colors.mintGreen,
      fontSize: 12,
      fontWeight: 700,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(-0.5),
    },
    ".comparisonTable__head__featuredCell .comparisonTable__tag": {
      borderColor: colors.white01,
      color: colors.white01,
    },
  })
);

const PREFIX = "MobileComparisionList";

const classes = {
  card: `${PREFIX}-card`,
  cardFeatured: `${PREFIX}-card__featured`,
  cardHeader: `${PREFIX}-card__header`,
  cardHeaderFeatured: `${PREFIX}-card__header__featured`,
  cardHeaderNotFeatured: `${PREFIX}-card__header_not-featured`,
  cardImage: `${PREFIX}-card__image`,
  cardTag: `${PREFIX}-card__tag`,
  cardBody: `${PREFIX}-card__body`,
  cardBodyNoCTA: `${PREFIX}-card__body--noCTA`,
  cardNotFeatured: `${PREFIX}-card__featured`,
  cardBodySubCategory: `${PREFIX}-card__sub-category`,
  featuresList: `${PREFIX}-features__list`,
  questionIcon: `${PREFIX}-question__icon`,
  comparisionAvailable: `${PREFIX}-comparisionAvailable`,
  comparisionNotAvailable: `${PREFIX}-comparisionNotAvailable`,
  comparisionCustomIcon: `${PREFIX}-comparisionCustomIcon`,
  cardFooterCTA: `${PREFIX}-card__footer`,
};

const MobileComparisionListStyled = styled("div")(({ theme }) => ({
  display: "grid",
  gap: 12,
  listStyle: "none",
  [`& .${classes.card}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  [`& .${classes.cardNotFeatured}`]: {
    border: `1px solid ${colors.gray04}`,
  },

  // Header
  [`& .${classes.cardHeader}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    "& h4": {
      color: "#000000",
      fontWeight: 500,
    },
    "& div": {
      display: "flex",
    },
  },
  [`& .${classes.cardHeaderFeatured}`]: {
    backgroundColor: colors.mintGreen,
    "& h4": {
      color: colors.white01,
      fontSize: 20,
      fontWeight: 700,
    },
  },
  [`& .${classes.cardHeaderNotFeatured}`]: {
    borderBottom: `1px solid ${colors.gray04}`,
  },
  [`& .${classes.cardImage}`]: {
    border: 0,
    borderRadius: 0,
    marginLeft: theme.spacing(0.75),
  },
  [`& .${classes.cardTag}`]: {
    zIndex: 1,
    borderColor: colors.mintGreen,
    color: colors.mintGreen,
    fontSize: 12,
    fontWeight: 700,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    right: 0,
    top: 0,
  },

  // Body
  [`& .${classes.cardBody}`]: {
    borderLeft: `3px solid ${colors.mintGreen}`,
    borderRight: `3px solid ${colors.mintGreen}`,
  },
  [`& .${classes.cardBodyNoCTA}`]: {
    borderLeft: `3px solid ${colors.mintGreen}`,
    borderRight: `3px solid ${colors.mintGreen}`,
    borderBottom: `3px solid ${colors.mintGreen}`,
  },
  [`& .${classes.cardBodySubCategory}`]: {
    padding: theme.spacing(2.5),
    background: colors.lightGray,
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.featuresList}`]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.questionIcon}`]: {
    color: colors.gray07,
    height: 20,
    width: 20,
  },
  [`& .${classes.comparisionAvailable}`]: {
    color: colors.mintGreen,
  },
  [`& .${classes.comparisionNotAvailable}`]: {
    color: colors.gray07,
  },
  [`& .${classes.comparisionCustomIcon}`]: {
    color: colors.yellow,
  },

  // Footer
  [`& .${classes.cardFooterCTA}`]: {
    display: "block",
    marginTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: colors.mintGreen,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderLeft: `3px solid ${colors.mintGreen}`,
    borderRight: `3px solid ${colors.mintGreen}`,
    left: -3,
    padding: theme.spacing(2),
    top: -1,
    "&:first-of-type": {
      marginTop: 0,
    },
  },
}));

interface ComparisonTableProps {
  comparison: {
    categories: {
      cta?: {
        featured?: boolean;
        link: string;
        text: string;
      }[];
      image: {
        publicURL?: string;
      };
      featured?: boolean;
      subTitle?: string;
      tag?: string;
      title: string;
    }[];
    features: {
      groupName: string;
      items: {
        excluding?: string[];
        specific?: { including: string[]; title: string; tooltip?: string }[];
        title: string;
        tooltip?: string;
      }[];
    }[];
  };
  columnWidth?: number;
  maxWidth?: number;
  adaptOnMobile?: boolean;
}

export const ComparisonTable = ({
  comparison: { categories, features },
  columnWidth,
  maxWidth,
  adaptOnMobile = true,
}: ComparisonTableProps) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  if (adaptOnMobile && matchesMd) {
    return (
      <MobileComparisionListStyled>
        {categories.map((category) => {
          return (
            <section
              className={clsx(
                classes.card,
                category.featured
                  ? classes.cardFeatured
                  : classes.cardNotFeatured
              )}
            >
              <div
                className={clsx(
                  classes.cardHeader,
                  category.featured
                    ? classes.cardHeaderFeatured
                    : classes.cardHeaderNotFeatured
                )}
              >
                <div>
                  <h4>{category.title}</h4>{" "}
                  {category.image?.publicURL ? (
                    <img
                      className={clsx(classes.cardImage)}
                      src={category.image.publicURL}
                      alt={category.title}
                      width={25}
                      height={25}
                      loading="lazy"
                    />
                  ) : null}
                </div>
                {category.tag ? (
                  <Chip
                    size="small"
                    variant="outlined"
                    className={classes.cardTag}
                    label={category.tag}
                  />
                ) : null}
              </div>
              <div
                className={clsx(
                  category.featured
                    ? category.cta === null
                      ? classes.cardBodyNoCTA
                      : classes.cardBody
                    : ""
                )}
              >
                {features.map(({ groupName, items }, indx) => {
                  return (
                    <React.Fragment key={indx}>
                      {groupName ? (
                        <div className={clsx(classes.cardBodySubCategory)}>
                          {groupName}
                        </div>
                      ) : null}
                      <List className={classes.featuresList}>
                        {items.map((item) => {
                          const specificFeature = item.specific?.find(
                            ({ including }) =>
                              including.includes(category.title)
                          );
                          return (
                            <ListItem disablePadding key={item.title}>
                              {specificFeature ? (
                                <ListItemIcon>
                                  <UilCheck
                                    className={clsx(
                                      classes.comparisionAvailable
                                    )}
                                    size={category.featured ? 32 : 26}
                                  />
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon>
                                  {item.excluding?.includes(category.title) ? (
                                    <UilMinus
                                      className={clsx(
                                        classes.comparisionNotAvailable
                                      )}
                                      size={26}
                                    />
                                  ) : (
                                    <UilCheck
                                      className={clsx(
                                        classes.comparisionAvailable
                                      )}
                                      size={category.featured ? 32 : 26}
                                    />
                                  )}
                                </ListItemIcon>
                              )}
                              <ListItemText
                                primary={
                                  <>
                                    {item.title}
                                    {specificFeature ? ":" : ""}{" "}
                                    {specificFeature ? (
                                      <React.Fragment>
                                        <span>{specificFeature.title}</span>
                                        {specificFeature.tooltip ? (
                                          <>
                                            <Tooltip
                                              title={specificFeature.tooltip}
                                              placement="top"
                                              enterDelay={0}
                                            >
                                              <UilQuestionCircle
                                                className={classes.questionIcon}
                                              />
                                            </Tooltip>
                                          </>
                                        ) : null}
                                      </React.Fragment>
                                    ) : null}
                                    {item.tooltip ? (
                                      <>
                                        <Tooltip
                                          title={item.tooltip}
                                          placement="top"
                                          enterDelay={0}
                                        >
                                          <UilQuestionCircle
                                            className={classes.questionIcon}
                                          />
                                        </Tooltip>
                                      </>
                                    ) : null}{" "}
                                  </>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </React.Fragment>
                  );
                })}
              </div>
              <div>
                {category?.cta?.map((cta, ctaIdx) => (
                  <div key={ctaIdx} className={clsx(classes.cardFooterCTA)}>
                    <Button
                      fullWidth
                      background={
                        category.featured || cta.featured
                          ? "mintGreen"
                          : "bluePurple"
                      }
                      href={cta.link}
                      variant="contained"
                    >
                      {cta.text}
                    </Button>
                  </div>
                ))}
              </div>
            </section>
          );
        })}
      </MobileComparisionListStyled>
    );
  }

  return (
    <ComparisonTableStyled maxWidth={maxWidth} columnWidth={columnWidth}>
      <Table size="large" className="comparisonTable">
        <TableHead>
          <tr>
            <TableCell className="comparisonTable__cell__head"></TableCell>
            {categories.map((category) => (
              <TableCell
                key={category.title}
                className={`comparisonTable__cell ${
                  category.featured ? "comparisonTable__head__featuredCell" : ""
                }`}
              >
                <div
                  className={
                    category.featured
                      ? "comparisonTable__head__featuredCell__box"
                      : ""
                  }
                >
                  {category.image?.publicURL ? (
                    <img
                      className="comparisonTable__image"
                      src={category.image.publicURL}
                      alt={category.title}
                    />
                  ) : null}
                  {category.title}
                  {category.tag ? (
                    <Chip
                      size="small"
                      variant="outlined"
                      className="comparisonTable__tag"
                      label={category.tag}
                    />
                  ) : null}
                </div>
              </TableCell>
            ))}
          </tr>
        </TableHead>
        {features.map(({ groupName, items }, idx) => (
          <React.Fragment key={idx}>
            {groupName ? (
              <TableHead className="comparisonTable__head">
                <TableRow>
                  <TableCell className="comparisonTable__cell__head comparisonTable__cell__head__groupName">
                    {groupName}
                  </TableCell>
                  {categories.map((category, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        className={
                          category.featured
                            ? "comparisonTable__featuredCell"
                            : ""
                        }
                      ></TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {items.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell className="comparisonTable__cell__head">
                      {item.title}
                      {item.tooltip ? (
                        <>
                          {" "}
                          <Tooltip
                            title={item.tooltip}
                            placement="top"
                            enterDelay={0}
                          >
                            <span>
                              <UilQuestionCircle className="comparisonTable__infoIcon" />
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                    </TableCell>
                    {categories.map((category, categoryIdx) => {
                      const specificFeature = item.specific?.find(
                        ({ including }) => including.includes(category.title)
                      );

                      if (specificFeature) {
                        return (
                          <TableCell
                            key={categoryIdx}
                            sx={{ textAlign: "center" }}
                            className={`comparisonTable__cell ${
                              category.featured
                                ? category.cta === null &&
                                  idx === items.length - 1
                                  ? "comparisonTable__featuredCell-noCTA"
                                  : "comparisonTable__featuredCell"
                                : ""
                            }`}
                          >
                            {specificFeature.title}
                            {specificFeature.tooltip ? (
                              <>
                                {" "}
                                <Tooltip
                                  title={specificFeature.tooltip}
                                  placement="top"
                                  enterDelay={0}
                                >
                                  <span>
                                    <UilQuestionCircle className="comparisonTable__infoIcon" />
                                  </span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={categoryIdx}
                          className={`comparisonTable__cell ${
                            category.featured
                              ? category.cta === null &&
                                idx === items.length - 1
                                ? "comparisonTable__featuredCell-noCTA"
                                : "comparisonTable__featuredCell"
                              : ""
                          }`}
                        >
                          <span>
                            {!item.excluding?.includes(category.title) ? (
                              <UilCheck
                                className="comparison__available"
                                size={category.featured ? 32 : 26}
                              />
                            ) : (
                              <UilMinus className="comparison__unavailable" />
                            )}
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </React.Fragment>
        ))}
        <TableFooter className="comparisonTable__footer">
          <TableRow>
            <TableCell></TableCell>
            {categories.map((category, idx) => (
              <TableCell
                key={idx}
                className={`comparisonTable__footer__cell ${
                  category.featured
                    ? "comparisonTable__footer__featuredCell"
                    : ""
                }
                `}
              >
                {category?.cta?.map((cta, ctaIdx) => (
                  <div
                    className={`comparisonTable__footer__cta ${
                      category.featured
                        ? "comparisonTable__footer__featuredCell__box"
                        : ""
                    }`}
                  >
                    <Button
                      fullWidth
                      background={
                        category.featured || cta.featured
                          ? "mintGreen"
                          : "bluePurple"
                      }
                      href={cta.link}
                      variant="contained"
                    >
                      {cta.text}
                    </Button>
                  </div>
                ))}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </ComparisonTableStyled>
  );
};
