import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const SectionTextStyled = styled(Typography)(({ theme }) => ({
  fontSize: 25,
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  maxWidth: 850,
}));

export const SectionText = ({ leftAligned, ...props }) => {
  return (
    <SectionTextStyled
      {...props}
      style={
        leftAligned
          ? { textAlign: "left", maxWidth: "initial", paddingLeft: 16 }
          : undefined
      }
    />
  );
};
