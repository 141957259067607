import React from "react";
import { Grid, styled } from "@mui/material";

import { Column } from "@/components/mdx/Column";
import { notForwarded } from "@/utils/notForwarded";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";

const ColumnsStyled = styled("div", {
  shouldForwardProp: notForwarded(["maxWidth", "narrow", "spacing", "align"]),
})<Omit<ColumnsProps, "children">>(
  ({ theme, narrow, maxWidth, spacing = "default", align = "top" }) => ({
    margin: theme.spacing(spacingMap[spacing], "auto"),

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], "auto"),
    },
    ".MuiGrid-root": {
      ...(align === "center"
        ? { alignItems: "center" }
        : align === "bottom"
        ? { alignItems: "flex-end" }
        : { alignItems: "flex-start" }),
    },
    ".columns__element > :first-child": {
      marginTop: 0,
    },
    ".columns__element > :last-child": {
      marginBottom: 0,
    },
    ...(maxWidth
      ? {
          maxWidth,
        }
      : narrow
      ? {
          maxWidth: 768,
        }
      : {}),
  })
);

interface ColumnsProps {
  align?: "top" | "center" | "bottom";
  children: React.ReactNode;
  gap?: number;
  maxWidth?: number;
  narrow?: boolean;
  spacing?: Spacing;
}

export const Columns = ({ children, gap = 5, ...props }: ColumnsProps) => {
  if (!children) {
    return null;
  }

  return (
    <ColumnsStyled {...props}>
      <Grid container spacing={gap}>
        {Array.isArray(children)
          ? children.map((element, idx) =>
              element.type === Column ? (
                element
              ) : (
                <Grid
                  className="columns__element"
                  item
                  key={idx}
                  sm={12 / children.length}
                  xs={12}
                >
                  {element}
                </Grid>
              )
            )
          : children}
      </Grid>
    </ColumnsStyled>
  );
};
