import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { ListItem, ListItemText, Icon } from "@mui/material";
import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";

import AppFrame from "@/components/app-frame";
import { Markdown } from "@/components/markdown";
import { colors } from "@/theme/colors";

const PREFIX = "SectionListItem";

const classes = {
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  icon: `${PREFIX}-icon`,
  markdown: `${PREFIX}-markdown`,
};

const SectionListItemStyled = styled(ListItem)(({ theme }) => ({
  paddingRight: "5em",
  paddingBottom: 0,

  "& h6": {
    ...theme.typography.h6,
    fontWeight: 500,
    margin: 0,
    fontSize: 21,
    color: colors.prismaticBlack,
  },

  "& p": {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 16.8,
    fontWeight: 400,
    marginTop: 0,
  },

  "& > div": {
    marginTop: 6,
    marginBottom: 6,
  },

  [theme.breakpoints.down(600)]: {
    paddingRight: 0,
    paddingLeft: 0,
  },

  [`& .${classes.primary}`]: {
    fontSize: "1.5em",
    fontWeight: 500,
  },

  [`& .${classes.secondary}`]: {
    fontSize: "1.2em",
  },

  [`& .${classes.icon}`]: {
    fontSize: "2.5em !important",
  },

  [`& .${classes.markdown}`]: {
    marginBottom: "0 !important",
    "& p:last-of-type": {
      marginBottom: 0,
    },
  },
}));

export const SectionListItem = ({
  primary,
  secondary,
  markdown,
  icon,
  iconColor = "gray",
  image,
  showFrame,
  alternateText,
  quote,
  person,
  ...props
}) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <SectionListItemStyled component="div" {...props}>
      {icon && (
        <Icon
          className={clsx(classes.icon, "material-icons-round")}
          style={{ color: iconColor }}
        >
          {icon}
        </Icon>
      )}
      {markdown && <Markdown html={markdown} className={classes.markdown} />}
      {image && (
        <AppFrame
          showFrame={showFrame}
          shadow
          loaded={imageLoaded}
          style={{ width: "100%", marginBottom: "2em" }}
        >
          <GatsbyImage
            style={{ width: "100%" }}
            alt={alternateText}
            image={image.childImageSharp.gatsbyImageData}
            onLoad={() => setImageLoaded(true)}
          />
        </AppFrame>
      )}
    </SectionListItemStyled>
  );
};
