import React from "react";
import { styled } from "@mui/material";

import { notForwarded } from "@/utils/notForwarded";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";

interface ListProps {
  variant?: "default" | "check" | "dash";
  spacing?: Spacing;
}

export const List = styled("ul", {
  shouldForwardProp: notForwarded(["variant", "spacing"]),
})<ListProps>(({ variant = "default", spacing = "default", theme }) => ({
  margin: theme.spacing(spacingMap[spacing], 0),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(spacingSmallMap[spacing], 0),
  },
  ...(variant === "check"
    ? {
        "> li::marker": {
          content: '"✓  "',
          fontSize: 20,
          fontWeight: 700,
        },
      }
    : variant === "dash"
    ? {
        "> li::marker": {
          content: '"—  "',
          fontSize: 20,
          fontWeight: 700,
        },
      }
    : {}),
}));
