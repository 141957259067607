import React from "react";
import { styled } from "@mui/material/styles";
import { List } from "@mui/material";

const PREFIX = "SectionList";

const classes = {
  hasDirection: `${PREFIX}-hasDirection`,
};

const SectionListStyled = styled(List)(({ theme }) => ({
  padding: "0 3em",
  order: 2,
  minWidth: "unset",
  maxWidth: "unset",

  [theme.breakpoints.down(500)]: {
    padding: "0 1em",
  },

  [`&.${classes.hasDirection}`]: {
    width: "50%",
    maxWidth: "50%",
    [theme.breakpoints.down(1000)]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
}));

export const SectionList = ({ direction, ...props }) => {
  return (
    <SectionListStyled
      className={direction && classes.hasDirection}
      {...props}
    />
  );
};
