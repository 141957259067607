import { rgb } from "@/utils/rgb";
import { getColorRangeHex } from "@/utils/getColorRangeHex";

export const brandBoardValues = {
  deepPurple: [35, 38, 87],
  periwinkle: [86, 98, 167],
  prismaticBlack: [19, 23, 24],
  mintMuted: [50, 150, 112],
  mintGreen: [46, 206, 149],
  bluePurple: [96, 120, 226],
  blueIris: [94, 146, 225],
  turquoise: [74, 147, 174],
  cyan: [101, 213, 218],
  coolGray: [197, 208, 216],
  lightGray: [248, 249, 250],
};

export const brandBoard = {
  deepPurple: rgb(brandBoardValues.deepPurple),
  periwinkle: rgb(brandBoardValues.periwinkle),
  prismaticBlack: rgb(brandBoardValues.prismaticBlack),
  mintGreen: rgb(brandBoardValues.mintGreen),
  bluePurple: rgb(brandBoardValues.bluePurple),
  blueIris: rgb(brandBoardValues.blueIris),
  turquoise: rgb(brandBoardValues.turquoise),
  cyan: rgb(brandBoardValues.cyan),
  coolGray: rgb(brandBoardValues.coolGray),
  lightGray: rgb(brandBoardValues.lightGray),
};

export const addedColors = {
  white01: "rgb(255, 255, 255)",
  deepPurpleMuted: "rgb(33, 37, 58)",
  mintMuted: "rgb(50, 150, 112)",
  yellow: "rgb(220, 173, 5)",
  red: "rgb(244, 67, 54)",
  lightRed: "rgb(255,85,85)",
  black: "rgba(0, 0, 0, 0.95)",
};

interface GeneratedGradients {
  [key: string]: {
    light: Record<string, string>;
    dark: Record<string, string>;
  };
}

export const gradients: GeneratedGradients = Object.keys(
  brandBoardValues
).reduce(
  (previous, colorName) => ({
    ...previous,
    [colorName]: {
      light: getColorRangeHex(
        brandBoardValues[colorName],
        [256, 256, 256],
        20,
        colorName
      ),
      dark: getColorRangeHex(
        [19, 23, 24],
        brandBoardValues[colorName],
        20,
        colorName
      ),
    },
  }),
  {}
);

export const selectedGrays = {
  gray01: gradients.coolGray.light.coolGray1,
  gray02: gradients.coolGray.light.coolGray2,
  gray03: gradients.coolGray.light.coolGray3,
  gray04: gradients.coolGray.light.coolGray9,
  gray05: gradients.coolGray.light.coolGray19,
  gray06: gradients.coolGray.light.coolGray15,
  gray07: gradients.coolGray.dark.coolGray1,
  gray08: gradients.coolGray.dark.coolGray12,
  gray085: gradients.coolGray.dark.coolGray15,
  gray09: gradients.coolGray.dark.coolGray17,
  gray10: gradients.coolGray.dark.coolGray18,
  gray11: gradients.coolGray.dark.coolGray19,
  gray12: gradients.coolGray.dark.coolGray20,
};

export interface Colors {
  blueIris: string;
  bluePurple: string;
  coolGray: string;
  cyan: string;
  deepPurple: string;
  deepPurpleMuted: string;
  gray01: string;
  gray02: string;
  gray03: string;
  gray04: string;
  gray05: string;
  gray06: string;
  gray07: string;
  gray085: string;
  gray08: string;
  gray09: string;
  gray10: string;
  gray11: string;
  gray12: string;
  lightGray: string;
  lightRed: string;
  black: string;
  mintGreen: string;
  mintMuted: string;
  periwinkle: string;
  prismaticBlack: string;
  red: string;
  turquoise: string;
  white01: string;
  yellow: string;
}

export type Color = keyof Colors;

export const colors: Colors = {
  ...brandBoard,
  ...selectedGrays,
  ...addedColors,
};
