import React from "react";
import { styled } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

import { colors } from "@/theme/colors";

const PREFIX = "AppFrame";

const classes = {
  skeleton: `${PREFIX}-skeleton`,
};

const AppFrameStyled = styled("div")(() => ({
  position: "relative",
  borderRadius: ".7em",
  overflow: "hidden",
  minHeight: 100,
  "-webkit-transform": "translateZ(0)",

  [`& .${classes.skeleton}`]: {
    background: colors.white01,
    padding: "1em",
    "& .MuiSkeleton-root": {
      background:
        "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.06), transparent)",
    },
    paddingBottom: "9%",
  },
}));

const AppFrame = ({
  children,
  shadow,
  style = {},
  loaded = true,
  showFrame,
  wrapperStyles,
  ...props
}) => {
  if (!showFrame) {
    return children;
  }

  const styles = { ...style };

  if (shadow) {
    styles.boxShadow = "rgb(135, 135, 135) 0px 0px 13px 0px";
  }

  return (
    <AppFrameStyled style={styles} {...props}>
      <div>
        {loaded === false && (
          <div className={classes.skeleton}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={300}
            />
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="text" width="80%" />
          </div>
        )}
        <div
          style={
            loaded
              ? { ...wrapperStyles }
              : { position: "absolute", top: 0, minWidth: 1, opacity: 0 }
          }
        >
          {children}
        </div>
      </div>
    </AppFrameStyled>
  );
};

export default AppFrame;
