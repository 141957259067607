import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { UilAngleLeft } from "@iconscout/react-unicons";
import clsx from "clsx";

import { SubNavigationList, SubNavigationListProps } from "./SubNavigationList";
import { colors } from "@/theme/colors";

const PREFIX = "MobileSubNavigationGroup";

const classes = {
  category: `${PREFIX}-category`,
  subCategory: `${PREFIX}-subCategory`,
  tag: `${PREFIX}-tag`,
  hidden: `${PREFIX}-hidden`,
  icon: `${PREFIX}-icon`,
  iconExpanded: `${PREFIX}-iconExpanded`,
};

const MobileSubNavigationGroupStyled = styled("div")(({ theme }) => ({
  minWidth: "100% !important",
  [`& .${classes.category}`]: {
    paddingLeft: 12,
    paddingRight: "1em",
    flex: 1,
    minWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${classes.subCategory}`]: {
    fontSize: "1em",
    paddingLeft: 12,
    paddingRight: "1em",
    color: colors.gray08,
    width: "100%",
  },
  [`& .${classes.hidden}`]: {
    display: "none",
  },
  [`& .${classes.tag}`]: {
    padding: theme.spacing(2, 1.75, 1),
    fontSize: 16,
  },
  [`& .${classes.icon}`]: {
    transform: "rotate(-90deg)",
    transition: "transform 0.25s ease-in-out",
  },
  [`& .${classes.iconExpanded}`]: {
    transform: "rotate(90deg)",
  },
}));

interface MobileSubNavigationGroupProps {
  category: string;
  items: SubNavigationListProps["items"];
}

export const MobileSubNavigationGroup = ({
  category,
  items,
}: MobileSubNavigationGroupProps) => {
  const [expanded, setExpanded] = useState(false);

  const subItems = items.reduce((acc, item) => {
    const primaryTag = item.tags?.[0] ?? "default";

    return {
      ...acc,
      [primaryTag]: [
        ...(acc[primaryTag] ?? []),
        {
          ...item,
          tags: undefined,
        },
      ],
    };
  }, []);

  return (
    <MobileSubNavigationGroupStyled>
      <Button
        className={classes.category}
        onClick={() => setExpanded(!expanded)}
        endIcon={
          <UilAngleLeft
            className={clsx(classes.icon, expanded && classes.iconExpanded)}
          />
        }
      >
        {category}
      </Button>
      <div className={clsx(!expanded && classes.hidden)}>
        {Object.entries(subItems).map(([subCategory, items]) => (
          <div key={subCategory}>
            {subCategory !== "default" ? (
              <Typography variant="h6" className={classes.tag}>
                {subCategory}
              </Typography>
            ) : null}
            <SubNavigationList items={items} />
          </div>
        ))}
      </div>
    </MobileSubNavigationGroupStyled>
  );
};
