import { getCookie } from "./getCookie";

export const getConsentTrackingPreferences = () => {
  const trackingPreferences = getCookie("tracking-preferences");

  try {
    if (trackingPreferences) {
      const results = JSON.parse(decodeURIComponent(trackingPreferences));

      return results;
    }
  } catch (err) {
    console.warn(err);
  }
};
