import { datadogLogs } from "@datadog/browser-logs";

const logError = (message) => {
  // Log locally
  // eslint-disable-next-line no-console
  console.error(message);

  // Send to Datadog
  datadogLogs.logger.error(message);
};

export default logError;
