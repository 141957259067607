import React from "react";
import { styled } from "@mui/material";

import { Color, colors } from "@/theme/colors";
import { rgbToRgba } from "@/utils/rgbToRgba";
import { notForwarded } from "@/utils/notForwarded";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";
import { transformColor } from "@/utils/transformColor";

interface BannerProps {
  background?: Color | string;
  color?: Color | string;
  spacing?: Spacing;
}

export const Banner = styled("div", {
  shouldForwardProp: notForwarded(["color", "background", "spacing"]),
})<BannerProps>(
  ({
    background = "mintGreen",
    color = "gray09",
    spacing = "default",
    theme,
  }) => ({
    borderLeft: `8px solid ${transformColor(background) || colors.mintGreen}`,
    backgroundColor: rgbToRgba(
      transformColor(background) || colors.mintGreen,
      0.2
    ),
    borderRadius: theme.shape.borderRadius,
    color: transformColor(color) || colors.white01,
    fontSize: 16,
    fontStyle: "italic",
    lineHeight: 1.6,
    margin: theme.spacing(spacingMap[spacing], 0),
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], 0),
    },
    "& > :first-child": {
      marginTop: 0,
    },
    "& > :last-child": {
      marginBottom: 0,
    },
  })
);
