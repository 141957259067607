import type { ReactNode } from "react";
import { Component } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

const INITIAL_STATE: State = {
  error: null,
};

export class ErrorBoundary extends Component<Props, State> {
  public state = INITIAL_STATE;

  public componentDidCatch(error: Error): void {
    if (
      // https://github.com/gatsbyjs/gatsby/issues/33844
      error.name === "ChunkLoadError" ||
      // https://github.com/gatsbyjs/gatsby/issues/33956
      error.message.startsWith(
        "The result of this StaticQuery could not be fetched"
      )
    ) {
      this.setState({ error });
    } else {
      throw error;
    }
  }

  public render(): ReactNode {
    return this.state.error ? null : this.props.children;
  }
}
