import React from "react";
import { styled } from "@mui/material/styles";
import { Theme, Typography } from "@mui/material";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";
import clsx from "clsx";

import { colors } from "@/theme/colors";

const PREFIX = "NavigationItem";

const classes = {
  linkContent: `${PREFIX}-linkContent`,
  open: `${PREFIX}-open`,
  short: `${PREFIX}-short`,
};

interface NavigationItemStyledProps {
  theme: Theme;
}

const NavigationItemStyled = ({ theme }: NavigationItemStyledProps) => ({
  color: "inherit",
  padding: "0.75em 1.25em",
  transition: "none",
  "&:hover": {
    opacity: 0.5,
  },
  [theme.breakpoints.down(1330)]: {
    padding: "1.25em 0.75em",
  },
  [theme.breakpoints.down(1200)]: {
    padding: "1.5em 0.75em",
  },

  [`& .${classes.linkContent}`]: {
    fontSize: "1rem",
  },

  [`&.${classes.open}`]: {
    opacity: 0.5,
  },

  [`&.${classes.short}`]: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 15,
    color: colors.gray08,
    "& > span": {
      fontSize: "inherit",
    },
  },
});

const NavigationItemDivStyled = styled("div")(NavigationItemStyled);

const NavigationItemAStyled = styled("a")(NavigationItemStyled);

const NavigationItemLinkStyled = styled(Link)(NavigationItemStyled);

interface NavigationItemProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
  open?: boolean;
  short?: boolean;
  to?: string;
}

export const NavigationItem = ({
  children,
  className,
  to,
  href,
  open,
  short,
  ...other
}: NavigationItemProps) => {
  const props = {
    to,
    href,
    className: clsx(className, open && classes.open, short && classes.short),
    children: (
      <Typography
        variant="body1"
        component="span"
        className={classes.linkContent}
      >
        {children}
      </Typography>
    ),
    ...other,
  };

  if (!to && !href) {
    return <NavigationItemDivStyled style={{ cursor: "default" }} {...props} />;
  }

  const otherProps = to ? { segmentType: "navbar" } : {};

  if (to) {
    return <NavigationItemLinkStyled {...props} {...otherProps} />;
  }

  return <NavigationItemAStyled {...props} {...otherProps} />;
};
