import React, { useMemo } from "react";
import { styled, SxProps, Theme } from "@mui/material";

import { Slider, SliderProps } from "./Slider";
import { colors } from "@/theme/colors";
import { spacingMap, Spacing } from "@/utils/spacing";

const LogoCloudStyled = styled("div")<
  Pick<LogoCloudProps, "fullWidth" | "spacing">
>(({ theme, fullWidth, spacing = "small" }) => ({
  margin: theme.spacing(spacingMap[spacing], "auto"),
  maxWidth: 768,
  textAlign: "center",
  ...(fullWidth
    ? {
        maxWidth: "none",
      }
    : {}),
  ".logoCloud__title": {
    color: colors.gray08,
    display: "block",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
}));

export interface LogoCloudProps {
  fullWidth?: boolean;
  logos?: string | SliderProps["slides"];
  spacing?: Spacing;
  sx?: SxProps<Theme> | undefined;
  title?: string;
}

export const LogoCloud = ({
  logos: logosBase,
  title,
  ...props
}: LogoCloudProps) => {
  const logos = useMemo(
    () =>
      typeof logosBase === "string"
        ? logosBase.split(",").reduce<SliderProps["slides"]>(
            (acc, cur) =>
              acc?.concat({
                title: cur.replace(/^.*[\\\/]/, ""),
                image: cur,
              }),
            []
          )
        : logosBase,
    [logosBase]
  );

  if (!logos?.length) {
    return null;
  }

  return (
    <LogoCloudStyled {...props}>
      {title ? <h3 className="logoCloud__title">{title}</h3> : null}
      <Slider slides={logos} />
    </LogoCloudStyled>
  );
};
