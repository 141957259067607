import React, { useRef, lazy, Suspense } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { useIntersection } from "react-use";
import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader";

import { Header } from "./header";
import {
  baseStyles,
  hasMessageStyles,
  useBannerContext,
} from "@/components/banner";
import { CookieConsent } from "@/components/CookieConsent";
import { useSiteMetadata } from "@/hooks/useSiteMetadata";

const Footer = lazy(() => import("./footer/index"));

const PREFIX = "Layout";

const classes = {
  content: `${PREFIX}-content`,
  baseStyles: `${PREFIX}-baseStyles`,
  hasMessage: `${PREFIX}-hasMessage`,
};

const LayoutStyled = styled("div")(({ theme }) => ({
  display: "flex",
  [`& .${classes.content}`]: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  [`& .${classes.baseStyles}`]: baseStyles,
  [`& .${classes.hasMessage}`]: hasMessageStyles,
}));

interface Props {
  children: React.ReactNode;
  customFooterCta?: React.ReactNode;
  footerCta?: React.ReactNode;
  hideFooter?: boolean;
  lightBackground?: boolean;
  manualBanner?: boolean;
  minimalNavigation?: boolean;
  noStick?: boolean;
  useScroll?: boolean;
}

const Layout = ({
  children,
  customFooterCta,
  footerCta,
  hideFooter,
  lightBackground,
  manualBanner,
  minimalNavigation,
  noStick,
  useScroll,
}: Props) => {
  const { hasMessage } = useBannerContext();
  const showHasMessage = hasMessage && !manualBanner;
  const { config } = useSiteMetadata();

  const footerRef = useRef(null);
  const intersection = useIntersection(footerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });

  return (
    <LiveChatLoaderProvider
      providerKey={config.siteMetadata.liveChatLoader.providerKey}
      provider={config.siteMetadata.liveChatLoader.provider}
    >
      <LayoutStyled>
        <Header
          lightBackground={lightBackground}
          noStick={noStick}
          useScroll={useScroll}
          minimalNavigation={minimalNavigation}
        />
        <div
          className={clsx(
            classes.content,
            !manualBanner && classes.baseStyles,
            showHasMessage && classes.hasMessage
          )}
        >
          <main>{children}</main>
          {!hideFooter && (
            <div ref={footerRef} style={{ minHeight: 500 }}>
              <Suspense>
                {intersection && intersection.intersectionRatio > 0 ? (
                  <Footer
                    customFooterCta={customFooterCta}
                    footerCta={footerCta}
                    minimalNavigation={minimalNavigation}
                  />
                ) : null}
              </Suspense>
            </div>
          )}
        </div>
        <CookieConsent />
      </LayoutStyled>
      <Intercom />
    </LiveChatLoaderProvider>
  );
};

export default Layout;
