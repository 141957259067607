import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { Color } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";

const PREFIX = "Section";

const classes = {
  background: `${PREFIX}-background`,
  navbar: `${PREFIX}-navbar`,
};

const SectionStyled = styled("div", {
  shouldForwardProp: notForwarded([
    "background",
    "backgroundImage",
    "color",
    "spacing",
  ]),
})<Pick<SectionProps, "spacing" | "background" | "backgroundImage" | "color">>(
  ({ theme, background, color, backgroundImage, spacing }) => ({
    ...(spacing
      ? {
          margin: theme.spacing(spacingMap[spacing], 0),
          [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(spacingSmallMap[spacing], 0),
          },
        }
      : {}),
    [`&.${classes.background}`]: {
      backgroundColor: transformColor(background) || "inherit",
      color: transformColor(color) || "inherit",
      margin: "0 -999em",
      padding: "0 999em",
      position: "relative",
      "&::before": {
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        content: '""',
        display: "block",
        height: "100%",
        position: "absolute",
        top: 0,
        width: "100vw",
      },
    },
  })
);

const SectionContainerStyled = styled("section", {
  shouldForwardProp: notForwarded(["padding"]),
})<Pick<SectionProps, "padding">>(({ theme, padding }) => ({
  ...(padding
    ? {
        padding: theme.spacing(spacingMap[padding]),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(spacingSmallMap[padding]),
        },
      }
    : {
        padding: "3em",
        [theme.breakpoints.down(1400)]: {
          padding: "2em 3em",
        },
        [theme.breakpoints.down(900)]: {
          padding: 20,
        },
      }),
  position: "relative",
  [`&.${classes.navbar}`]: {
    marginTop: "3em",
    paddingTop: "7em",
  },
}));

export interface SectionProps {
  background?: Color | string;
  backgroundImage?: string;
  className?: string;
  color?: Color | string;
  innerRef?: React.RefObject;
  navbar?: string;
  padding?: Spacing;
  spacing?: Spacing;
  [props: string]: any;
}

export const Section = ({
  background,
  backgroundImage,
  className,
  color,
  innerRef,
  navbar,
  padding,
  spacing,
  ...props
}: SectionProps) => {
  return (
    <SectionStyled
      backgroundImage={backgroundImage}
      background={background}
      color={color}
      spacing={spacing}
      className={clsx((background || backgroundImage) && classes.background)}
    >
      <SectionContainerStyled
        ref={innerRef}
        className={clsx(navbar && classes.navbar, className)}
        padding={padding}
        {...props}
      />
    </SectionStyled>
  );
};
