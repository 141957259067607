import React from "react";
import { styled } from "@mui/material/styles";
import { ArrowBackIosRounded } from "@mui/icons-material";
import clsx from "clsx";

const PREFIX = "EndIcon";

const classes = {
  icon: `${PREFIX}-icon`,
  iconLeft: `${PREFIX}-iconLeft`,
  iconRight: `${PREFIX}-iconRight`,
};

const EndIconStyled = styled("span")(() => ({
  display: "flex",
  transform: "rotate(180deg)",
  width: "1em",
  height: "1em",
  position: "relative",

  [`& .${classes.icon}`]: {
    position: "absolute",
    margin: "auto",
    top: 0,
    bottom: 0,
    fontSize: "0.8em",
  },

  [`& .${classes.iconLeft}`]: {
    right: "-0.3em",
  },

  [`& .${classes.iconRight}`]: {
    right: "0.2em",
  },
}));

const EndIcon = (props) => {
  return (
    <EndIconStyled {...props}>
      <ArrowBackIosRounded className={clsx(classes.icon, classes.iconLeft)} />
      <ArrowBackIosRounded className={clsx(classes.icon, classes.iconRight)} />
    </EndIconStyled>
  );
};

export default EndIcon;
