import React from "react";
import {
  styled,
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from "@mui/material";

import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";

const TypographyStyled = styled(MUITypography, {
  shouldForwardProp: notForwarded([
    "spacing",
    "maxWidth",
    "spacingBottom",
    "spacingTop",
  ]),
})<
  Pick<TypographyProps, "spacing" | "maxWidth" | "spacingBottom" | "spacingTop">
>(({ theme, spacing, spacingBottom, spacingTop, maxWidth }) => ({
  maxWidth: maxWidth || "auto",
  ...(spacing
    ? {
        margin: `${theme.spacing(spacingMap[spacing], 0)} !important`,
        [theme.breakpoints.down("sm")]: {
          margin: `${theme.spacing(spacingSmallMap[spacing], 0)} !important`,
        },
      }
    : {}),
  ...(spacingBottom
    ? {
        marginBottom: `${theme.spacing(spacingMap[spacingBottom])} !important`,
        [theme.breakpoints.down("sm")]: {
          margin: `${theme.spacing(spacingSmallMap[spacingBottom])} !important`,
        },
      }
    : {}),
  ...(spacingTop
    ? {
        marginTop: `${theme.spacing(spacingMap[spacingTop])} !important`,
        [theme.breakpoints.down("sm")]: {
          marginTop: `${theme.spacing(spacingSmallMap[spacingTop])} !important`,
        },
      }
    : {}),
  "> :first-child": {
    marginTop: 0,
  },
  "> :last-child": {
    marginBottom: 0,
  },
}));

interface TypographyProps extends MUITypographyProps {
  copy?: string | null;
  maxWidth?: number;
  spacing?: Spacing;
  spacingBottom?: Spacing;
  spacingTop?: Spacing;
}

export const Typography = ({
  children,
  copy,
  maxWidth,
  spacing,
  spacingBottom,
  spacingTop,
  ...muiTypographyProps
}: TypographyProps) => {
  if (!copy && !children) {
    return null;
  }

  return (
    <TypographyStyled
      maxWidth={maxWidth}
      spacing={spacing}
      spacingBottom={spacingBottom}
      spacingTop={spacingTop}
      {...muiTypographyProps}
    >
      {copy || children}
    </TypographyStyled>
  );
};
