import React from "react";
import { IconButton } from "@mui/material";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";
import { styled } from "@mui/material/styles";
import { UilMultiply } from "@iconscout/react-unicons";

import { Menu } from "@/icons";
import { MobileSubNavigationGroup } from "./MobileSubNavigationGroup";
import { NavigationItem } from "./NavigationItem";
import { PrimaryButton } from "@/components/buttons";
import { colors } from "@/theme/colors";
import { useNavData } from "./navigationHooks";

const PREFIX = "MobileMenu";

const classes = {
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  closeButton: `${PREFIX}-closeButton`,
  topSection: `${PREFIX}-topSection`,
  bottomSection: `${PREFIX}-bottomSection`,
  primaryButton: `${PREFIX}-primaryButton`,
  menuButton: `${PREFIX}-menuButton`,
};

const MobileMenuStyled = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1210)]: {
    display: "none",
  },
  [`& .${classes.menuButton}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const PopoverStyled = styled(Popover)(({ theme }) => ({
  zIndex: 1000000,
  [`& .${classes.container}`]: {
    display: "block",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(9),
    position: "relative",
    width: 350,
    [theme.breakpoints.down(400)]: {
      width: 300,
    },
    [theme.breakpoints.down(300)]: {
      width: 250,
    },
  },
  [`& .${classes.content}`]: {
    color: colors.deepPurple,
    width: "100%",
    display: "block",
    margin: "0 auto",
  },
  [`& .${classes.closeButton}`]: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  [`& .${classes.primaryButton}`]: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  [`& .${classes.topSection}`]: {
    borderBottom: `1px solid ${colors.gray04}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  [`& .${classes.bottomSection}`]: {
    "& > *": {
      display: "block",
      padding: theme.spacing(0.75, 1.5),
    },
  },
}));

interface MobileMenuProps {
  primaryCta: {
    text: string;
    link: string;
  };
  loginCta: {
    text: string;
    link: string;
    href: string;
  };
}

export const MobileMenu = ({ primaryCta, loginCta }: MobileMenuProps) => {
  const { navData } = useNavData();

  return (
    <PopupState variant="popover" popupId="mobile-menu-popover">
      {(popupState) => (
        <MobileMenuStyled>
          <IconButton
            {...bindTrigger(popupState)}
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            size="medium"
          >
            <Menu />
          </IconButton>
          <PopoverStyled {...bindPopover(popupState)} elevation={2}>
            <div className={classes.container}>
              <IconButton
                className={classes.closeButton}
                onClick={() => popupState.setOpen(false)}
                size="small"
              >
                <UilMultiply size={20} />
              </IconButton>
              <PrimaryButton
                className={classes.primaryButton}
                component={Link}
                to={primaryCta.link}
                onClick={() => {
                  window.analytics.track("Clicked CTA", {
                    name: primaryCta.text,
                    external: primaryCta.link,
                    segmentType: "navbar",
                  });
                }}
              >
                {primaryCta.text}
              </PrimaryButton>
              <div className={classes.content}>
                <div className={classes.topSection}>
                  <MobileSubNavigationGroup
                    category="Platform"
                    items={navData.platform}
                  />
                  <MobileSubNavigationGroup
                    category="Solutions"
                    items={navData.solutions}
                  />
                  <MobileSubNavigationGroup
                    category="Developers"
                    items={navData.developers}
                  />
                  <MobileSubNavigationGroup
                    category="Resources"
                    items={navData.resources}
                  />
                </div>
                <div className={classes.bottomSection}>
                  <NavigationItem to="/components/">Connectors</NavigationItem>
                  <NavigationItem to="/pricing/">Pricing</NavigationItem>
                  <NavigationItem href={loginCta.href}>
                    {loginCta.text}
                  </NavigationItem>
                </div>
              </div>
            </div>
          </PopoverStyled>
        </MobileMenuStyled>
      )}
    </PopupState>
  );
};
