import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = () => {
  const { site } = useStaticQuery(graphql`
    query getLogoImage {
      site: markdownRemark(
        fileAbsolutePath: { glob: "**/content/site-meta.md" }
      ) {
        frontmatter {
          navbarLogo {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 100
                width: 200
              )
            }
          }
        }
      }
    }
  `);

  const { backgroundColor, ...image } =
    site.frontmatter.navbarLogo.childImageSharp.gatsbyImageData;

  return (
    <GatsbyImage
      loading="eager"
      image={image}
      alt="Prismatic.io an integration platform for B2B software companies"
    />
  );
};

export default Image;
