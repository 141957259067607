/**
 * This file needs to be kept in sync manually with the icons.ts
 * in the /src directory
 */
import {
  AccountCircleRounded as MuiAccountCircle,
  AddCircleRounded as MuiAddCircle,
  AddRounded as MuiAdd,
  ArrowBackIosRounded as MuiArrowBackIos,
  ArrowDropDownOutlined as MuiArrowDropDownOutlined,
  AssignmentRounded as MuiAssignment,
  BugReportRounded as MuiBugReport,
  BuildRounded as MuiBuild,
  BusinessRounded as MuiBusiness,
  CachedRounded as MuiCached,
  CameraAltOutlined as MuiCameraAltOutlined,
  CategoryRounded as MuiCategory,
  CheckCircleRounded as MuiCheckCircle,
  CheckRounded as MuiCheck,
  ChevronRightRounded as MuiChevronRight,
  CloseRounded as MuiClose,
  CloudUploadRounded as MuiCloudUpload,
  CodeRounded as MuiCode,
  CreateRounded as MuiCreate,
  DeleteOutlineRounded as MuiDeleteOutline,
  DeleteRounded as MuiDelete,
  DescriptionOutlined as MuiDescriptionOutlined,
  DeviceHubOutlined as MuiDeviceHubOutlined,
  DeviceHubRounded as MuiDeviceHub,
  DnsOutlined as MuiDnsOutlined,
  DoneAllRounded as MuiDoneAll,
  EditOutlined as MuiEditOutlined,
  EditRounded as MuiEdit,
  EmailOutlined as MuiEmailOutlined,
  EmailRounded as MuiEmail,
  ErrorRounded as MuiError,
  ExitToAppRounded as MuiExitToApp,
  Facebook as MuiFacebook,
  FileCopyRounded as MuiFileCopyRounded,
  FullscreenRounded as MuiFullscreen,
  GitHub as MuiGitHub,
  GroupRounded as MuiGroup,
  HeadsetMicRounded as MuiHeadsetMic,
  HelpRounded as MuiHelp,
  HomeRounded as MuiHome,
  InputRounded as MuiInput,
  KeyboardArrowDownRounded as MuiKeyboardArrowDown,
  LanguageRounded as MuiLanguage,
  LaunchRounded as MuiLaunch,
  LinkedIn as MuiLinkedIn,
  LinkOutlined as MuiLinkOutlined,
  LinkRounded as MuiLink,
  LockOutlined as MuiLockOutlined,
  LockRounded as MuiLock,
  MailOutlineRounded as MuiMailOutline,
  MenuRounded as MuiMenu,
  MoreVertRounded as MuiMoreVert,
  NavigateNextRounded as MuiNavigateNext,
  PauseCircleOutlineRounded as MuiPauseCircleOutline,
  PeopleRounded as MuiPeople,
  PersonRounded as MuiPerson,
  PhoneOutlined as MuiPhoneOutlined,
  PlayCircleFilledRounded as MuiPlayCircleFilled,
  PlayCircleOutlineRounded as MuiPlayCircleOutline,
  Reddit as MuiReddit,
  RefreshRounded as MuiRefreshRounded,
  ScheduleRounded as MuiSchedule,
  SearchOutlined as MuiSearchOutlined,
  SettingsPowerRounded as MuiSettingsPower,
  SettingsRemoteRounded as MuiSettingsRemote,
  SettingsRounded as MuiSettings,
  SpeedRounded as MuiSpeed,
  Star as MuiStar,
  StartFille as MuiStarFilled,
  TimerRounded as MuiTimer,
  TuneRounded as MuiTune,
  ViewListOutlined as MuiViewListOutlined,
  ViewModuleOutlined as MuiViewModuleOutlined,
  VpnKeyOutlined as MuiVpnKeyOutlined,
  VpnKeyRounded as MuiVpnKey,
  WallpaperRounded as MuiWallpaper,
  WarningRounded as MuiWarning,
} from "@mui/icons-material";
import TwitterX from "./components/svgs/TwitterX";

export const AccountCircle = MuiAccountCircle;
export const Add = MuiAdd;
export const AddCircle = MuiAddCircle;
export const ArrowBackIos = MuiArrowBackIos;
export const ArrowDropDownOutlined = MuiArrowDropDownOutlined;
export const Assignment = MuiAssignment;
export const BugReport = MuiBugReport;
export const Build = MuiBuild;
export const Business = MuiBusiness;
export const Cached = MuiCached;
export const CameraAltOutlined = MuiCameraAltOutlined;
export const Category = MuiCategory;
export const Check = MuiCheck;
export const CheckCircle = MuiCheckCircle;
export const ChevronRight = MuiChevronRight;
export const Close = MuiClose;
export const CloudUpload = MuiCloudUpload;
export const Code = MuiCode;
export const CopyToClipboard = MuiFileCopyRounded;
export const Create = MuiCreate;
export const Delete = MuiDelete;
export const DeleteOutline = MuiDeleteOutline;
export const DescriptionOutlined = MuiDescriptionOutlined;
export const DeviceHub = MuiDeviceHub;
export const DeviceHubOutlined = MuiDeviceHubOutlined;
export const DnsOutlined = MuiDnsOutlined;
export const DoneAll = MuiDoneAll;
export const Edit = MuiEdit;
export const EditOutlined = MuiEditOutlined;
export const Email = MuiEmail;
export const EmailOutlined = MuiEmailOutlined;
export const Error = MuiError;
export const ExitToApp = MuiExitToApp;
export const Facebook = MuiFacebook;
export const Fullscreen = MuiFullscreen;
export const GitHub = MuiGitHub;
export const Group = MuiGroup;
export const HeadsetMic = MuiHeadsetMic;
export const Help = MuiHelp;
export const Home = MuiHome;
export const Input = MuiInput;
export const KeyboardArrowDown = MuiKeyboardArrowDown;
export const Language = MuiLanguage;
export const Launch = MuiLaunch;
export const Link = MuiLink;
export const LinkedIn = MuiLinkedIn;
export const LinkOutlined = MuiLinkOutlined;
export const Lock = MuiLock;
export const LockOutlined = MuiLockOutlined;
export const MailOutline = MuiMailOutline;
export const Menu = MuiMenu;
export const MoreVert = MuiMoreVert;
export const NavigateNext = MuiNavigateNext;
export const PauseCircleOutline = MuiPauseCircleOutline;
export const People = MuiPeople;
export const Person = MuiPerson;
export const PhoneOutlined = MuiPhoneOutlined;
export const PlayCircleFilled = MuiPlayCircleFilled;
export const PlayCircleOutline = MuiPlayCircleOutline;
export const Reddit = MuiReddit;
export const RefreshRounded = MuiRefreshRounded;
export const Schedule = MuiSchedule;
export const SearchOutlined = MuiSearchOutlined;
export const Settings = MuiSettings;
export const SettingsPower = MuiSettingsPower;
export const SettingsRemote = MuiSettingsRemote;
export const Star = MuiStar;
export const StarFilled = MuiStarFilled;
export const Speed = MuiSpeed;
export const Timer = MuiTimer;
export const Tune = MuiTune;
export const Twitter = TwitterX;
export const ViewListOutlined = MuiViewListOutlined;
export const ViewModuleOutlined = MuiViewModuleOutlined;
export const VpnKey = MuiVpnKey;
export const VpnKeyOutlined = MuiVpnKeyOutlined;
export const Wallpaper = MuiWallpaper;
export const Warning = MuiWarning;
