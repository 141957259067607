import React, { forwardRef } from "react";
import { Link } from "gatsby";

import {
  handleLinkClick,
  stripHashedLocation,
  handleStrippedLinkClick,
} from "../utils";

export const AnchorLink = forwardRef(
  (
    {
      to,
      title,
      hrefLang = "en",
      children,
      segmentType,
      className,
      stripHash = false,
      ...props
    },
    ref
  ) => {
    const linkProps = {
      to: stripHash ? stripHashedLocation(to) : to,
      onClick: (e) => {
        window.analytics.track("Clicked CTA", {
          name: getLinkText(children) || title,
          ...(segmentType ? { type: segmentType } : {}),
          internal: to,
        });

        return stripHash
          ? handleStrippedLinkClick(to, e)
          : handleLinkClick(to, e);
      },
      hrefLang,
    };

    /**
     * Optional props
     */
    if (title) linkProps.title = title;
    if (className) linkProps.className = className;

    return (
      <Link {...props} {...linkProps} ref={ref}>
        {Boolean(children) ? children : title}
      </Link>
    );
  }
);

function getLinkText(children) {
  if (!children || typeof children === "string") {
    return children;
  }

  const base = children?.[0];

  if (typeof base === "string") {
    return base;
  }

  if (base?.props) {
    return base.props.children?.[1];
  }

  return base?.[1]?.props?.primary;
}
