import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useInterval, useWindowSize } from "react-use";

import { alignment } from "@/theme/alignment";
import { notForwarded } from "@/utils/notForwarded";

const PREFIX = "Carousel";

const classes = {
  container: `${PREFIX}-container`,
  item: `${PREFIX}-item`,
  stepperRoot: `${PREFIX}-stepperRoot`,
  stepperDot: `${PREFIX}-stepperDot`,
  stepperDotActive: `${PREFIX}-stepperDotActive`,
  stepperButton: `${PREFIX}-stepperButton`,
};

const CarouselStyled = styled("div", {
  shouldForwardProp: notForwarded(["styles"]),
})<{
  styles: { containerHeight: string; stepperRootDisplay: string };
}>(({ styles }) => ({
  ...alignment,
  margin: "0 auto",
  paddingLeft: 0,
  paddingRight: 0,

  [`& .${classes.container}`]: {
    alignItems: "center",
    display: "flex",
    height: styles.containerHeight,
    position: "relative",
  },

  [`& .${classes.item}`]: {
    left: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },

  [`& .${classes.stepperRoot}`]: {
    background: "none",
    bottom: 0,
    color: "inherit",
    display: styles.stepperRootDisplay,
  },

  [`& .${classes.stepperDot}`]: {
    background: "currentColor",
    opacity: 0.3,
  },

  [`& .${classes.stepperDotActive}`]: {
    background: "currentColor",
    opacity: 1,
  },

  [`& .${classes.stepperButton}`]: {
    color: "currentColor",
    opacity: 0.6,
  },
}));

export interface CarouselProps<P> {
  children: (item: P) => React.ReactNode;
  controls: boolean;
  delay: number;
  items: P[];
  maxLength: number;
}

const Carousel = <P extends unknown>({
  children,
  controls,
  delay = 5000,
  items,
}: CarouselProps<P>) => {
  const carouselElements = useRef<HTMLDivElement[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [containerHeight, setContainerHeight] = useState(350);
  const [isRunning, setIsRunning] = useState(true);
  const { width } = useWindowSize();
  const maxSteps = items.length;

  // set container height
  useEffect(
    () =>
      setContainerHeight(
        carouselElements.current?.reduce(
          (acc, el) =>
            acc.scrollHeight > el.scrollHeight
              ? acc.scrollHeight
              : el.scrollHeight,
          0
        ) || 350
      ),
    [width]
  );

  // reset carousel
  useEffect(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, [isRunning]);

  // loop carousel
  useInterval(
    () => {
      setActiveStep((prevActiveStep) =>
        prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1
      );
    },
    isRunning ? delay : null
  );

  const handleNext = () => {
    setIsRunning(false);
    setActiveStep((prevActiveStep) =>
      prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1
    );
  };

  const handlePrevious = () => {
    setIsRunning(false);
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  return (
    <CarouselStyled
      styles={{
        containerHeight,
        stepperRootDisplay: controls ? "flex" : "none",
      }}
    >
      <div className={classes.container}>
        {items.map((item, idx) => (
          <div
            className={classes.item}
            key={idx}
            ref={(el) => (carouselElements.current[idx] = el)}
            style={{ opacity: idx === activeStep ? 1 : 0 }}
          >
            {children(items[idx])}
          </div>
        ))}
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant={controls ? "dots" : null}
        classes={{
          dot: classes.stepperDot,
          dotActive: classes.stepperDotActive,
          root: classes.stepperRoot,
        }}
        activeStep={activeStep}
        nextButton={
          controls ? (
            <Button
              size="large"
              onClick={handleNext}
              className={classes.stepperButton}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          ) : null
        }
        backButton={
          controls ? (
            <Button
              size="large"
              onClick={handlePrevious}
              className={classes.stepperButton}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          ) : null
        }
      />
    </CarouselStyled>
  );
};

export default Carousel;
