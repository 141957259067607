import React, { useContext } from "react";

interface BannerContextValue {
  hasMessage: boolean;
  setHasMessage?: () => void;
}

const defaultState = {
  hasMessage: false,
};

export const BannerContext =
  React.createContext<BannerContextValue>(defaultState);

export const useBannerContext = () => useContext(BannerContext);
