import React from "react";
import { Avatar, Box, Grid, Paper, Rating, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { Container } from "@/components/Container";

interface Testimonial {
  id: string;
  rating: number;
  person: {
    avatar: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
    name: string;
  };
  quote: string;
}

const TestimonialGrid = () => {
  const data = useStaticQuery<GraphQLQuery>(graphql`
    query getTestimonialRatingsData {
      ratings: markdownRemark(
        fileAbsolutePath: { glob: "**/content/referenceData/testimonials.md" }
      ) {
        frontmatter {
          showRatings
          ratingsStoreSectionTitle
          ratingsStoreSectionSubtitle
          ratingsStore {
            id
            rating
            person {
              avatar {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: NONE
                    quality: 85
                    width: 128
                  )
                }
              }
              name
            }
            quote
          }
        }
      }
    }
  `);

  if (data.showRatings === false) {
    return null;
  }

  return (
    <Container
      gutter={5.25}
      maxWidth={1230}
      sx={{ marginBottom: 10, marginTop: 5 }}
    >
      <Typography sx={{ textAlign: "center" }} variant="h4">
        {data.ratings.frontmatter.ratingsStoreSectionTitle}
      </Typography>
      <Typography
        fontWeight={400}
        variant="h6"
        paragraph
        sx={{ textAlign: "center", marginBottom: 5 }}
      >
        {data.ratings.frontmatter.ratingsStoreSectionSubtitle}
      </Typography>
      <Grid container spacing={4}>
        {data.ratings.frontmatter.ratingsStore.map(
          (testimonial: Testimonial) => (
            <Grid item xs={4} key={testimonial.id}>
              <Paper
                sx={{
                  paddingX: 2,
                  paddingY: 2,
                }}
              >
                <Box display="flex" gap={2}>
                  <Avatar
                    alt="Testimonial Avatar"
                    src={
                      testimonial.person.avatar.childImageSharp.gatsbyImageData
                        .images.fallback.src
                    }
                  />
                  <Box>
                    <Typography fontWeight={800} fontSize={16}>
                      {testimonial.person.name}
                    </Typography>
                    <Box>
                      <Rating
                        readOnly
                        precision={0.5}
                        value={testimonial.rating}
                        size="small"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography fontSize={13}>{testimonial.quote}</Typography>
                </Box>
                <Box display="flex" gap={1} marginTop={2} alignItems="center">
                  <Avatar
                    sx={{ width: 28, height: 28 }}
                    src="https://seeklogo.com/images/G/g2-logo-F87402EB23-seeklogo.com.png"
                    alt="g2 logo"
                  />
                  <Box>
                    <Typography fontSize={12} color="#9e9e9e">
                      Posted on
                    </Typography>
                    <Typography fontSize={12} fontWeight={800} color="#0cd2ff">
                      G2
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          )
        )}
      </Grid>
    </Container>
  );
};

export default TestimonialGrid;
