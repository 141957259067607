import React from "react";

import { useConnectors } from "./useConnectors";
import { Connectors, ConnectorsProps } from "./Connectors";

interface ConnectorsContainerProps extends ConnectorsProps {
  categoryOrder?: string;
}

export const ConnectorsContainer = ({
  category: categoryBase,
  categoryOrder,
  hideCategories,
  hideSearch,
  limit: limitBase,
  search: searchBase,
  spacing,
}: ConnectorsContainerProps) => {
  const {
    allConnectors,
    categories,
    category,
    limit,
    results,
    search,
    setCategory,
    setLimit,
    setSearch,
  } = useConnectors({
    category: categoryBase,
    categoryOrder,
    limit: limitBase,
    search: searchBase,
  });

  if (!allConnectors.length) {
    return null;
  }

  return (
    <Connectors
      categories={categories}
      category={category}
      hideCategories={hideCategories}
      hideSearch={hideSearch}
      limit={limit}
      results={results}
      search={search}
      setCategory={setCategory}
      setLimit={setLimit}
      setSearch={setSearch}
      spacing={spacing}
    />
  );
};
