interface ConditionalWrapperProps {
  children: React.ReactNode;
  condition: boolean;
  wrapper: (component: React.ReactNode) => React.ReactNode;
}

const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children);

export default ConditionalWrapper;
