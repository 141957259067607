import React from "react";
import { styled } from "@mui/material/styles";
import { GatsbyImage } from "gatsby-plugin-image";

import { alignment } from "@/theme/alignment";
import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";
import { Spacing, spacingMap } from "@/utils/spacing";

const PREFIX = "SecondaryTestimonial";

const classes = {
  avatar: `${PREFIX}-avatar`,
  avatarImage: `${PREFIX}-avatarImage`,
  content: `${PREFIX}-content`,
  quote: `${PREFIX}-quote`,
  name: `${PREFIX}-name`,
  title: `${PREFIX}-title`,
};

const SecondaryTestimonialStyled = styled("div", {
  shouldForwardProp: notForwarded(["color", "background", "padding"]),
})<Pick<SecondaryTestimonialProps, "background" | "color" | "padding">>(
  ({ theme, background, color, padding = "small" }) => ({
    ...alignment,
    backgroundColor: transformColor(background) || "inherit",
    borderRadius: theme.shape.borderRadius,
    color: transformColor(color) || "inherit",
    display: "flex",
    justifyContent: "space-between",
    letterSpacing: "0.01071em",
    lineHeight: "1.43",
    margin: theme.spacing(0, "auto"),
    padding: theme.spacing(spacingMap[padding]),
    position: "relative",
    [theme.breakpoints.down(800)]: {
      flexWrap: "wrap",
      paddingTop: theme.spacing(8),
      textAlign: "center",
    },
    [`& .${classes.avatar}`]: {
      height: 128,
      marginRight: theme.spacing(2),
      width: 128,
      [theme.breakpoints.down(800)]: {
        marginBottom: theme.spacing(4),
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    [`& .${classes.avatarImage}`]: {
      border: `2px solid ${transformColor(color) || colors.mintGreen}`,
      borderRadius: "50%",
    },
    [`& .${classes.content}`]: {},
    [`& .${classes.quote}`]: {
      fontSize: 18,
      margin: 0,
    },
    [`& .${classes.name}`]: {
      fontSize: 18,
      fontWeight: 800,
      margin: 0,
      marginTop: theme.spacing(3),
    },
    [`& .${classes.title}`]: {
      fontSize: 15,
      margin: 0,
    },
  })
);

interface SecondaryTestimonialProps {
  avatar: any;
  background?: Color | string;
  className: string;
  color?: Color | string;
  name: string;
  padding: Spacing;
  quote: string;
  title: string;
}

export const SecondaryTestimonial = ({
  avatar,
  background,
  className,
  color,
  name,
  padding,
  quote,
  title,
}: SecondaryTestimonialProps) => {
  return (
    <SecondaryTestimonialStyled
      background={background}
      className={className}
      color={color}
      padding={padding}
    >
      {avatar && (
        <div className={classes.avatar}>
          <GatsbyImage
            alt={`Testimonial from ${name}`}
            image={avatar}
            imgClassName={classes.avatarImage}
            objectFit="scale-down"
          />
        </div>
      )}
      <div className={classes.content}>
        <p className={classes.quote}>{quote}</p>
        {name && <p className={classes.name}>{name}</p>}
        {title && <p className={classes.title}>{title}</p>}
      </div>
    </SecondaryTestimonialStyled>
  );
};
