import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
  styled,
  DialogActions,
} from "@mui/material";
import { useLunr } from "react-lunr";
import { useHotkeys } from "react-hotkeys-hook";
import { useDebounce } from "react-use";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Search as SearchIcon } from "@mui/icons-material";
import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useCallback, useMemo, useState } from "react";

import { colors } from "@/theme/colors";
import { SubNavigationList } from "./SubNavigationList";

interface Search {
  title: string;
  description: string;
  tags: Array<string>;
  slug: string;
  icon: string | IGatsbyImageData;
}

const SearchBoxWrapperStyled = styled(Box)(({ theme }) => ({
  ".global-search__button": {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    "&:hover": { backgroundColor: "transparent" },
  },
  ".global-search__search-input": {
    cursor: "pointer",
    input: { cursor: "pointer" },
    backgroundColor: colors.lightGray,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 160,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down(600)]: {
      display: "none",
    },

    ".MuiInputAdornment-positionEnd": {
      cursor: "pointer",
      backgroundColor: colors.white01,
      height: "100%",
      padding: `0px ${theme.spacing(0.5)}`,
      borderRadius: theme.shape.borderRadius,

      ".MuiTypography-root": {
        fontSize: 12,
        fontWeight: 800,
        color: colors.gray08,
      },
    },
  },
}));

const SearchDialogStyled = styled(Dialog)(() => ({
  ".global-search__dialog-title": {
    padding: 0,
    maxHeight: 56,
    borderBottom: `1px solid ${colors.gray04}`,
  },
  ".global-search__dialog-input": {
    borderRadius: 0,
    border: "none",
    "& fieldset": { border: "none" },
  },
  ".global-search__dialog-escape": {
    minHeight: 24,
    minWidth: 28,
    backgroundColor: colors.gray05,
    "&:hover": {
      backgroundColor: colors.gray04,
    },
    ".MuiTypography-root": {
      fontSize: 10,
      fontWeight: 800,
      color: colors.white01,
    },
  },
  ".global-search__dialog-content": {
    padding: 0,
  },
  ".global-search__dialog-actions": {
    display: "flex",
    justifyContent: "start",
    paddingX: 2,
    paddingY: 1,
    fontSize: 12,
    backgroundColor: colors.gray03,
  },
}));

const GlobalSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [_, cancel] = useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    500,
    [searchTerm]
  );
  const handleClose = useCallback(() => {
    cancel();
    setSearchTerm("");
    setOpenSearch(false);
  }, [cancel]);

  useHotkeys("meta+k", () => setOpenSearch(true), []);
  const queryData = useStaticQuery(graphql`
    query {
      localSearchContent {
        index
        store
      }
    }
  `);

  const index = queryData.localSearchContent.index;
  const store = queryData.localSearchContent.store;

  const results = useLunr(debouncedSearchTerm, index, store) as Array<Search>;
  const items = useMemo(
    () =>
      results.map((result) => ({
        href: result.slug,
        icon: result.icon,
        subTitle: result.description,
        text: result.title,
        tags: result.tags,
      })),
    [results]
  );

  const isSearching = searchTerm && debouncedSearchTerm !== searchTerm;
  const noResults = isSearching === false && searchTerm && results.length === 0;

  return (
    <SearchBoxWrapperStyled>
      <Button
        onClick={() => {
          setOpenSearch(true);
        }}
        aria-label="Search"
        className="global-search__button"
        variant="text"
        disableRipple
        disableElevation
      >
        <SearchIcon />
      </Button>
      <SearchDialogStyled open={openSearch} onClose={handleClose}>
        <DialogTitle className="global-search__dialog-title" component={Box}>
          <TextField
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            className="global-search__dialog-input"
            autoFocus
            variant="outlined"
            placeholder="Search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: isSearching ? (
                <InputAdornment position="end">
                  <CircularProgress size={16} />
                </InputAdornment>
              ) : (
                <InputAdornment
                  position="end"
                  component={Button}
                  size="small"
                  onClick={handleClose}
                  className="global-search__dialog-escape"
                >
                  ESC
                </InputAdornment>
              ),
            }}
          />
        </DialogTitle>
        <DialogContent className="global-search__dialog-content">
          {noResults ? (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              textAlign="center"
              marginTop={4}
            >
              No Results for "<strong>{searchTerm}</strong>"
            </Typography>
          ) : null}
          <SubNavigationList items={items} showTags onSelection={handleClose} />
        </DialogContent>
        <DialogActions className="global-search__dialog-actions">
          <Typography color="CaptionText" component="p" fontSize={12}>
            Looking for the Docs?{" "}
            <Link to="/docs" onClick={handleClose}>
              Go to Prismatic Docs
            </Link>{" "}
          </Typography>
        </DialogActions>
      </SearchDialogStyled>
    </SearchBoxWrapperStyled>
  );
};

export default GlobalSearch;
