import React from "react";
import { TextField as MuiTextField } from "@mui/material";

import Field from "./Field";

export const TextField = ({ children, name, label, ...rest }) => (
  <Field name={name} label={label}>
    {({ field }) => (
      <MuiTextField fullWidth {...rest} {...field}>
        {children}
      </MuiTextField>
    )}
  </Field>
);

export default TextField;
