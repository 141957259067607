import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const QuoteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 414.58 356.18">
      <path
        d="M411.08,352.41c-1.45.09-2.9.25-4.35.25q-82.58,0-165.15,0H236.7c-.11-1.33-.25-2.23-.25-3.12.05-59-1-118.1.47-177.1,1.48-59.63,28.84-106.31,77.86-139.92C342.69,13.41,374,4.06,407.92,3.5c1.05,0,2.11.12,3.16.19V61.81c-5.68.32-11.43.21-17,1a116.46,116.46,0,0,0-97.23,93.05c-1.41,7.16-1.72,14.53-2.58,22.14H411.08Z"
        strokeMiterlimit={10}
        strokeWidth={7}
      />
      <path
        d="M3.5,166.9c1-7.06,1.77-14.17,3.13-21.16,8.76-45.1,31.76-81.36,68.62-108.61,29.18-21.58,62.15-32.68,98.49-33.6,1,0,2.12,0,3.17,0a3.89,3.89,0,0,1,1,.4V61.38c-39.86,1.37-72,17.73-95.27,50.35-13.95,19.55-20.53,41.65-20.91,66.2H177.9V352.44c-1.37.09-2.53.23-3.69.23q-83.77,0-167.56,0c-1.05,0-2.1-.16-3.15-.25Z"
        strokeMiterlimit={10}
        strokeWidth={7}
      />
    </SvgIcon>
  );
};

export default QuoteIcon;
