import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { colors } from "@/theme/colors";

const PREFIX = "Markdown";

const classes = {
  withSpacing: `${PREFIX}-withSpacing`,
  appFrame: `${PREFIX}-appFrame`,
  denseBullets: `${PREFIX}-denseBullets`,
};

export const MarkdownStyled = styled("div")(({ theme }) => ({
  fontSize: 16.8,
  fontWeight: 400,
  color: colors.gray08,
  lineHeight: 2,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  "& > video": {
    maxWidth: "100%",
  },
  "& .app-frame": {
    margin: "20px auto 40px auto",
    "& img": {
      borderRadius: ".7em",
      boxShadow: "rgb(135, 135, 135) 0px 0px 13px 0px !important",
    },
  },
  "& > blockquote p": {
    marginBottom: "1em",
    color: colors.gray08,
  },
  "& > ul > li": {
    lineHeight: 2,
    marginTop: "0.5em",
  },
  "& h2": {
    marginBottom: "0",
    fontSize: "30px",
    lineHeight: "36px",
    color: colors.black,
    fontWeight: 500,
  },
  "& h3": {
    marginTop: 0,
    fontSize: "24px",
    lineHeight: "32px",
    color: colors.black,
    fontWeight: 500,
  },
  "& h4": {
    fontSize: "22px",
    lineHeight: "28px",
    color: colors.black,
    fontWeight: 500,
  },
  "h1, h5, h6": {
    fontWeight: 500,
    color: colors.black,
    lineHeight: 1.5,
  },
  "& > p": {
    margin: theme.spacing(3, 0),
    color: colors.gray08,
    lineHeight: 2,
    "& + ul, & + ol": {
      marginTop: "-1em",
    },
  },
  "& > ul": {
    paddingLeft: 24,
    marginBottom: "1em",
    "&.contains-task-list": {
      listStyle: "none",
      paddingLeft: 0,
      "& .task-list-item": {
        display: "flex",
        alignItems: "center",
        '& [type="checkbox"]': {
          position: "absolute",
          left: -99999,
        },
        "& .checkbox": {
          minWidth: 30,
          minHeight: 30,
          background: colors.mintGreen,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          fontSize: "1rem",
          marginRight: "1.5em",
          color: "transparent",
          textShadow: `0 0 0 ${colors.white01}`,
          paddingLeft: 7,
        },
      },
    },
  },
  "& > hr": {
    border: "none",
    borderTop: `1px solid ${colors.gray05}`,
  },
  "& img, & > video": {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${colors.gray04}`,
  },
  [`&.${classes.denseBullets}`]: {
    "& li": {
      marginTop: 3,
    },
  },
}));

export const Markdown = ({
  html,
  className,
  appFrame,
  denseBullets,
  ...props
}) => {
  return (
    <MarkdownStyled
      dangerouslySetInnerHTML={{ __html: html }}
      className={clsx(
        denseBullets && classes.denseBullets,
        appFrame && classes.appFrame,
        className
      )}
      {...props}
    />
  );
};
