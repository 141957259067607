import React, { useMemo } from "react";
import { styled } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";

import { SecondaryTestimonial } from "@/components/Testimonials/SecondaryTestimonial";
import { PrimaryTestimonial } from "@/components/Testimonials/PrimaryTestimonial";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { Color } from "@/theme/colors";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";

type TestimonialVariants = {
  [variant: string]: React.ElementType;
};

const testimonialVariants: TestimonialVariants = {
  secondary: SecondaryTestimonial,
  primary: PrimaryTestimonial,
};

const TestimonialStyled = styled("div", {
  shouldForwardProp: notForwarded(["spacing"]),
})<Pick<TestimonialProps, "spacing">>(({ theme, spacing = "default" }) => ({
  margin: theme.spacing(spacingMap[spacing], 0),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(spacingSmallMap[spacing], 0),
  },
}));

interface TestimonialProps {
  background?: Color | string;
  color?: Color | string;
  id: string;
  padding?: Spacing;
  spacing?: Spacing;
  variant?: "primary" | "secondary";
}

export const Testimonial = ({
  background,
  color,
  id,
  padding,
  spacing,
  variant = "primary",
}: TestimonialProps) => {
  const Testimonial = testimonialVariants?.[variant];

  const data = useStaticQuery<GraphQLQuery>(graphql`
    query getTestimonialData {
      testimonials: markdownRemark(
        fileAbsolutePath: { glob: "**/content/referenceData/testimonials.md" }
      ) {
        frontmatter {
          testimonialStore {
            id
            person {
              companyLogo {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: NONE
                    quality: 85
                    width: 192
                  )
                }
              }
              avatar {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: NONE
                    quality: 85
                    width: 128
                  )
                }
              }
              name
              title
            }
            quote
          }
        }
      }
    }
  `);

  const testimonial = useMemo(
    () =>
      data.testimonials.frontmatter.testimonialStore.find(
        (testimonial) => testimonial.id === id
      ),
    [data.testimonials.frontmatter, id]
  );

  if (!testimonial || !Testimonial) {
    return null;
  }

  return (
    <TestimonialStyled spacing={spacing}>
      <Testimonial
        avatar={testimonial.person?.avatar?.childImageSharp?.gatsbyImageData}
        background={background}
        color={color}
        companyLogo={
          testimonial.person?.companyLogo?.childImageSharp?.gatsbyImageData
        }
        name={testimonial.person?.name}
        padding={padding}
        quote={testimonial.quote}
        title={testimonial.person?.title}
      />
    </TestimonialStyled>
  );
};
