import React, { useMemo } from "react";
import { styled } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import { FormContainer } from "@/components/form/FormContainer";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";
import { transformColor } from "@/utils/transformColor";

const FormStyled = styled("div", {
  shouldForwardProp: notForwarded(["background", "color", "spacing", "shadow"]),
})<Omit<FormProps, "name">>(
  ({ theme, background = "white01", color, spacing = "default", shadow }) => ({
    backgroundColor: transformColor(background) || colors.white01,
    border: `1px solid ${colors.gray04}`,
    borderRadius: theme.shape.borderRadius,
    color: transformColor(color) || "inherit",
    margin: theme.spacing(spacingMap[spacing], 0),
    padding: theme.spacing(3),
    ...(shadow ? { boxShadow: theme.shadows[6] } : {}),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], 0),
    },
  })
);

interface FormProps {
  background?: Color | string;
  shadow?: boolean;
  color?: Color | string;
  name: string;
  spacing?: Spacing;
}

export const Form = ({ name, ...props }: FormProps) => {
  const data = useStaticQuery<GraphQLQuery>(graphql`
    query getFormData {
      forms: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/content/referenceData/forms/**.md" }
        }
      ) {
        nodes {
          parent {
            ... on File {
              id
              name
            }
          }
          frontmatter {
            form {
              name
              title
              fields {
                autoComplete
                defaultValue
                hideFromSegment
                identifier
                label
                name
                type
                validation
                validationMatch
                queryParameter
              }
              postData {
                enable
                route
                subdomain
                defaultParams {
                  key
                  value
                }
              }
              submit {
                loadingText
                text
              }
              headline
              submitAreaMarkdown
              text
              confirmation {
                confirmationImage {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED
                      placeholder: NONE
                      quality: 85
                      width: 200
                    )
                  }
                }
                secondary
                primary
                redirect {
                  route
                  type
                }
              }
              errorMessage
            }
          }
        }
      }
    }
  `);

  const form = useMemo(
    () =>
      data.forms.nodes.find(({ parent }) => parent.name === name)?.frontmatter
        ?.form,
    [name, data.forms.nodes]
  );

  if (!form) {
    return null;
  }

  return (
    <FormStyled {...props}>
      <FormContainer {...form} />
    </FormStyled>
  );
};
