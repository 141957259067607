import React from "react";
import { styled } from "@mui/material/styles";
import { Icon, Typography } from "@mui/material";
import { usePrevious, useThrottle } from "react-use";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

import { SubNavigationList, SubNavigationListProps } from "./SubNavigationList";
import { SecondaryButton } from "@/components/buttons";
import { bannerHeight, useBannerContext } from "@/components/banner";
import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";

const PREFIX = "SubNav";

const classes = {
  open: `${PREFIX}-open`,
  container: `${PREFIX}-container`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`,
  column: `${PREFIX}-column`,
  tag: `${PREFIX}-tag`,
  subItemFooter: `${PREFIX}-subItemFooter`,
  specialLinkContainer: `${PREFIX}-specialLinkContainer`,
};

const SubNavStyled = styled("div", {
  shouldForwardProp: notForwarded(["open"]),
})<{ open: boolean }>(({ theme, open }) => ({
  position: "fixed",
  color: colors.deepPurple,
  left: 0,
  right: 0,
  top: 60,
  opacity: 0,
  borderRadius: 8,
  transition: `${baseTransition}, opacity 0.1s, z-index 0s`,
  transform: "translateX(80px) translateY(0)",
  zIndex: -1,
  display: "flex",
  ...(open
    ? {
        opacity: 1,
        zIndex: 1,
      }
    : {}),
  [`&.${classes.open}`]: {
    opacity: 1,
    zIndex: 1,
  },
  [`& .${classes.container}`]: {
    zIndex: 2,
    overflow: "hidden",
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    maxWidth: "100%",
    boxShadow: "0px 8px 24px 4px rgba(0, 0, 0, 0.16)",
  },
  [`& .${classes.contentContainer}`]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backdropFilter: "blur(30px)",
    maxWidth: "100%",
  },
  [`& .${classes.content}`]: {
    padding: 0,
    display: "flex",
    flexDirection: "row",
    flex: 1,
    backgroundColor: `rgba(${grayBackgroundDarkness}, .97)`,
  },
  [`& .${classes.column}`]: {},
  [`& .${classes.tag}`]: {
    fontSize: 18,
    padding: theme.spacing(2, 2, 1),
  },
  [`& .${classes.subItemFooter}`]: {
    backgroundColor: "rgb(255, 255, 255)",
    "@supports (backdrop-filter: blur(20px))": {
      backgroundColor: "rgba(255, 255, 255, .95)",
    },
    padding: 0,
    zIndex: 1,
    flex: 1,
    flexGrow: 2,
  },
  [`& .${classes.specialLinkContainer}`]: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: -1,
    padding: theme.spacing(1.5),
    position: "relative",
  },
}));

const grayBackgroundDarkness = Array(3).fill("255").join(", ");

const baseTransition = "width 0.2s";

interface SubNavigationProps {
  subItems: SubNavigationListProps["items"];
  subNavOpen: boolean;
  navTarget?: HTMLElement;
  onSelection: () => void;
  columns:
    | {
        tags: string[];
        hideTitles: boolean;
        nav: string;
      }
    | undefined;
  specialLink?: {
    label: string;
    to: string;
    icon?: string;
    text: string;
    link: string;
    color: Color;
    backgroundColor: Color;
    hide?: boolean;
    specialIcon?: string;
  };
}

export const SubNavigation = ({
  subItems,
  subNavOpen,
  navTarget,
  onSelection,
  columns: columnsBase,
  specialLink,
  ...props
}: SubNavigationProps) => {
  const { hasMessage } = useBannerContext();

  const throttledNavOpen = useThrottle(subNavOpen, 300);

  const open = subNavOpen || throttledNavOpen;

  const subNavWasOpen = usePrevious(open);

  const { offsetWidth = 0, offsetLeft = 0 } = navTarget || {};

  const columns = columnsBase?.tags.map((tag) => ({
    label: tag,
    subItems: subItems.filter((item) => item.tags?.[0] === tag),
    hideTitles: columnsBase?.hideTitles,
  }));

  const width =
    columns?.length === 4
      ? 1275
      : columns?.length === 3
      ? 950
      : columns?.length === 2
      ? 700
      : 450;

  const translateAmount = Math.max(offsetWidth / 2 + offsetLeft - width / 2, 0);

  const style: { transform: string; width: number; transition: string } = {
    transform: `translateX(${translateAmount}px) translateY(${
      hasMessage ? bannerHeight : 0
    }px)`,
    transition: open
      ? `${baseTransition}, transform 0.3s, opacity 0.1s, z-index 0s`
      : subNavWasOpen
      ? `${baseTransition}, transform 0.3s, opacity 0.3s, z-index 0.3s`
      : "",
    width,
  };

  return (
    <SubNavStyled open={open} style={style} {...props}>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {columns?.length ? (
              columns.map(({ label, subItems, hideTitles }) => (
                <div className={classes.column} key={label}>
                  {!hideTitles ? (
                    <Typography variant="h6" className={classes.tag}>
                      {label}
                    </Typography>
                  ) : null}
                  <SubNavigationList
                    items={subItems}
                    key={label}
                    onSelection={onSelection}
                  />
                </div>
              ))
            ) : (
              <SubNavigationList items={subItems} onSelection={onSelection} />
            )}
          </div>
        </div>
        {specialLink && !specialLink.hide && (
          <div
            className={classes.specialLinkContainer}
            style={{
              backgroundColor:
                colors[specialLink.backgroundColor] || colors.white01,
            }}
          >
            <SecondaryButton
              startIcon={
                specialLink.specialIcon && (
                  <Icon>{specialLink.specialIcon}</Icon>
                )
              }
              style={{
                textTransform: "none",
                color: colors[specialLink.color] || colors.periwinkle,
              }}
              segmentType="navbar"
              to={specialLink.link}
              component={Link}
            >
              {specialLink.text}
            </SecondaryButton>
          </div>
        )}
      </div>
    </SubNavStyled>
  );
};
