import React from "react";
import { styled } from "@mui/material/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";

import QuoteIcon from "@/components/svgs/quoteIcon";
import { alignment } from "@/theme/alignment";
import { Color } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";
import { Spacing, spacingMap } from "@/utils/spacing";

const PREFIX = "PrimaryTestimonial";

const classes = {
  testimonial: `${PREFIX}-testimonial`,
  avatar: `${PREFIX}-avatar`,
  avatarImage: `${PREFIX}-avatarImage`,
  content: `${PREFIX}-content`,
  quoteIcon: `${PREFIX}-quoteIcon`,
  quote: `${PREFIX}-quote`,
  name: `${PREFIX}-name`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`,
};

const PrimaryTestimonialStyled = styled("div", {
  shouldForwardProp: notForwarded(["color", "background", "padding"]),
})<Pick<PrimaryTestimonialProps, "background" | "color" | "padding">>(
  ({ theme, background, color, padding = "small" }) => ({
    [`&.${classes.testimonial}`]: {
      ...alignment,
      backgroundColor: transformColor(background) || "inherit",
      borderRadius: theme.shape.borderRadius,
      color: transformColor(color) || "inherit",
      display: "flex",
      justifyContent: "space-between",
      letterSpacing: "0.01071em",
      lineHeight: "1.43",
      margin: theme.spacing(0, "auto"),
      padding: theme.spacing(
        spacingMap["small"],
        spacingMap["small"],
        spacingMap[padding]
      ),
      paddingTop: theme.spacing(7),
      position: "relative",
      [theme.breakpoints.down(800)]: {
        flexWrap: "wrap",
        paddingTop: theme.spacing(8),
        textAlign: "center",
      },
    },
    [`& .${classes.avatar}`]: {
      height: 128,
      marginRight: theme.spacing(4),
      width: 128,
      [theme.breakpoints.down(800)]: {
        marginBottom: theme.spacing(4),
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    [`& .${classes.avatarImage}`]: {
      borderRadius: "50%",
      border: 0,
    },
    [`& .${classes.content}`]: {},
    [`& .${classes.quoteIcon}`]: {
      display: "block",
      left: 6,
      opacity: 0.15,
      position: "absolute",
      top: theme.spacing(1),
      width: 80,
      height: 80,
      [theme.breakpoints.down(800)]: {
        left: "calc(50% - 110px)",
        top: theme.spacing(2),
      },
    },
    [`& .${classes.quote}`]: {
      fontSize: 22,
      margin: 0,
    },
    [`& .${classes.name}`]: {
      fontSize: 18,
      fontWeight: 800,
      margin: 0,
      marginTop: theme.spacing(3),
    },
    [`& .${classes.title}`]: {
      fontSize: 15,
      margin: 0,
    },
    [`& .${classes.logo}`]: {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(800)]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
      img: {
        border: 0,
        borderRadius: 0,
      },
    },
  })
);

interface PrimaryTestimonialProps {
  avatar: any;
  background?: Color | string;
  className: string;
  color?: Color | string;
  companyLogo: any;
  name: string;
  padding: Spacing;
  quote: string;
  title: string;
}

export const PrimaryTestimonial = ({
  avatar,
  background,
  className,
  color,
  companyLogo,
  name,
  padding,
  quote,
  title,
}: PrimaryTestimonialProps) => {
  return (
    <PrimaryTestimonialStyled
      background={background}
      className={clsx(classes.testimonial, className)}
      color={color}
      padding={padding}
    >
      <QuoteIcon className={classes.quoteIcon} />
      {avatar && (
        <div className={classes.avatar}>
          <GatsbyImage
            alt={`Testimonial from ${name}`}
            image={avatar}
            imgClassName={classes.avatarImage}
            objectFit="scale-down"
          />
        </div>
      )}
      <div className={classes.content}>
        <p className={classes.quote}>{quote}</p>
        {name && <p className={classes.name}>{name}</p>}
        {title && <p className={classes.title}>{title}</p>}
        {companyLogo && (
          <GatsbyImage
            objectFit="scale-down"
            className={classes.logo}
            image={companyLogo}
            alt={`${name}'s Company Logo`}
          />
        )}
      </div>
    </PrimaryTestimonialStyled>
  );
};
