import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import VisibilitySensor from "react-visibility-sensor";
import clsx from "clsx";

const PREFIX = "Animate";

const classes = {
  base: `${PREFIX}-base`,
  right: `${PREFIX}-right`,
  left: `${PREFIX}-left`,
  visible: `${PREFIX}-visible`,
};

const AnimateStyled = styled(VisibilitySensor)(() => ({
  [`& .${classes.base}`]: {
    transition: "all 1s, transform .4s cubic-bezier(0.39, 0.575, 0.565, 1)",
    opacity: 0,
  },

  [`& .${classes.right}`]: {
    transform: "translateX(3vw)",
  },

  [`& .${classes.left}`]: {
    transform: "translateX(-3vw)",
  },

  [`& .${classes.visible}`]: {
    transform: "translateX(0)",
    opacity: 1,
  },
}));

const Animate = ({ animation = {}, children, ...props }) => {
  const [visible, setVisible] = useState(false);

  if (!animation.from) {
    return <div {...props}>{children}</div>;
  }

  return (
    <AnimateStyled
      partialVisibility
      minTopValue={0}
      onChange={(newValue) => {
        if (newValue) {
          setVisible(newValue);
        }
      }}
    >
      <div {...props}>
        <div
          className={clsx(
            classes.base,
            classes[animation.from],
            visible && classes.visible
          )}
        >
          {children}
        </div>
      </div>
    </AnimateStyled>
  );
};

export default Animate;
