export type Spacing =
  | "none"
  | "xxsmall"
  | "xsmall"
  | "default"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

export const spacingMap: Record<Spacing, number> = {
  none: 0,
  xxsmall: 1,
  xsmall: 2,
  default: 3,
  small: 6,
  medium: 9,
  large: 12,
  xlarge: 15,
  xxlarge: 18,
};

export const spacingSmallMap: Record<Spacing, number> = {
  none: 0,
  xxsmall: 1,
  xsmall: 2,
  default: 3,
  small: 4,
  medium: 6,
  large: 8,
  xlarge: 10,
  xxlarge: 12,
};
