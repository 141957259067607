import React from "react";
import { styled } from "@mui/material/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

import { GraphQLQuery } from "@/types/GraphQLQuery";
import { PrimaryButton } from "@/components/buttons";
import { colors } from "@/theme/colors";

const PREFIX = "Download";

const classes = {
  container: `${PREFIX}-container`,
  image: `${PREFIX}-image`,
  content: `${PREFIX}-content`,
  headline: `${PREFIX}-headline`,
  text: `${PREFIX}-text`,
  cta: `${PREFIX}-cta`,
};

const HeroDownloadStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [`& .${classes.container}`]: {
    background: colors.white01,
    borderRadius: 4,
    display: "flex",
    maxWidth: 650,
    padding: "2em",
    textAlign: "left",
    width: "100%",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 1em",
    },
    [theme.breakpoints.down(800)]: {
      flexWrap: "wrap",
      maxWidth: 450,
      textAlign: "center",
    },
  },
  [`& .${classes.image}`]: {
    borderRadius: 4,
    marginRight: "1em",
    maxWidth: 250,
    width: "100%",
    [theme.breakpoints.down(800)]: {
      marginBottom: "2em",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "none",
      width: "100%",
    },
  },
  [`& .${classes.content}`]: {
    flex: 1,
  },
  [`& .${classes.headline}`]: {
    color: colors.prismaticBlack,
    fontSize: 22,
    fontWeight: 800,
    marginBottom: ".25em",
  },
  [`& .${classes.text}`]: {
    color: colors.gray08,
    fontSize: 16,
    marginBottom: "1em",
  },
  [`& .${classes.cta}`]: {},
}));

export interface HeroDownload {
  callToAction: {
    text: string;
    link: string;
  };
  headline: string;
  image: GraphQLQuery;
  text: string;
}

export const HeroDownload = ({
  callToAction,
  headline,
  image,
  text,
}: HeroDownload) => {
  return (
    <HeroDownloadStyled>
      <div className={classes.container}>
        <GatsbyImage
          loading="eager"
          image={image.childImageSharp.gatsbyImageData}
          alt={`${headline} Download`}
          className={classes.image}
        />
        <div className={classes.content}>
          <div className={classes.headline}>{headline}</div>
          <div className={classes.text}>{text}</div>
          {callToAction && (
            <div className={classes.cta}>
              <PrimaryButton
                component={Link}
                to={callToAction.link}
                size="large"
              >
                {callToAction.text}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </HeroDownloadStyled>
  );
};
