import React, { useMemo } from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import { ComparisonTable } from "@/components/ComparisonTable";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";

const ComparisonStyled = styled(ComparisonTable, {
  shouldForwardProp: notForwarded(["spacing"]),
})<Pick<ComparisonProps, "spacing">>(({ theme, spacing = "default" }) => ({
  margin: theme.spacing(spacingMap[spacing], "auto"),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(spacingSmallMap[spacing], "auto"),
  },
}));

interface ComparisonProps {
  name: string;
  spacing?: Spacing;
}

export const Comparison = ({ name, spacing }: ComparisonProps) => {
  const data = useStaticQuery<GraphQLQuery>(graphql`
    query getComparisonData {
      comparisons: allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "**/content/referenceData/comparison/**.md"
          }
        }
      ) {
        nodes {
          parent {
            ... on File {
              id
              name
            }
          }
          frontmatter {
            comparison {
              categories {
                featured
                subTitle
                tag
                title
                image {
                  publicURL
                }
                cta {
                  featured
                  link
                  text
                }
              }
              features {
                items {
                  excluding
                  specific {
                    including
                    title
                    tooltip
                  }
                  title
                  tooltip
                }
                groupName
              }
            }
          }
        }
      }
    }
  `);

  const comparison = useMemo(
    () =>
      data.comparisons.nodes.find(({ parent }) => parent.name === name)
        ?.frontmatter?.comparison,
    [name, data.comparisons.nodes]
  );

  if (!comparison) {
    return null;
  }

  return <ComparisonStyled comparison={comparison} spacing={spacing} />;
};

export const OverflowContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  if (matchesMd) {
    return <>{children}</>;
  }

  return (
    <Box mx="-100px" width="calc(100% + 200px)">
      {" "}
      {children}
    </Box>
  );
};
