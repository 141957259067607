import React from "react";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Checkbox } from "@mui/material";

import Field from "./Field";
import { colors } from "@/theme/colors";

const PREFIX = "CheckboxField";

const classes = {
  checkbox: `${PREFIX}-checkbox`,
};

const StyledField = styled(Field)(() => ({
  [`& .${classes.checkbox}`]: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: colors.mintGreen,
    },
  },
}));

export const CheckboxBase = ({
  checked,
  name,
  onChange,
  onBlur,
  label,
  error,
  ...rest
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name ?? null}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.checkbox}
        />
      }
      label={label}
      error={error}
      {...rest}
    />
  );
};

export const CheckboxField = ({ name, label, ...rest }) => {
  return (
    <StyledField name={name} label={label}>
      {({ field }) => (
        <CheckboxBase
          checked={field.value}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          label={field.label}
          error={field.error?.toString()}
          color="secondary"
          {...rest}
        />
      )}
    </StyledField>
  );
};

export default CheckboxField;
