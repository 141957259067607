import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import clsx from "clsx";
import { Icon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors } from "@/theme/colors";

const pulseColor = "53, 197, 177";
const startingPoint = 20;
const getTransform = (multiplier) =>
  `translateY(${-startingPoint * multiplier + startingPoint}vh)`;

const PREFIX = "SectionPoints";

const classes = {
  pointContainer: `${PREFIX}-pointContainer`,
  point: `${PREFIX}-point`,
  visible: `${PREFIX}-visible`,
  pulse: `${PREFIX}-pulse`,
};

const SectionPointsStyled = styled(VisibilitySensor)(() => ({
  "@keyframes bounce": {
    "0%": {
      transform: getTransform(0),
      opacity: 0,
    },
    "16%": {
      transform: getTransform(1.2227),
      opacity: 0.8,
    },
    "28%": {
      transform: getTransform(0.988),
      opacity: 1,
    },
    "44%": {
      transform: getTransform(1.0463),
      opacity: 1,
    },
    "59%": {
      transform: getTransform(0.9936),
      opacity: 1,
    },
    "73%": {
      transform: getTransform(1.0058),
      opacity: 1,
    },
    "88%": {
      transform: getTransform(0.9998),
      opacity: 1,
    },
    "100%": {
      transform: getTransform(1),
      opacity: 1,
    },
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: `0 0 0 0 rgba(${pulseColor}, 0.7)`,
    },
    "40%": {
      transform: "scale(1)",
      boxShadow: `0 0 0 15px rgba(${pulseColor}, 0)`,
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: `0 0 0 0 rgba(${pulseColor}, 0)`,
    },
  },

  [`& .${classes.pointContainer}`]: {
    position: "absolute",
    zIndex: 999,
    borderRadius: "50%",
    cursor: "pointer",
    transition: "all .5s",
    opacity: 0,
    transform: getTransform(0),
  },

  [`& .${classes.point}`]: {
    fontSize: "24px !important",
    display: "block !important",
    padding: "0.25em",
    height: "1.5em",
    width: "1.5em",
    borderRadius: "50%",
    backgroundColor: colors.mintGreen,
    color: colors.white01,
    boxShadow: `0 0 0 0 rgba(${pulseColor}, 1)`,
    transition: "all .3s",
    transform: "rotate(0deg)",
    "&:hover": {
      transform: "rotate(90deg)",
    },
  },

  [`& .${classes.visible}`]: { animation: "$bounce 2s forwards" },

  [`& .${classes.pulse}`]: { opacity: 1, animation: "$pulse 2s infinite" },
}));

export const SectionPoints = ({ points }) => {
  const [visible, setVisible] = useState(false);
  const [pulse, setPulse] = useState(false);

  if (!points) {
    return null;
  }

  return (
    <SectionPointsStyled
      minTopValue={500}
      onChange={(newValue) => {
        if (newValue) {
          setVisible(newValue);
          setTimeout(() => setPulse(true), 2500);
        }
      }}
    >
      <div style={{ minWidth: 1, minHeight: 1 }}>
        {points.map(({ x, y, message }, index) => (
          <div
            key={`${x},${y}`}
            className={clsx(
              classes.pointContainer,
              visible && !pulse && classes.visible,
              pulse && classes.pulse
            )}
            style={{
              left: `${x}%`,
              bottom: `${y}%`,
              animationDelay: getAnimationDelay(index, pulse),
            }}
          >
            <Tooltip placement="top" title={message}>
              <Icon className={clsx(classes.point, "material-icons-round")}>
                add_circle
              </Icon>
            </Tooltip>
          </div>
        ))}
      </div>
    </SectionPointsStyled>
  );
};

function getAnimationDelay(index, pulse) {
  return pulse ? undefined : `${200 * (index + (index % 2 === 0 ? -1 : 1))}ms`;
}
