import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";

const PREFIX = "FormConfirmation";

const classes = {
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
  actions: `${PREFIX}-actions`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: "1.5em",
    paddingLeft: "2em",
    paddingBottom: 0,
  },

  [`& .${classes.content}`]: {
    fontSize: "1.2em",
    lineHeight: 2,
    display: "flex",
    alignItems: "center",
    paddingBottom: 0,
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
    },
  },

  [`& .${classes.actions}`]: {
    paddingTop: 0,
  },
}));

const FormConfirmation = ({ open, setOpen, confirmation }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.title}>
        {confirmation.primary}
      </DialogTitle>
      <DialogContent className={classes.content} data-cy="sign-up-confirmation">
        <GatsbyImage
          style={{ borderRadius: 10, minWidth: 200 }}
          image={confirmation.confirmationImage.childImageSharp.gatsbyImageData}
          alt="Prismatic form confirmation image"
        />
        <div>{confirmation.secondary}</div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default FormConfirmation;
