import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";

const PrimaryButtonStyled = styled(Button, {
  shouldForwardProp: notForwarded(["styles"]),
})<{
  styles: { background: string; color: string };
}>(({ styles }) => ({
  background: transformColor(styles.background) || colors.periwinkle,
  color: transformColor(styles.color) || colors.white01,
  "&:hover": {
    background: transformColor(styles.background) || colors.periwinkle,
    opacity: 0.7,
  },
}));

export interface CTAProps {
  background: Color | string;
  className: string;
  color: Color | string;
  [props: string]: any;
}

const PrimaryButton = ({ className, background, color, ...props }) => {
  return (
    <PrimaryButtonStyled
      disableElevation
      variant="contained"
      className={className}
      styles={{
        background,
        color,
      }}
      {...props}
    />
  );
};

export default PrimaryButton;
