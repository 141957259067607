import React from "react";
import { styled, SxProps, Theme } from "@mui/material";

import { notForwarded } from "@/utils/notForwarded";

const ContainerStyled = styled("div", {
  shouldForwardProp: notForwarded(["maxWidth", "gutter", "sx"]),
})<Pick<ContainerProps, "maxWidth" | "gutter">>(
  ({ theme, maxWidth = 1068, gutter = 3 }) => ({
    margin: theme.spacing(0, "auto"),
    maxWidth: maxWidth,
    padding: theme.spacing(0, gutter),
  })
);

interface ContainerProps {
  children: React.ReactNode;
  gutter?: number;
  maxWidth?: number | string;
  sx?: SxProps<Theme> | undefined;
}

export const Container = ({ children, ...props }: ContainerProps) => {
  return <ContainerStyled {...props}>{children}</ContainerStyled>;
};
