export const getColorRangeHex = (
  color1: number[],
  color2: number[],
  pieces: number,
  name: string
): Record<string, string> => {
  const sliceSize = 100 / (pieces + 1);

  return Array(pieces)
    .fill(0)
    .reduce((previous, _, index) => {
      const percentage = sliceSize * (index + 1);

      return {
        ...previous,
        [`${name}${index + 1}`]: `rgb(${pickHex(
          color1,
          color2,
          percentage / 100
        ).join(", ")})`,
      };
    }, {});
};

const pickHex = (color1: number[], color2: number[], weight: number) => {
  const weight1 = weight;
  const weight2 = 1 - weight1;

  const rgb = [
    Math.round(color1[0] * weight1 + color2[0] * weight2),
    Math.round(color1[1] * weight1 + color2[1] * weight2),
    Math.round(color1[2] * weight1 + color2[2] * weight2),
  ];

  return rgb;
};
