import React from "react";

import LegalContainer from "../page-components/legal/LegalContainer";

const Layout = ({ children, ...props }) => {
  if (props.location.pathname.startsWith("/legal")) {
    return <LegalContainer {...props}>{children}</LegalContainer>;
  }

  return children;
};

export default Layout;
