import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useStaticQuery, graphql } from "gatsby";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Icon,
  Chip,
  Box,
} from "@mui/material";
import clsx from "clsx";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import { getListItemProps } from "../getListItemProps";
import { colors } from "@/theme/colors";

const PREFIX = "SubNavigationList";

const classes = {
  itemText: `${PREFIX}-itemText`,
  avatar: `${PREFIX}-avatar`,
  listItem: `${PREFIX}-listItem`,
  tag: `${PREFIX}-tag`,
};

const SubNavigationListStyled = styled(List)(({ theme }) => ({
  [theme.breakpoints.up(400)]: {
    minWidth: 320,
  },
  paddingTop: 0,
  [`& .${classes.itemText}`]: {
    "& .MuiListItemText-primary": {
      fontWeight: 500,
      fontSize: 15,
    },
    "& .MuiListItemText-secondary": {
      fontSize: 14,
      marginTop: theme.spacing(0.5),
    },
  },
  [`& .${classes.avatar}`]: {
    minWidth: 42,
    display: "flex",
    ".material-icons-round": {
      fontSize: 28,
    },
  },
  [`& .${classes.listItem}`]: {
    padding: theme.spacing(1.25, 2.5),
  },

  [`& .${classes.tag}`]: {
    borderColor: colors.mintGreen,
    color: colors.mintGreen,
    fontSize: 12,
    fontWeight: 700,
  },
}));

export interface SubNavigationListProps {
  items: {
    href: string;
    icon: string | IGatsbyImageData;
    subTitle?: string;
    text: string;
    tags?: string[];
  }[];
  onSelection?: any;
  showTags?: boolean;
}

export const SubNavigationList = ({
  items,
  onSelection,
  showTags = false,
}: SubNavigationListProps) => {
  const { featureFlags } = useStaticQuery(graphql`
    query getFeatureFlags {
      featureFlags {
        events
      }
    }
  `);

  return (
    <SubNavigationListStyled disablePadding>
      {items?.map((subItem, index) => {
        if (subItem.href === "/events/" && !featureFlags.events) {
          return null;
        }

        return (
          <ListItem
            key={index}
            button
            className={classes.listItem}
            onClick={onSelection}
            {...getListItemProps({ ...subItem, segmentType: "navbar" })}
          >
            <ListItemAvatar className={classes.avatar}>
              {typeof subItem.icon === "object" ? (
                <GatsbyImage
                  alt={subItem.text}
                  image={subItem.icon as IGatsbyImageData}
                  loading="eager"
                  style={{ marginRight: "12px" }}
                  objectFit="scale-down"
                />
              ) : (
                <Icon
                  className="material-icons-round"
                  style={{ color: colors.mintGreen }}
                >
                  {subItem.icon}
                </Icon>
              )}
            </ListItemAvatar>
            <Box component={showTags ? "div" : Fragment}>
              <ListItemText
                className={clsx(classes.itemText)}
                primary={subItem.text}
                secondary={subItem.subTitle}
              />
              {showTags && subItem.tags && subItem.tags.length > 0 ? (
                <Box display="flex" gap={1} flexWrap="wrap">
                  {subItem.tags.map((tag) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      className={classes.tag}
                      label={tag}
                    />
                  ))}
                </Box>
              ) : null}
            </Box>
          </ListItem>
        );
      })}
    </SubNavigationListStyled>
  );
};
