import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "gatsby";

import { colors, Colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";

const TagStyled = styled("span", {
  shouldForwardProp: notForwarded(["color", "isActive", "size"]),
})<{ color: string; isActive?: boolean; size: TagProps["size"] }>(
  ({ color, isActive, theme, size }) => ({
    backgroundColor: "transparent",
    borderColor: color,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    color,
    cursor: "default",
    display: "inline-block",
    fontWeight: 500,
    lineHeight: 1,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "uppercase",
    ...(size === "small"
      ? {
          padding: theme.spacing(0.75),
          fontSize: 12,
        }
      : size === "medium"
      ? {
          padding: theme.spacing(1),
          fontsize: 14,
        }
      : size === "large"
      ? {
          padding: theme.spacing(1.25),
          fontSize: 16,
        }
      : {}),
    "& a": {
      color,
      cursor: "pointer",
      textDecoration: "none",
    },
    ...(isActive
      ? {
          backgroundColor: color,
          color: theme.palette.common.white,
          "& a": {
            color: "currentColor",
          },
        }
      : {}),
  })
);

export interface TagProps {
  color: keyof Colors | undefined;
  isActive?: boolean;
  name: string;
  size?: "small" | "medium" | "large";
  slug: string | undefined;
}

export const Tag = ({
  color: colorBase = "turquoise",
  isActive,
  name,
  size = "small",
  slug,
}: TagProps) => {
  const color = colors?.[colorBase || "turquoise"];

  if (!name) {
    return null;
  }

  if (slug) {
    return (
      <TagStyled color={color} size={size} isActive={isActive}>
        <Link to={slug}>{name}</Link>
      </TagStyled>
    );
  }

  return (
    <TagStyled color={color} size={size}>
      {name}
    </TagStyled>
  );
};
