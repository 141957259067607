import { Background } from "./Background";
import { Banner } from "./Banner";
import { Box } from "@mui/material";
import { Button } from "./Button";
import { Column } from "./Column";
import { Columns } from "./Columns";
import { Comparison, OverflowContainer } from "./Comparison";
import { ConnectorsContainer as Connectors } from "./Connectors";
import { Contact } from "./Contact";
import { Cta } from "./Cta";
import { Download } from "./Download";
import { Form } from "./Form";
import { Hero } from "../Hero";
import { Link } from "./Link";
import { List } from "./List";
import { LogoCloud } from "../LogoCloud";
import { Media } from "./Media";
import { MediaStyles } from "./MediaStyles";
import { Outgrow } from "./Outgrow";
import { Resource } from "./Resource";
import { Testimonial } from "./Testimonial";
import { Testimonials } from "@/components/Testimonials";
import TestimonialsGrid from "@/components/Testimonials/Grid";
import { Tooltip } from "../Tooltip";
import { Typography } from "./Typography";
import { Video } from "../Video";

export {
  Background,
  Banner,
  Button,
  Column,
  Columns,
  Comparison,
  OverflowContainer,
  Connectors,
  Contact,
  Cta,
  Download,
  Form,
  Link,
  List,
  Media,
  MediaStyles,
  Outgrow,
  Resource,
  Testimonial,
  Testimonials,
  TestimonialsGrid,
  Typography,
};

const components = {
  Background,
  Banner,
  Box,
  Button,
  Column,
  Columns,
  Comparison,
  OverflowContainer,
  Connectors,
  Contact,
  Cta,
  Download,
  Form,
  Hero,
  Link,
  List,
  LogoCloud,
  Media,
  MediaStyles,
  Outgrow,
  Resource,
  Testimonial,
  Testimonials,
  TestimonialsGrid,
  Tooltip,
  Typography,
  Video,
};

export default components;
