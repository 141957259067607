import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Carousel from "@/components/carousel";
import ConditionalWrapper from "@/utils/ConditionalWrapper";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { PrimaryTestimonial } from "@/components/Testimonials/PrimaryTestimonial";
import { SecondaryTestimonial } from "@/components/Testimonials/SecondaryTestimonial";
import { Section } from "@/components/section";
import { Color } from "@/theme/colors";
import { Spacing } from "@/utils/spacing";

type TestimonialVariants = {
  [variant: string]: React.ElementType;
};

const testimonialVariants: TestimonialVariants = {
  secondary: SecondaryTestimonial,
  primary: PrimaryTestimonial,
};

interface TestimonialsProps {
  background?: Color | string;
  className?: string;
  color?: Color | string;
  fullWidth?: boolean;
  ids: string | string[];
  padding?: Spacing;
  spacing?: Spacing;
  style?: React.CSSProperties;
  variant?: "primary" | "secondary";
}

export const Testimonials = ({
  background,
  className,
  color,
  fullWidth = false,
  ids = [],
  padding,
  spacing,
  style,
  variant = "primary",
}: TestimonialsProps) => {
  const data = useStaticQuery<GraphQLQuery>(graphql`
    query getTestimonialsData {
      testimonials: markdownRemark(
        fileAbsolutePath: { glob: "**/content/referenceData/testimonials.md" }
      ) {
        frontmatter {
          testimonialStore {
            id
            person {
              companyLogo {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: NONE
                    quality: 85
                    width: 192
                  )
                }
              }
              avatar {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    placeholder: NONE
                    quality: 85
                    width: 128
                  )
                }
              }
              name
              title
            }
            quote
          }
        }
      }
    }
  `);
  const { testimonialStore } = data.testimonials.frontmatter;
  const Testimonial = testimonialVariants?.[variant];

  // find/filter testimonials by ids (if no ids, show all)
  const results = ids?.length
    ? testimonialStore.filter(({ id }) =>
        (typeof ids === "string" ? ids.split(",") : ids).includes(id)
      )
    : testimonialStore;

  // return, no results
  if (!results.length) {
    return null;
  }

  // single testimonial ~ use default
  if (results.length === 1 || variant === "secondary") {
    return (
      <ConditionalWrapper
        condition={fullWidth}
        wrapper={(children) => (
          <Section
            background={background}
            color={color}
            padding={padding}
            spacing={spacing}
            style={style}
          >
            {children}
          </Section>
        )}
      >
        <Testimonial
          avatar={results[0]?.person?.avatar?.childImageSharp?.gatsbyImageData}
          background={!fullWidth ? background : undefined}
          className={className}
          companyLogo={
            results[0]?.person?.companyLogo?.childImageSharp?.gatsbyImageData
          }
          color={!fullWidth ? color : undefined}
          name={results[0]?.person?.name}
          quote={results[0].quote}
          title={results[0]?.person?.title}
        />
      </ConditionalWrapper>
    );
  }

  // multiple testimonials ~ use carousel
  return (
    <ConditionalWrapper
      condition={fullWidth}
      wrapper={(children) => (
        <Section
          background={background}
          color={color}
          padding={padding}
          spacing={spacing}
          style={style}
        >
          {children}
        </Section>
      )}
    >
      <Carousel items={results}>
        {(result) => (
          <Testimonial
            avatar={result?.person?.avatar?.childImageSharp?.gatsbyImageData}
            background={!fullWidth ? background : undefined}
            className={className}
            companyLogo={
              result?.person?.companyLogo?.childImageSharp?.gatsbyImageData
            }
            color={!fullWidth ? color : undefined}
            name={result?.person?.name}
            quote={result.quote}
            title={result?.person?.title}
          />
        )}
      </Carousel>
    </ConditionalWrapper>
  );
};
