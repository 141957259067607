import React from "react";
import { styled } from "@mui/material/styles";

const SectionGroupStyled = styled("section")(() => ({
  overflow: "hidden",
}));

export const SectionGroup = (props) => {
  return <SectionGroupStyled {...props} />;
};
