import React from "react";
import { Button, ButtonProps, styled } from "@mui/material";
import { ArrowBackIosRounded } from "@mui/icons-material";

const EndIconStyled = styled("span")(() => ({
  display: "flex",
  height: "1em",
  position: "relative",
  transform: "rotate(180deg)",
  width: "1em",
  ".arrow": {
    bottom: 0,
    fontSize: "0.8em",
    margin: "auto",
    position: "absolute",
    top: 0,
  },
  ".arrow__left": {
    right: "-0.3em",
  },
  ".arrow__right": {
    right: "0.2em",
  },
}));

const EndIcon = (props) => {
  return (
    <EndIconStyled {...props}>
      <ArrowBackIosRounded className="arrow arrow__left" />
      <ArrowBackIosRounded className="arrow arrow__right" />
    </EndIconStyled>
  );
};

const LinkStyled = styled(Button)(({ color }) => ({
  color: !color ? "currentColor" : undefined,
  "& .MuiButton-endIcon": {
    transition: "transform 0.25s ease-in-out",
  },
  "&:hover": {
    backgroundColor: "unset",
    opacity: 1,
    "& .MuiButton-endIcon": {
      transform: "translateX(0.4em)",
    },
  },
}));

interface LinkProps extends ButtonProps {
  noArrows?: boolean;
  label?: string;
}

export const Link = ({ noArrows, label, ...props }: LinkProps) => {
  return (
    <LinkStyled
      children={label}
      disableElevation
      endIcon={!noArrows ? <EndIcon /> : undefined}
      variant="text"
      {...props}
    />
  );
};
