import { useState } from "react";
import { useWindowScroll } from "react-use";
import { useStaticQuery, graphql } from "gatsby";

export function useNavigationState() {
  const [navTarget, setNavTarget] = useState(null);
  const [mouseOverButtons, setMouseOverButtons] = useState({});
  const [mouseOverMenu, setMouseOverMenu] = useState(false);
  const [subNavName, setSubNavigationName] = useState(undefined);

  const setMouseOverButton = (name, newValue) =>
    setMouseOverButtons((state) => ({ ...state, [name]: newValue }));

  const subNavOpen =
    Object.values(mouseOverButtons).some(Boolean) || mouseOverMenu;
  const { subItems, specialLink } = useSubItems({ subNavName });

  const bindNavigationItem = (name) => ({
    onMouseEnter: ({ currentTarget }) => {
      setNavTarget(currentTarget);
      setSubNavigationName(name);
      setMouseOverButton(name, true);
    },
    onMouseLeave: () => setMouseOverButton(name, false),
    open: subNavOpen && subNavName === name,
  });

  const bindSubNavigation = () => ({
    onMouseEnter: () => setMouseOverMenu(true),
    onMouseLeave: () => setMouseOverMenu(false),
    onSelection: () => setMouseOverMenu(false),
    subNavOpen,
    subItems,
    navTarget,
    specialLink,
  });

  return {
    bindNavigationItem,
    bindSubNavigation,
    subNavName,
  };
}

export function useHasScroll({ noStick = false }) {
  const scroll = useWindowScroll();

  return !noStick && scroll.y > 40;
}

interface UseSubItemsProps {
  subNavName: string;
}

const useSubItems = ({ subNavName }: UseSubItemsProps) => {
  const { navData, specialLinks } = useNavData();

  return {
    specialLink: specialLinks.find(({ navName }) => navName === subNavName),
    subItems: navData[subNavName],
  };
};

export const useNavData = () => {
  const data = useStaticQuery(graphql`
    query getNavData {
      site: markdownRemark(
        fileAbsolutePath: { glob: "**/content/site-meta.md" }
      ) {
        frontmatter {
          navbar {
            specialLinks {
              navName
              text
              link
              backgroundColor
              color
              specialIcon
              hide
            }
          }
        }
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "!**/{pages,blog,resources,careers,referenceData}/**"
          }
        }
      ) {
        nodes {
          frontmatter {
            title
            subTitle
            icon
            order
            tags
          }
          fileAbsolutePath
          fields {
            slug
          }
        }
      }
      developers: markdownRemark(
        fileAbsolutePath: { glob: "**/content/navigation/header/developers.md" }
      ) {
        ...navigationFields
      }
      resources: markdownRemark(
        fileAbsolutePath: { glob: "**/content/navigation/header/resources.md" }
      ) {
        ...navigationFields
      }
    }
  `);

  const developerItems = data.developers.frontmatter.items.map((item) => ({
    frontmatter: item,
    fields: {
      slug: "/developers/",
    },
    fileAbsolutePath: data.developers.fileAbsolutePath,
  }));

  const resourceItems = data.resources.frontmatter.items.map((item) => ({
    frontmatter: item,
    fields: {
      slug: "/resources/",
    },
    fileAbsolutePath: data.resources.fileAbsolutePath,
  }));

  return {
    specialLinks: data.site.frontmatter.navbar.specialLinks,
    navData: [
      ...data.allMarkdownRemark.nodes,
      ...developerItems,
      ...resourceItems,
    ]
      .sort((a, b) => a.frontmatter.order - b.frontmatter.order)
      .reduce(
        (
          previous,
          {
            fields: { slug },
            frontmatter: { category, title, icon, href, absoluteTo, ...rest },
          }
        ) => {
          const slugBase = slug.split("/")[1];

          return {
            ...previous,
            [slugBase]: [
              ...(previous[slugBase] || []),
              {
                ...rest,
                to: slug,
                href,
                absoluteTo,
                text: title,
                icon,
                category,
              },
            ],
          };
        },
        {}
      ),
  };
};
