import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import clsx from "clsx";

import EndIcon from "./end-icon";
import { colors } from "@/theme/colors";

const SecondaryButtonStyled = styled(Button)(() => ({
  color: colors.periwinkle,
  "& .MuiButton-endIcon": {
    transition: "transform 0.25s ease-in-out",
  },
  "&:hover": {
    opacity: 1,
    backgroundColor: "unset",
    "& .MuiButton-endIcon": {
      transform: "translateX(0.4em)",
    },
  },
}));

const SecondaryButton = ({ className, noArrows, ...props }) => {
  return (
    <SecondaryButtonStyled
      disableElevation
      variant="text"
      className={className}
      endIcon={!noArrows && <EndIcon />}
      {...props}
    />
  );
};

export default SecondaryButton;
