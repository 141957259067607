import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import { colors } from "@/theme/colors";

const TextButtonStyled = styled(Box)(() => ({
  color: colors.blueIris,
  "&:hover": {
    opacity: 0.6,
    cursor: "pointer",
  },
}));

const TextButton = ({ className, ...props }) => {
  return <TextButtonStyled component="span" className={className} {...props} />;
};

export default TextButton;
