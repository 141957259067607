import React from "react";
import { styled } from "@mui/material";

import { colors } from "@/theme/colors";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";

const ContactStyled = styled("div", {
  shouldForwardProp: notForwarded(["spacing", "hideLogo"]),
})<Pick<ContactProps, "spacing" | "hideLogo">>(
  ({ theme, hideLogo, spacing = "default" }) => ({
    backgroundColor: colors.white01,
    border: `1px solid ${colors.gray04}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.spacing(2),
    margin: theme.spacing(spacingMap[spacing], "auto"),
    padding: theme.spacing(3),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], "auto"),
    },
    ...(!hideLogo
      ? {
          paddingRight: 14,
          "::before": {
            backgroundColor: colors.deepPurple,
            backgroundImage: `url("/logo.png")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "40px auto",
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            content: '""',
            height: 65,
            position: "absolute",
            right: theme.spacing(3),
            top: -1,
            width: 65,
          },
        }
      : null),
    p: {
      display: "block",
      margin: 0,
      lineHeight: 1.4,
    },
    ".contact__name": {
      color: colors.prismaticBlack,
      fontSize: theme.spacing(2.5),
      fontWeight: 700,
      marginBottom: theme.spacing(0.5),
      textTransform: "uppercase",
    },
    ".contact__position": {
      color: colors.gray08,
    },
    ".contact__details": {
      marginTop: theme.spacing(3),
      color: colors.gray08,
      p: {
        marginTop: theme.spacing(0.5),
        ":first-of-type": {
          marginTop: 0,
        },
      },
    },
  })
);

interface ContactProps {
  email?: string;
  hideLogo?: boolean;
  name?: string;
  phone?: string;
  position?: string;
  spacing?: Spacing;
}

export const Contact = ({
  email,
  hideLogo,
  name,
  phone,
  position,
  spacing,
}: ContactProps) => {
  return (
    <ContactStyled spacing={spacing} hideLogo={hideLogo}>
      {name ? <p className="contact__name">{name}</p> : null}
      {position ? <p className="contact__position">{position}</p> : null}
      {phone || email ? (
        <div className="contact__details">
          {phone ? (
            <p className="contact__phone">
              <strong>Phone:</strong> <a href={`tel:${phone}`}>{phone}</a>
            </p>
          ) : null}
          {email ? (
            <p className="contact__email">
              <strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a>
            </p>
          ) : null}
        </div>
      ) : null}
    </ContactStyled>
  );
};
