import React, { useMemo } from "react";
import { styled } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";

import { CharlieCard, CharlieCardProps } from "@/components/cards/CharlieCard";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";
import { getTags } from "@/utils/getTags";

const ResourceStyled = styled("div")<Pick<ResourceProps, "spacing">>(
  ({ theme, spacing = "default" }) => ({
    margin: theme.spacing(spacingMap[spacing], 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], 0),
    },
  })
);

interface ResourceProps extends CharlieCardProps {
  spacing?: Spacing;
}

export const Resource = ({
  backgroundImage,
  spacing,
  tags: tagsBase,
  ...props
}: ResourceProps) => {
  const data = useStaticQuery(graphql`
    query getCards {
      cardImages: allFile(filter: { relativePath: { glob: "card-**.png" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 85
                width: 1000
              )
            }
          }
        }
      }
      tags: markdownRemark(fileAbsolutePath: { glob: "**/content/tags.md" }) {
        frontmatter {
          landingTags {
            name
            color
            slug
          }
          customerTags {
            name
            color
            slug
          }
          blogTags {
            name
            color
            slug
          }
          eventTags {
            name
            color
            slug
          }
          resourceTags {
            name
            color
            slug
          }
        }
      }
    }
  `);

  const siteTags = useMemo(
    () => [
      ...data.tags.frontmatter.blogTags,
      ...data.tags.frontmatter.customerTags,
      ...data.tags.frontmatter.eventTags,
      ...data.tags.frontmatter.landingTags,
      ...data.tags.frontmatter.resourceTags,
    ],
    [
      data.tags.frontmatter.blogTags,
      data.tags.frontmatter.customerTags,
      data.tags.frontmatter.eventTags,
      data.tags.frontmatter.landingTags,
      data.tags.frontmatter.resourceTags,
    ]
  );

  const tags = useMemo(
    () =>
      getTags({ postTags: tagsBase as unknown as string[], siteTags })?.map(
        (tag) => ({
          ...tag,
          color: "white01",
        })
      ),
    [tagsBase, siteTags]
  );

  const cardImage = useMemo(
    () =>
      data.cardImages.edges.find(({ node }) => node.name === backgroundImage)
        ?.node ?? backgroundImage,
    [backgroundImage, data.cardImages.edges]
  );

  return (
    <ResourceStyled spacing={spacing}>
      <CharlieCard
        backgroundImage={cardImage}
        color="white01"
        tags={tags}
        {...props}
      />
    </ResourceStyled>
  );
};
