import React from "react";
import { styled } from "@mui/material/styles";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";
import clsx from "clsx";

import Layout from "@/layout";
import { Hero } from "@/components/Hero";
import { colors } from "@/theme/colors";

const isCurrentLink = (location, post) => {
  const basePage = location.pathname === "/legal/";

  if (basePage) {
    return post.fields.slug === "/legal/terms/";
  }

  return location.pathname === post.fields.slug;
};

const PREFIX = "LegalContainer";

const classes = {
  hero: `${PREFIX}-hero`,
  main: `${PREFIX}-main`,
  articlesTitle: `${PREFIX}-articlesTitle`,
  articles: `${PREFIX}-articles`,
  content: `${PREFIX}-content`,
  article: `${PREFIX}-article`,
  link: `${PREFIX}-link`,
  currentLink: `${PREFIX}-currentLink`,
  toc: `${PREFIX}-toc`,
  tocTitle: `${PREFIX}-tocTitle`,
  tocArticle: `${PREFIX}-tocArticle`,
};

const LegalContainerStyled = styled("div")(({ theme }) => ({
  [`& .${classes.hero}`]: {
    marginBottom: "2em",
    marginTop: 60,
  },

  [`& .${classes.main}`]: {
    maxWidth: 1400,
    width: "94%",
    margin: "0 auto 2em",
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.articlesTitle}`]: {
    padding: "1em 5px",
    color: colors.gray08,
    fontWeight: 600,
    textTransform: "uppercase",
  },

  [`& .${classes.articles}`]: {
    padding: "1em 5em",
    minWidth: 250,
  },

  [`& .${classes.content}`]: {
    maxWidth: "100%",
    padding: "1em 5em",
    flex: 2,
    width: "100%",
    [theme.breakpoints.up(500)]: {
      minWidth: 500,
    },
  },

  [`& .${classes.article}`]: {
    margin: "5px 0",
    padding: "5px",
  },

  [`& .${classes.link}`]: {
    color: colors.gray08,
    "&:hover": {
      opacity: 0.8,
    },
  },

  [`& .${classes.currentLink}`]: {
    color: colors.gray11,
    fontWeight: 500,
  },

  [`& .${classes.toc}`]: {
    width: 280,
    padding: "1em 0 0 1em",
    [theme.breakpoints.down(1179)]: {
      display: "none",
    },
  },

  [`& .${classes.tocTitle}`]: {
    padding: "1em 0 1em 1em",
    color: colors.gray08,
    fontWeight: 600,
    textTransform: "uppercase",
  },

  [`& .${classes.tocArticle}`]: {
    margin: "5px 0",
    paddingBottom: ".5em",
  },
}));

const LegalContainer = ({ location, children }) => {
  const { legal, legalBase } = useStaticQuery(graphql`
    query getLegalPosts {
      legalBase: markdownRemark(
        fileAbsolutePath: { glob: "**/pages/legal.md" }
      ) {
        frontmatter {
          ...SeoFields
          icon
        }
      }
      legal: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/legal/**" }
          frontmatter: { showInTOC: { eq: true } }
        }
        sort: { frontmatter: { order: ASC } }
      ) {
        nodes {
          id
          frontmatter {
            title
            description
          }
          headings {
            id
            depth
            value
          }
          html
          fields {
            slug
          }
        }
      }
    }
  `);

  const { title, description } = legalBase.frontmatter;

  return (
    <Layout>
      <LegalContainerStyled>
        <Hero
          background="deepPurple"
          backgroundImage="secondary"
          className={classes.hero}
          color="white01"
          headline={title}
          text={description}
        />
        <main className={classes.main}>
          <div className={classes.articles}>
            <div className={classes.articlesTitle}>Pages</div>
            {legal.nodes.map((post) => (
              <article key={post.id} className={classes.article}>
                <Link
                  to={post.fields.slug}
                  className={clsx(
                    classes.link,
                    isCurrentLink(location, post) && classes.currentLink
                  )}
                >
                  {post.frontmatter.title}
                </Link>
              </article>
            ))}
          </div>
          <div className={classes.content}>{children}</div>
          <div className={classes.toc}>
            <div className={classes.tocTitle}>Table of contents</div>
            {legal.nodes
              .filter((post) => isCurrentLink(location, post))
              .map((post) => {
                return post.headings
                  .filter((heading) => heading.depth !== 1)
                  .map((heading) => (
                    <article
                      key={heading.id}
                      className={classes.tocArticle}
                      style={{ marginLeft: `${heading.depth - 1}em` }}
                    >
                      <Link to={`#${heading.id}`} className={classes.link}>
                        {heading.value}
                      </Link>
                    </article>
                  ));
              })}
          </div>
        </main>
      </LegalContainerStyled>
    </Layout>
  );
};

export default LegalContainer;
