import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Toolbar, AppBar } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";
import clsx from "clsx";

import Logo from "@/components/logo";
import GlobalSearch from "./GlobalSearch";
import { MobileMenu } from "./MobileMenu";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { NavigationItem } from "./NavigationItem";
import { PrimaryButton, SecondaryButton } from "@/components/buttons";
import { SubNavigation } from "./SubNavigation";
import { colors } from "@/theme/colors";
import { useHasScroll, useNavigationState } from "./navigationHooks";
import {
  baseStyles,
  hasMessageStyles,
  useBannerContext,
} from "@/components/banner";

const PREFIX = "Header";

const classes = {
  appBar: `${PREFIX}-appBar`,
  hasMessage: `${PREFIX}-hasMessage`,
  translucent: `${PREFIX}-translucent`,
  hasScroll: `${PREFIX}-hasScroll`,
  noStick: `${PREFIX}-noStick`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  logoLink: `${PREFIX}-logoLink`,
  logo: `${PREFIX}-logo`,
  centerNav: `${PREFIX}-centerNav`,
  rightNav: `${PREFIX}-rightNav`,
  rightNavButton: `${PREFIX}-rightNavButton`,
  login: `${PREFIX}-login`,
  getStarted: `${PREFIX}-getStarted`,
  navTopBar: `${PREFIX}-navTopBar`,
};

const HeaderStyled = styled("div")(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    display: "flex",
    alignContent: "center",
    boxShadow: "none",
    backdropFilter: "blur(0)",
    backgroundColor: colors.white01,
    opacity: 1,
    color: colors.deepPurple,
    ...baseStyles,
  },
  [`& .${classes.hasMessage}`]: hasMessageStyles,
  [`& .${classes.translucent}`]: {
    "@supports (backdrop-filter: blur(20px))": {
      backdropFilter: "blur(20px)",
    },
    color: colors.deepPurple,
  },
  [`& .${classes.hasScroll}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.98)",
    "@supports (backdrop-filter: blur(20px))": {
      backgroundColor: "rgba(255, 255, 255, 0.85)",
    },
    boxShadow:
      "0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), 0 -18px 60px -10px rgba(0, 0, 0, 0.17)",
  },
  [`& .${classes.noStick}`]: {
    position: "absolute",
    backdropFilter: "none",
  },
  [`& .${classes.toolbar}`]: {
    minHeight: 60,
  },
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${classes.logoLink}`]: {
    display: "block",
    color: colors.gray10,
    flexGrow: 1,
    overflow: "hidden",
    [theme.breakpoints.down(250)]: {
      width: 30,
      flexGrow: "unset",
    },
  },
  [`& .${classes.logo}`]: {
    width: theme.spacing(18),
  },
  [`& .${classes.centerNav}`]: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1210)]: {
      display: "none",
    },
  },
  [`& .${classes.rightNav}`]: {
    display: "flex",
    marginLeft: "auto",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: 1,
  },
  [`& .${classes.rightNavButton}`]: {
    marginLeft: theme.spacing(2),
    whiteSpace: "nowrap",
    maxHeight: 38,
  },
  [`& .${classes.login}`]: {
    border: `1px solid ${colors.periwinkle}`,
    padding: theme.spacing(0.625, 2),
    [theme.breakpoints.down(1210)]: {
      display: "none",
    },
  },
  [`& .${classes.getStarted}`]: {
    [theme.breakpoints.down(473)]: {
      display: "none",
    },
  },
}));

interface HeaderProps {
  lightBackground?: boolean;
  noStick?: boolean;
  useScroll?: boolean;
  minimalNavigation?: boolean;
}

export const Header = ({
  lightBackground,
  noStick,
  useScroll,
  minimalNavigation,
}: HeaderProps) => {
  const hasScroll = useHasScroll({ noStick });
  const displayHasScroll = useScroll ? hasScroll : true;
  const { bindNavigationItem, bindSubNavigation, subNavName } =
    useNavigationState();
  const { hasMessage } = useBannerContext();

  const { site } = useStaticQuery(graphql`
    query getSiteDataForNav {
      site: markdownRemark(
        fileAbsolutePath: { glob: "**/content/site-meta.md" }
      ) {
        frontmatter {
          navbar {
            columns {
              nav
              tags
              hideTitles
            }
          }
          support {
            text
            href
            hide
          }
          loginCta {
            text
            link
          }
          secondaryCta {
            text
            link
          }
          primaryCta {
            text
            link
          }
        }
      }
    }
  `);

  const columns = site.frontmatter.navbar.columns?.find(
    ({ nav }) => nav === subNavName
  );

  const { secondaryCta, primaryCta, loginCta } = site.frontmatter;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const appUrl = process.env.GATSBY_URLS_APP || "https://app.prismatic.io";
  const loginUrl = `${appUrl}${loginCta.link}`;

  return (
    <HeaderStyled>
      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          (displayHasScroll || lightBackground) && classes.translucent,
          displayHasScroll && classes.hasScroll,
          noStick && classes.noStick,
          hasMessage && classes.hasMessage
        )}
      >
        <Toolbar className={classes.toolbar}>
          <Link
            to="/"
            title="Prismatic.io"
            segmentType="navbar"
            className={classes.logoLink}
          >
            <div className={classes.logo}>
              <Logo />
            </div>
          </Link>
          {!minimalNavigation && (
            <>
              <div className={classes.centerNav}>
                <NavigationItem {...bindNavigationItem("platform")}>
                  Platform
                </NavigationItem>
                <NavigationItem to="/components/">Connectors</NavigationItem>
                <NavigationItem {...bindNavigationItem("solutions")}>
                  Solutions
                </NavigationItem>
                <NavigationItem {...bindNavigationItem("developers")}>
                  Developers
                </NavigationItem>
                <NavigationItem {...bindNavigationItem("resources")}>
                  Resources
                </NavigationItem>
                <NavigationItem to="/pricing/">Pricing</NavigationItem>
                <NavigationItem href={loginUrl}>{loginCta.text}</NavigationItem>
              </div>
              <div className={classes.rightNav}>
                <ErrorBoundary>
                  <GlobalSearch />
                </ErrorBoundary>
                <SecondaryButton
                  noArrows
                  className={clsx(classes.rightNavButton, classes.login)}
                  component={Link}
                  segmentType="navbar"
                  to={secondaryCta.link}
                >
                  {secondaryCta.text}
                </SecondaryButton>
                <PrimaryButton
                  className={clsx(classes.rightNavButton, classes.getStarted)}
                  segmentType="navbar"
                  component={Link}
                  to={primaryCta.link}
                >
                  {primaryCta.text}
                </PrimaryButton>
                <MobileMenu
                  primaryCta={{ ...secondaryCta }}
                  loginCta={{ ...loginCta, href: loginUrl }}
                />
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      <SubNavigation columns={columns} {...bindSubNavigation()} />
    </HeaderStyled>
  );
};
