import { getBaseUrl } from "@/utils/api-config";

const getUserId = async ({ email }) => {
  if (!email) {
    return undefined;
  }

  const response = await fetch(`${getBaseUrl()}/cdp/pre-user-event`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  });

  const result = await response.json();

  return result?.userId;
};

export default getUserId;
