import React from "react";
import { Grid, GridProps, styled } from "@mui/material";

interface ColumnProps extends GridProps {
  xsOrder: number;
  smOrder: number;
  mdOrder: number;
  lgOrder: number;
  xlOrder: number;
}

export const Column = styled(
  ({ xsOrder, smOrder, mdOrder, lgOrder, xlOrder, ...props }: ColumnProps) => (
    <Grid
      item
      order={{
        xs: xsOrder,
        sm: smOrder,
        md: mdOrder,
        lg: lgOrder,
        xl: xlOrder,
      }}
      {...props}
    />
  )
)(({ theme }) => ({
  "& > :first-child": {
    marginTop: 0,
  },
  "& > :last-child": {
    marginBottom: 0,
  },
}));
