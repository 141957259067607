import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

interface GetListItemProps {
  href?: string;
  absoluteTo?: string;
  to?: string;
  segmentType?: string;
}

export const getListItemProps = ({
  href,
  absoluteTo,
  to,
  segmentType,
}: GetListItemProps) =>
  href
    ? { href, component: "a" }
    : { to: absoluteTo || to, component: Link, segmentType };
