import React, { useEffect } from "react";
import { styled } from "@mui/material";

import { colors } from "@/theme/colors";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";

const OutgrowStyled = styled("div", {
  shouldForwardProp: notForwarded(["spacing", "maxWidth", "showBorder"]),
})<Pick<OutgrowProps, "spacing" | "maxWidth" | "showBorder">>(
  ({ theme, showBorder, spacing = "default", maxWidth }) => ({
    ...(showBorder
      ? {
          border: `1px solid ${colors.gray04}`,
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(3),
        }
      : {}),
    margin: theme.spacing(spacingMap[spacing], "auto"),
    maxWidth: maxWidth || "auto",
    minHeight: 574,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], "auto"),
    },
  })
);

interface OutgrowProps {
  copy?: string;
  id: string;
  maxWidth?: number;
  showBorder?: boolean;
  spacing?: Spacing;
  title?: string;
}

export const Outgrow = ({
  id,
  maxWidth,
  showBorder,
  spacing,
  title,
}: OutgrowProps) => {
  useEffect(() => {
    if (!id) {
      return;
    }

    const script = document.createElement("script");

    // script.src = "//dyv6f9ner1ir9.cloudfront.net/assets/js/nloader.js";
    script.src = "/outgrow.min.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initIframe(id);
    };

    document.querySelector("head")?.appendChild(script);

    return () => {
      document.querySelector("head")?.removeChild(script);
    };
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <OutgrowStyled
      showBorder={showBorder}
      maxWidth={maxWidth}
      spacing={spacing}
    >
      <div
        className="op-interactive"
        data-title={title || ""}
        data-url={`https://prismatic.outgrow.us/${id}?vHeight=1`}
        data-width="100%"
        id={id}
      ></div>
    </OutgrowStyled>
  );
};
