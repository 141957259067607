import React from "react";
import { styled } from "@mui/material";

import { Color, colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";
import { transformColor } from "@/utils/transformColor";
import { Container } from "../Container";

const backgroundImagesMap = {
  dark: "/images/form-dark.png",
  light: "/images/form-light.png",
};

const BackgroundStyled = styled("div", {
  shouldForwardProp: notForwarded([
    "color",
    "background",
    "backgroundImage",
    "fullWidth",
    "spacing",
  ]),
})<BackgroundProps>(
  ({
    background = "deepPurple",
    backgroundImage,
    color = "white01",
    fullWidth,
    spacing = "default",
    theme,
  }) => ({
    backgroundColor: transformColor(background) || colors.deepPurple,
    backgroundImage: `url(${
      (backgroundImage && backgroundImagesMap?.[backgroundImage]) || "none"
    })`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: theme.shape.borderRadius,
    color: transformColor(color) || colors.white01,
    margin: theme.spacing(spacingMap[spacing], 0),
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], 0),
    },
    ...(fullWidth
      ? {
          borderRadius: 0,
          left: "50%",
          marginLeft: "-50vw !important",
          marginRight: "-50vw !important",
          position: "relative",
          right: "50%",
          width: "100vw",
        }
      : {}),
    "& > :first-child": {
      marginTop: 0,
    },
    "& > :last-child": {
      marginBottom: 0,
    },
    "h1, h2, h3, h4, h5, h6": {
      color: "currentColor",
    },
  })
);

interface BackgroundProps {
  background?: Color | string;
  backgroundImage?: keyof typeof backgroundImagesMap;
  children?: React.ReactNode;
  color?: Color | string;
  fullWidth?: boolean;
  maxWidth?: number | string;
  spacing?: Spacing;
}

export const Background = ({
  children,
  fullWidth,
  maxWidth,
  ...props
}: BackgroundProps) => {
  return (
    <BackgroundStyled fullWidth={fullWidth} {...props}>
      {fullWidth ? (
        <Container maxWidth={maxWidth}>{children}</Container>
      ) : (
        children
      )}
    </BackgroundStyled>
  );
};
