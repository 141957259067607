import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { navigate } from "gatsby";
import queryString from "query-string";

import CheckboxField from "@/components/form/CheckboxField";
import FormConfirmation from "./FormConfirmation";
import TextField from "@/components/form/TextField";
import getUserId from "@/utils/getUserId";
import logError from "@/utils/logError";
import { colors } from "@/theme/colors";
import { siteKey } from "@/utils/api-config";
import { Markdown } from "@/components/markdown";
import { getConsentTrackingPreferences } from "@/utils/getConsentTrackingPreferences";

const PREFIX = "FormContainer";

const classes = {
  title: `${PREFIX}-title`,
  fields: `${PREFIX}-fields`,
  actions: `${PREFIX}-actions`,
  submitAreaMarkdown: `${PREFIX}-submitAreaMarkdown`,
  signUpButton: `${PREFIX}-signUpButton`,
  alert: `${PREFIX}-alert`,
};

const FormContainerStyled = styled("div")(() => ({
  color: colors.gray12,
  "& .MuiFormControl-root": {
    margin: "1em 0",
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  [`& .${classes.title}`]: {
    paddingBottom: 0,
  },
  [`& .${classes.fields}`]: {
    padding: 0,
  },
  [`& .${classes.actions}`]: {
    display: "flex",
    marginTop: "2em",
    alignItems: "center",
  },
  [`& .${classes.submitAreaMarkdown}`]: {
    marginTop: "1.5em",
    textAlign: "center",
    "& p": {
      margin: ".75em 0 0",
      fontSize: "0.8em",
      lineHeight: 1.5,
    },
  },
  [`& .${classes.signUpButton}`]: {
    minWidth: 250,
    margin: "0 auto",
  },
  [`& .${classes.alert}`]: {
    display: "flex",
    alignItems: "center",
    opacity: 1,
    fontSize: 14,
    borderRadius: 4,
    borderLeft: "4px solid",
    borderColor: colors.red,
    backgroundColor: colors.lightRed,
    padding: "1em",
  },
}));

export const FormContainer = ({
  className,
  confirmation,
  errorMessage,
  fields,
  name: formName,
  postData: postDataConfig,
  submit,
  submitAreaMarkdown,
  title = "",
}) => {
  const recaptchaRef = useRef();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showError, setShowError] = useState(false);

  return (
    <FormContainerStyled className={className}>
      {!confirmation?.redirect && (
        <FormConfirmation
          open={showConfirmation}
          setOpen={setShowConfirmation}
          confirmation={confirmation}
        />
      )}
      {title ? (
        <Typography fontWeight={400} variant="h3">
          {title}
        </Typography>
      ) : null}
      <Formik
        validationSchema={getValidation(fields)}
        initialValues={{
          subscribe: true,
          ...Object.fromEntries(
            fields.map(({ name, defaultValue }) => [name, defaultValue ?? ""])
          ),
        }}
        onSubmit={async (newValues, { resetForm }) => {
          const identityFields = fields.reduce(
            (previous, current) =>
              current.identifier
                ? { ...previous, [current.name]: newValues[current.name] }
                : previous,
            {}
          );

          const userId = await getUserId({ email: identityFields.email });

          window.analytics.identify(userId, identityFields);

          /**
           * This is where most form data is sent into our system.
           * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
           */
          const analyticsOpts = {
            integrations: { Salesforce: true },
          };

          const utm_data = localStorage.getItem("utm_data");

          if (utm_data) {
            try {
              Object.assign(analyticsOpts, {
                campaign: JSON.parse(utm_data),
              });
            } catch (error) {
              console.error("Error parsing utm_data", error);
            }
          }

          window.analytics.track(
            `Submitted Form - ${formName}`,
            fields.reduce(
              (previous, current) =>
                current.hideFromSegment
                  ? previous
                  : { ...previous, [current.name]: newValues[current.name] },
              {}
            ),
            analyticsOpts
          );

          /**
           * Post data only happens for forms that have `postData` enabled or tracking
           * disabled. This is to prevent double tracking of form submissions.
           */
          const trackingPreferences = getConsentTrackingPreferences();

          if (
            postDataConfig.enable === "always" ||
            (postDataConfig.enable === "when-tracking-disabled" &&
              typeof trackingPreferences?.custom?.marketingAndAnalytics !==
                "undefined" &&
              (trackingPreferences.custom.marketingAndAnalytics === false ||
                trackingPreferences.destinations.Webhooks === false))
          ) {
            const [error] = await postData({
              subdomain: postDataConfig.subdomain,
              route: postDataConfig.route,
              payload: { formName, ...newValues },
              recaptchaRef,
              defaultParams: postDataConfig.defaultParams,
            });

            setShowError(Boolean(error));

            if (error) {
              setErrorResponse(error);
              return;
            }
          }

          if (confirmation?.redirect) {
            const queryParamFields = fields.reduce(
              (previous, current) =>
                current?.queryParameter
                  ? { ...previous, [current.name]: newValues[current.name] }
                  : previous,
              {}
            );
            performRedirect(confirmation.redirect, queryParamFields);
          } else {
            setShowConfirmation(true);
            resetForm();
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form} noValidate>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
            <div className={classes.fields}>
              {showError && (
                <div className={classes.alert}>
                  <b style={{ marginRight: "5px" }}>{errorMessage}.</b>
                  <span>{errorResponse}</span>
                </div>
              )}
              {fields.map(
                ({
                  defaultValue,
                  name,
                  label: labelBase,
                  type,
                  autoComplete,
                  validation,
                }) => {
                  const label = validation?.includes("required")
                    ? `${labelBase}*`
                    : labelBase;

                  if (type === "hidden") {
                    return (
                      <input
                        key={name}
                        name={name}
                        type="hidden"
                        value={defaultValue}
                      />
                    );
                  }

                  if (type === "checkbox") {
                    return (
                      <CheckboxField key={name} name={name} label={label} />
                    );
                  }

                  return (
                    <TextField
                      key={name}
                      name={name}
                      label={label}
                      type={type}
                      autoComplete={type === "password" ? "off" : autoComplete}
                      fullWidth
                      multiline={type === "textarea"}
                      rows={type === "textarea" ? "4" : undefined}
                    />
                  );
                }
              )}
            </div>
            <div className={classes.actions}>
              <Button
                className={classes.signUpButton}
                color="primary"
                variant="contained"
                type="submit"
                size="large"
                disabled={isSubmitting}
              >
                {isSubmitting ? submit.loadingText : submit.text}
              </Button>
            </div>
            {submitAreaMarkdown ? (
              <Markdown
                html={submitAreaMarkdown}
                className={classes.submitAreaMarkdown}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </FormContainerStyled>
  );
};

function performRedirect(redirect, queryParamFields) {
  if (!redirect.route) {
    return;
  }

  const redirectUrl = queryString.stringifyUrl({
    url: redirect.route,
    query: queryParamFields,
  });

  if (redirect.type === "internal") {
    navigate(redirectUrl);
  } else if (redirect.type === "app") {
    const appUrl = process.env.GATSBY_URLS_APP || "https://app.prismatic.io";
    window.location.href = `${appUrl}${redirectUrl}`;
  } else if (redirect.type === "external") {
    window.location.href = redirectUrl;
  }
}

async function postData({
  subdomain,
  route,
  payload,
  recaptchaRef,
  defaultParams,
}) {
  if (!subdomain && !route) {
    return [];
  }

  const token = await getToken(recaptchaRef);
  const response = await fetch(getUrl({ subdomain, route, defaultParams }), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token, ...payload }),
  });

  if (response.status === 200) {
    return [];
  }

  if (response.status === 400) {
    return [await response.text()];
  }

  return [
    "There was a problem completing the specified action. Please reload the page and try again.",
  ];
}

function getSearchParams({ search, defaultParams = [] }) {
  const searchParams = new URLSearchParams(search);

  defaultParams.forEach(({ key, value }) => {
    if (!searchParams.has(key)) {
      searchParams.append(key, value);
    }
  });

  return searchParams.toString();
}

function getUrl({ subdomain, route, defaultParams }) {
  const { hostname, port, search } = window.location;
  const baseDomain =
    hostname === "localhost"
      ? `${hostname}:${port}`
      : hostname.replace("www.", "");
  const base = [subdomain, baseDomain].join(".");
  const params = getSearchParams({ search, defaultParams });

  const url = `https://${[base, route].join("/")}`;
  return params ? `${url}?${params}` : url;
}

const validationMap = {
  required: (validator) => validator.required("is required"),
  email: (validator) => validator.email("is not a valid email address"),
  password: (validator) =>
    validator
      // eslint-disable-next-line no-template-curly-in-string
      .min(8, "must be at least ${min} characters")
      // eslint-disable-next-line no-template-curly-in-string
      .max(64, "must be at most ${max} characters")
      .matches(/[a-z]/, "must contain at least one lowercase character")
      .matches(/[A-Z]/, "must contain at least one uppercase character")
      .matches(/[0-9]/, "must contain at least one number"),
  match: (validator, matchKey) =>
    validator.oneOf([Yup.ref(matchKey)], "does not match"),
};

function getValidation(fields) {
  return Yup.object(
    Object.fromEntries(
      fields.map(({ name, validation, validationMatch }) => [
        name,
        (validation || []).reduce(
          (previous, current) =>
            current in validationMap
              ? validationMap[current](previous)
              : previous,
          validationMatch
            ? validationMap.match(Yup.string(), validationMatch)
            : Yup.string()
        ),
      ])
    )
  );
}

async function getToken(recaptchaRef) {
  try {
    const token = await recaptchaRef.current.executeAsync();

    return token;
  } catch (error) {
    logError("Could not get recaptcha token", error);

    return "";
  }
}
