import React from "react";
import { Button, Chip, MenuItem, styled, TextField } from "@mui/material";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  UilBracketsCurly,
  UilCodeBranch,
  UilPlusCircle,
} from "@iconscout/react-unicons";

import { Spacing, spacingMap, spacingSmallMap } from "@/utils/spacing";
import { colors } from "@/theme/colors";
import { notForwarded } from "@/utils/notForwarded";
import type { Connector } from "./useConnectors";

const ConnectorsStyled = styled("div", {
  shouldForwardProp: notForwarded(["spacing", "hideCategories"]),
})<Pick<ConnectorsProps, "spacing" | "hideCategories">>(
  ({ theme, hideCategories, spacing = "default" }) => ({
    margin: theme.spacing(spacingSmallMap[spacing], "auto"),
    ...(!hideCategories
      ? {
          display: "grid",
          gridGap: theme.spacing(3),
        }
      : {}),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(spacingMap[spacing], "auto"),
      gridTemplateColumns: "275px 1fr",
    },
  })
);

const CategoriesStyled = styled("nav")(({ theme }) => ({
  background: colors.gray03,
  border: `1px solid ${colors.gray04}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  h3: {
    fontSize: 18,
    fontWeight: 500,
    margin: theme.spacing(1.5),
    padding: theme.spacing(0, 0, 2),
    textTransform: "uppercase",
    borderBottom: `1px solid ${colors.gray04}`,
  },
  ul: {
    display: "none",
    listStyle: "none",
    margin: 0,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    li: {
      margin: theme.spacing(0.5, 0, 0),
      padding: 0,
      button: {
        background: "none",
        border: "none",
        borderRadius: theme.shape.borderRadius,
        color: colors.prismaticBlack,
        cursor: "pointer",
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        padding: theme.spacing(1.5),
        textAlign: "left",
        width: "100%",
        "&:disabled": {
          cursor: "default",
        },
        "&:hover, &.is-active": {
          color: colors.periwinkle,
          backgroundColor: colors.white01,
          boxShadow: "0 0 2px rgba(0, 0, 0, 0.1)",
          "&:disabled": {
            backgroundColor: "none",
          },
        },
      },
    },
  },
  ".MuiFormControl-root": {
    backgroundColor: colors.white01,
    margin: theme.spacing(1.5),
    width: `calc(100% - ${theme.spacing(3)})`,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const SearchAndResultsStyled = styled("div")(({ theme }) => ({}));

const SearchStyled = styled("div")(({ theme }) => ({
  display: "block",
  margin: theme.spacing(0, 0, 4),
}));

const ResultsStyled = styled("div")(({ theme }) => ({
  ul: {
    display: "grid",
    gridGap: theme.spacing(5),
    gridColumnGap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    lineHeight: 1.5,
    listStyle: "none",
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  li: {
    position: "relative",
  },
  a: {
    color: colors.gray08,
  },
  p: {
    margin: 0,
    fontSize: 16,
  },
  ".comingSoonChip": {
    backgroundColor: colors.mintGreen,
    color: colors.white01,
    fontSize: 11,
    fontWieght: 600,
    lineHeight: 1,
    position: "absolute",
    right: theme.spacing(-1.25),
    top: theme.spacing(-1.5),
  },
  ".gatsby-image-wrapper": {
    backgroundColor: "white",
    border: `1px solid ${colors.gray04}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    margin: theme.spacing(0, 0, 1.5),
    padding: theme.spacing(4),
    width: "100%",
    height: 124,
  },
  img: {
    border: 0,
    margin: "auto",
    maxWidth: "100%",
    width: `calc(100% - ${theme.spacing(8)})`,
    height: `calc(100% - ${theme.spacing(4)})`,
  },
  button: {
    display: "block",
    margin: theme.spacing(6, "auto", 0),
  },
}));

const NoResultsStyled = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  h3: {
    margin: theme.spacing(5, 0, 0.5),
  },
  h4: {
    marginBottom: theme.spacing(2),
  },
  p: {
    margin: 0,
  },
  ul: {
    listStyle: "none",
    margin: theme.spacing(3, 0, 0),
    padding: 0,
    display: "grid",
    gridGap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  },
  li: {
    backgroundColor: "white",
    border: `1px solid ${colors.gray04}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  a: {
    color: colors.gray08,
    lineHeight: 1.5,
    display: "block",
    padding: theme.spacing(3),
  },
  svg: {
    color: colors.bluePurple,
    display: "block",
    margin: theme.spacing(0, "auto", 1.5),
    width: 50,
  },
}));

export interface ConnectorsProps {
  categories: string[];
  category?: string;
  hideCategories?: boolean;
  hideSearch?: boolean;
  limit?: number | undefined;
  results: Connector[];
  search: string;
  setCategory: (category: string | undefined) => void;
  setLimit: (limit: number | undefined) => void;
  setSearch: (search: string) => void;
  spacing?: Spacing;
}

export const Connectors = ({
  categories,
  category,
  hideCategories,
  hideSearch,
  limit,
  results,
  search,
  setCategory,
  setLimit,
  setSearch,
  spacing,
}: ConnectorsProps) => {
  return (
    <ConnectorsStyled spacing={spacing} hideCategories={hideCategories}>
      {!hideCategories ? (
        <CategoriesStyled>
          <h3>Categories</h3>
          <ul>
            <li>
              <button
                onClick={() => setCategory(undefined)}
                className={
                  !category || !categories.includes(category)
                    ? "is-active"
                    : undefined
                }
              >
                All
              </button>
            </li>
            {categories.map((item) =>
              item ? (
                <li key={item}>
                  <button
                    onClick={() => setCategory(item)}
                    className={item === category ? "is-active" : undefined}
                  >
                    {item}
                  </button>
                </li>
              ) : null
            )}
          </ul>
          <TextField
            fullWidth
            id="category"
            label="Category"
            onChange={(ev) =>
              setCategory(
                ev.target.value === "all" ? undefined : ev.target.value
              )
            }
            select
            value={category || "all"}
          >
            <MenuItem value="all">All</MenuItem>
            {categories.map((item) =>
              item ? (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ) : null
            )}
          </TextField>
        </CategoriesStyled>
      ) : null}
      <SearchAndResultsStyled>
        {!hideSearch ? (
          <SearchStyled>
            <TextField
              focused
              fullWidth
              label="Search Connectors"
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="Search for a connector"
              value={search}
              variant="outlined"
            />
          </SearchStyled>
        ) : null}
        {results.length ? (
          <ResultsStyled>
            <ul>
              {results.slice(0, limit).map((item) => (
                <li key={item.id}>
                  {item?.pending ? (
                    <>
                      {item?.display?.iconPath?.childImageSharp
                        ?.gatsbyImageData ? (
                        <GatsbyImage
                          image={
                            item?.display?.iconPath?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={item?.display?.label ?? ""}
                          objectFit="contain"
                        />
                      ) : null}
                      <p>{item?.display?.label}</p>
                      <Chip
                        className="comingSoonChip"
                        label="Coming Soon"
                        size="small"
                        variant="filled"
                        color="info"
                      />
                    </>
                  ) : (
                    <Link to={!item?.pending ? `/components/${item.key}` : ""}>
                      {item?.display?.iconPath?.childImageSharp
                        ?.gatsbyImageData ? (
                        <GatsbyImage
                          image={
                            item?.display?.iconPath?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={item?.display?.label ?? ""}
                          objectFit="contain"
                        />
                      ) : null}
                      <p>{item?.display?.label}</p>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            {limit && results.length > limit ? (
              <Button variant="contained" onClick={() => setLimit(undefined)}>
                Load{" "}
                {category && categories.includes(category)
                  ? `All ${category}`
                  : "All Connectors"}
              </Button>
            ) : null}
          </ResultsStyled>
        ) : (
          <NoResultsStyled>
            <h3>We don't have that connector yet.</h3>
            <p>
              However, our connector library is easily extensible. Check out
              these options below.
            </p>
            <ul>
              <li>
                <Link to="#custom-connectors">
                  <UilCodeBranch size="50" />
                  <h4>Custom Connector</h4>
                  <p>
                    Use Prismatic's custom connector SDK to build a robust
                    connector to any app your customers use.
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/docs/components/http/">
                  <UilBracketsCurly size="50" />
                  <h4>HTTP</h4>
                  <p>
                    The HTTP connector provides universal connectivity to any
                    app with an HTTP-based API (most apps).
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/request-a-connector/">
                  <UilPlusCircle size="50" />
                  <h4>Request Connector</h4>
                  <p>Contact us to request the connector you're looking for.</p>
                </Link>
              </li>
            </ul>
          </NoResultsStyled>
        )}
      </SearchAndResultsStyled>
    </ConnectorsStyled>
  );
};
