import React from "react";
import { ConsentManager } from "@segment/consent-manager";
import { Button, Grid, styled } from "@mui/material";
import { colors } from "@/theme/colors";
import { graphql, useStaticQuery } from "gatsby";
import inEU from "@segment/in-eu";

import { Markdown } from "@/components/markdown";

const CookieConsentStyled = styled("div")(({ theme }) => ({
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  margin: `0 auto`,
  maxWidth: `calc(100% - ${theme.spacing(6)})`,
  position: "fixed",
  width: 420,
  zIndex: 2147483001, // needs to greater than intercom zIndex
  "> div": {
    background: colors.deepPurpleMuted,
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 4px 24px 4px rgba(0, 0, 0, 0.16)",
    color: colors.white01,
    display: "block",
    padding: theme.spacing(4.25, 3, 3),
    textAlign: "left",
    "a, button#subContentBtn": {
      color: colors.white01,
      "&:hover, &:focus, &:active": {
        color: colors.white01,
        opacity: 0.75,
      },
    },
  },
  'button[title="Close"]': {
    top: theme.spacing(2.5),
    right: theme.spacing(1),
  },
}));

const DenyButtonStyled = styled(Button)(({ theme }) => ({
  borderColor: colors.white01,
  color: colors.white01,
  "&:hover, &:focus, &:active": {
    borderColor: colors.white01,
  },
}));

const markdownStyles = {
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: "0.00938em",
  lineHeight: 1.6,
  "> p": {
    color: colors.white01,
    lineHeight: "inherit",
    margin: "0 0 12px",
  },
  "a, button#subContentBtn": {
    color: colors.blueIris,
  },
};

const bannerActionsBlock = ({ acceptAll, denyAll }) => (
  <Grid container columnSpacing={2} justifyContent="center" marginTop={3}>
    <Grid item xs={6}>
      <Button
        color="primary"
        disableElevation
        onClick={acceptAll}
        variant="contained"
        fullWidth
      >
        Allow all
      </Button>
    </Grid>
    <Grid item xs={6}>
      <DenyButtonStyled
        disableElevation
        onClick={denyAll}
        variant="outlined"
        fullWidth
      >
        Deny all
      </DenyButtonStyled>
    </Grid>
  </Grid>
);

export const CookieConsent = () => {
  const { site } = useStaticQuery(
    graphql`
      query getCookies {
        site: markdownRemark(
          fileAbsolutePath: { glob: "**/content/site-meta.md" }
        ) {
          frontmatter {
            cookiesBannerContentMarkdown
            cookiesBannerSubContent
            cookiesCancelDialogContentMarkdown
            cookiesCancelDialogTitle
            cookiesDialogContentMarkdown
            cookiesDialogTitle
          }
        }
      }
    `
  );

  if (!process.env.GATSBY_SEGMENT_WRITE_KEY) {
    return null;
  }

  return (
    <CookieConsentStyled>
      <ConsentManager
        bannerActionsBlock={bannerActionsBlock}
        bannerTextColor="#FFFFFF"
        bannerContent={
          <Markdown
            html={site.frontmatter.cookiesBannerContentMarkdown}
            sx={markdownStyles}
          />
        }
        bannerSubContent={site.frontmatter.cookiesBannerSubContent}
        cancelDialogContent={
          <Markdown
            html={site.frontmatter.cookiesCancelDialogContentMarkdown}
            sx={markdownStyles}
          />
        }
        cancelDialogTitle={site.frontmatter.cookiesCancelDialogTitle}
        closeBehavior={"accept"}
        preferencesDialogContent={
          <Markdown
            html={site.frontmatter.cookiesDialogContentMarkdown}
            sx={markdownStyles}
          />
        }
        preferencesDialogTitle={site.frontmatter.cookiesDialogTitle}
        shouldRequireConsent={inEU}
        writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY}
      />
    </CookieConsentStyled>
  );
};
