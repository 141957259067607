import React from "react";
import { styled } from "@mui/material/styles";
import { useWindowSize } from "react-use";
import clsx from "clsx";

const PREFIX = "SectionContent";

const classes = {
  leftAligned: `${PREFIX}-leftAligned`,
  spaced: `${PREFIX}-spaced`,
};

const SectionContentStyled = styled("div")(({ theme }) => ({
  maxWidth: "80%",
  flexWrap: "wrap",
  justifyContent: "center",
  margin: "0 auto",
  [theme.breakpoints.down(700)]: {
    maxWidth: "100%",
  },

  [`&.${classes.leftAligned}`]: {
    maxWidth: 1230,
    padding: 4,
  },

  [`&.${classes.spaced}`]: {
    "& > *": {
      padding: "0.7rem 0",
    },
  },
}));

export const SectionContent = ({
  flex,
  wide,
  className,
  style = {},
  spaced,
  leftAligned,
  ...props
}) => {
  const { width } = useWindowSize();

  const styles = { ...style };

  if (flex) {
    styles.display = "flex";
  }

  if (wide) {
    styles.maxWidth = width > 1200 ? "90%" : "100%";
  }

  return (
    <SectionContentStyled
      className={clsx(
        spaced && classes.spaced,
        leftAligned && classes.leftAligned,
        className
      )}
      style={styles}
      {...props}
    />
  );
};
