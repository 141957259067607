import { useReducer } from "react";
import { graphql, useStaticQuery } from "gatsby";

export type Connector =
  | Queries.UseConnectorsQuery["allPendingConnectorsJson"]["nodes"][0]
  | Queries.UseConnectorsQuery["allPublishedConnectorsJson"]["nodes"][0];

interface State {
  allConnectors: Connector[];
  categories: string[];
  category: string | undefined;
  categoryOrder: string | undefined;
  categoryConnectors: Connector[];
  limit: number | undefined;
  limitBase: number | undefined;
  results: Connector[];
  search: string;
}

enum ActionType {
  SET_FILTERS = "SET_FILTERS",
  SET_CATEGORY = "SET_CATEGORY",
  SET_SEARCH = "SET_SEARCH",
  SET_LIMIT = "SET_LIMIT",
}

type Action =
  | {
      type: ActionType.SET_CATEGORY;
      data: State["category"];
    }
  | {
      type: ActionType.SET_SEARCH;
      data: State["search"];
    }
  | {
      type: ActionType.SET_LIMIT;
      data: State["limit"];
    };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_CATEGORY:
      const categoryConnectors = state.allConnectors.filter((component) =>
        action.data ? component.display?.category?.includes(action.data) : true
      );

      return {
        ...state,
        category: action.data,
        categoryConnectors,
        limit: state.limitBase,
        results: categoryConnectors,
        search: "",
      };
    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.data,
        results: state.categoryConnectors.filter((component) =>
          component?.display?.label
            ?.toLowerCase()
            .includes(action.data.toLowerCase())
        ),
      };
    case ActionType.SET_LIMIT:
      return {
        ...state,
        limit: action.data,
      };
    default:
      return state;
  }
};

const initialState: State = {
  allConnectors: [],
  categories: [],
  category: undefined,
  categoryOrder: undefined,
  categoryConnectors: [],
  limit: undefined,
  limitBase: undefined,
  results: [],
  search: "",
};

const initializer =
  (data: Queries.UseConnectorsQuery) => (initialState: State) => {
    const allConnectors = [
      ...(data?.allPublishedConnectorsJson.nodes ?? []),
      ...(data?.allPendingConnectorsJson.nodes ?? []),
    ]
      .reduce<Connector[]>((acc, cur) => {
        const existing = acc.some((connector) => connector.key === cur.key);
        const category = data.connectorsMeta?.frontmatter?.categories?.find(
          (category) => category?.connectors?.includes(cur.key)
        )?.name;

        return [
          ...acc,
          ...(!existing && cur.public
            ? [
                {
                  ...cur,
                  display: {
                    ...cur.display,
                    category: category ? [category] : [],
                  },
                } as Connector,
              ]
            : []),
        ];
      }, [])
      .sort((a: any, b: any) =>
        a?.display.label
          .toLowerCase()
          .localeCompare(b?.display.label.toLowerCase())
      );

    const categories = initialState.categoryOrder
      ? initialState.categoryOrder.split(",").map((item) => item.trim())
      : ([
          ...new Set(
            allConnectors
              .map((component) => component?.display?.category)
              .flat()
              .filter(Boolean)
          ),
        ].sort() as string[]);

    const categoryConnectors = allConnectors.reduce<Connector[]>((acc, cur) => {
      const inCategory =
        initialState.category && categories.includes(initialState.category)
          ? cur?.display?.category?.includes(initialState.category)
          : true;

      const inSearch = initialState.search
        ? cur?.display?.label?.includes(initialState.search)
        : true;

      return [...acc, ...(inCategory && inSearch ? [cur] : [])];
    }, []);

    return {
      ...initialState,
      allConnectors,
      categories,
      categoryConnectors,
      limitBase: initialState.limit,
      results: categoryConnectors,
    };
  };

interface UseConnectorsProps {
  category?: string;
  categoryOrder?: string;
  limit?: number;
  search?: string;
}

export const useConnectors = ({
  category,
  categoryOrder,
  limit,
  search,
}: UseConnectorsProps) => {
  const data = useStaticQuery<Queries.UseConnectorsQuery>(graphql`
    query UseConnectors {
      allPublishedConnectorsJson(filter: { public: { eq: true } }) {
        nodes {
          id
          key
          pending
          public
          display {
            category
            description
            iconPath {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  height: 150
                  placeholder: BLURRED
                  width: 150
                )
              }
            }
            label
          }
        }
      }
      allPendingConnectorsJson(filter: { public: { eq: true } }) {
        nodes {
          key
          id
          pending
          public
          display {
            label
            category
            description
            iconPath {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  height: 150
                  placeholder: BLURRED
                  width: 150
                )
              }
            }
          }
        }
      }
      connectorsMeta: markdownRemark(
        fileAbsolutePath: { glob: "**/referenceData/connectors.md" }
      ) {
        frontmatter {
          categories {
            connectors
            name
          }
        }
      }
    }
  `);

  const [state, dispatch] = useReducer(
    reducer,
    { ...initialState, category, categoryOrder, limit, search: search ?? "" },
    initializer(data)
  );

  const setCategory = (category: State["category"]) =>
    dispatch({ type: ActionType.SET_CATEGORY, data: category });

  const setSearch = (search: State["search"]) =>
    dispatch({ type: ActionType.SET_SEARCH, data: search });

  const setLimit = (limit: State["limit"]) =>
    dispatch({ type: ActionType.SET_LIMIT, data: limit });

  return {
    ...state,
    setCategory,
    setLimit,
    setSearch,
  };
};
