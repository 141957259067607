import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { PlayCircleFilledRounded } from "@mui/icons-material";

import { Color, colors } from "@/theme/colors";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";

const PREFIX = "VideoPreview";

const classes = {
  previewIconContainer: `${PREFIX}-previewIconContainer`,
  previewIcon: `${PREFIX}-previewIcon`,
  image: `${PREFIX}-image`,
};

const VideoPreviewStyled = styled(Box, {
  shouldForwardProp: notForwarded(["buttonColor", "buttonOpacity"]),
})<Pick<VideoPreviewProps, "buttonColor" | "buttonOpacity">>(
  ({ buttonColor, buttonOpacity }) => ({
    position: "relative",
    "& $previewIconContainer": {
      transition: "opacity 0.3s",
    },
    "&:hover": {
      cursor: "pointer",
      "& $previewIconContainer": {
        opacity: 0.6,
      },
    },
    [`& .${classes.previewIconContainer}`]: {
      alignItems: "center",
      borderRadius: 4,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      left: 0,
      margin: "auto",
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 1,
    },
    [`& .${classes.previewIcon}`]: {
      color: transformColor(buttonColor) || colors.periwinkle,
      fontSize: "10em",
      opacity: buttonOpacity || 1,
    },
    [`& .${classes.image}`]: {
      borderRadius: 4,
    },
  })
);

export interface VideoPreviewProps extends React.HTMLProps<HTMLButtonElement> {
  alt?: string;
  buttonColor?: Color | string;
  buttonOpacity?: number;
  hideButton?: boolean;
  image: string | GraphQLQuery;
}

export const VideoPreview: React.FC<VideoPreviewProps> = ({
  image,
  alt,
  buttonColor = "periwinkle",
  buttonOpacity = 0.8,
  hideButton,
  ...props
}) => {
  if (!image) {
    return null;
  }

  return (
    <VideoPreviewStyled
      buttonColor={buttonColor}
      buttonOpacity={buttonOpacity}
      {...props}
    >
      {!hideButton ? (
        <div className={classes.previewIconContainer}>
          <PlayCircleFilledRounded className={classes.previewIcon} />
        </div>
      ) : null}
      {typeof image === "string" ? (
        <img
          src={image}
          alt={alt || ""}
          loading="lazy"
          className={classes.image}
        />
      ) : (
        <GatsbyImage
          loading="lazy"
          objectFit="scale-down"
          className={classes.image}
          image={image.childImageSharp.gatsbyImageData}
          alt={alt || ""}
        />
      )}
    </VideoPreviewStyled>
  );
};
