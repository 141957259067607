import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useStaticQuery, graphql } from "gatsby";
import { IconButton } from "@mui/material";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";
import clsx from "clsx";

import { BannerContext } from "@/components/banner/context";
import { Close, ArrowBackIos } from "@/icons";
import { colors } from "@/theme/colors";

export const height = 38;
export const baseStyles = {
  transform: "translateY(0)",
  transition: "all 0.33s",
};
export const hasMessageStyles = { transform: `translateY(${height}px)` };

const PREFIX = "Content";

const classes = {
  content: `${PREFIX}-content`,
  hasMessage: `${PREFIX}-hasMessage`,
  message: `${PREFIX}-message`,
  close: `${PREFIX}-close`,
  closeAriaLabel: `${PREFIX}-close-sr-text`,
  learnMoreLink: `${PREFIX}-learnMoreLink`,
  learnMoreArrows: `${PREFIX}-learnMoreArrows`,
  learnMoreArrowOne: `${PREFIX}-learnMoreArrowOne`,
  learnMoreArrowTwo: `${PREFIX}-learnMoreArrowTwo`,
};

const BannerContentStyled = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    ...baseStyles,
    alignItems: "center",
    background: `linear-gradient(90deg, ${colors.deepPurple}, ${colors.bluePurple})`,
    color: colors.white01,
    display: "flex",
    height: height,
    left: 0,
    position: "fixed",
    right: 0,
    textAlign: "center",
    top: -height,
    width: "100%",
    zIndex: 9999,
  },

  [`& .${classes.hasMessage}`]: hasMessageStyles,

  [`& .${classes.message}`]: {
    display: "flex",
    flexWrap: "wrap",
    fontSize: 15,
    fontWeight: 500,
    justifyContent: "center",
    lineHeight: 1.3,
    margin: "0 auto",
    maxWidth: 1100,
    padding: "0 10px",
    [theme.breakpoints.down(768)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
      paddingRight: "45px",
    },
    "& a": {
      color: "currentColor",
      display: "block",
    },
  },

  [`& .${classes.close}`]: {
    bottom: 0,
    color: colors.white01,
    padding: "0 0.3em",
    position: "absolute",
    right: "0.5em",
    top: 0,
  },

  [`& .${classes.learnMoreLink}`]: {
    paddingLeft: "0.5em",
    position: "relative",
    "&:hover $learnMoreArrows": {
      transform: "rotate(180deg) translateX(-0.2em)",
    },
  },

  [`& .${classes.learnMoreArrows}`]: {
    display: "inline-block",
    fontSize: "1em !important",
    left: -8,
    lineHeight: 1,
    position: "relative",
    transform: "rotate(180deg)",
    transition: "transform 0.25s ease-in-out",
  },

  [`& .${classes.learnMoreArrowOne}`]: {
    fontSize: "inherit",
  },

  [`& .${classes.learnMoreArrowTwo}`]: {
    left: -9,
    fontSize: "inherit",
    position: "inherit",
  },

  [`& .${classes.closeAriaLabel}`]: {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: 0,
  },
}));

export interface ContentProps {
  children: React.ReactNode;
}

const Content = ({ children }: ContentProps) => {
  const data = useStaticQuery(graphql`
    query getBannerInfo {
      site: markdownRemark(
        fileAbsolutePath: { glob: "**/content/site-meta.md" }
      ) {
        frontmatter {
          banner {
            linkText
            linkUrl
            message
            show
          }
        }
      }
    }
  `);
  const { linkText, linkUrl, message, show } = data.site.frontmatter.banner;
  const [hasMessage, setHasMessage] = useState(show);

  return (
    <BannerContext.Provider value={{ hasMessage, setHasMessage }}>
      <BannerContentStyled>
        {show && (
          <div
            className={clsx(classes.content, hasMessage && classes.hasMessage)}
          >
            <div className={classes.message}>
              {linkUrl && (
                <Link
                  className={classes.learnMoreLink}
                  onClick={() => setTimeout(() => setHasMessage(false), 200)}
                  to={linkUrl}
                >
                  {message} {linkText || "Learn more"}
                  <span className={classes.learnMoreArrows}>
                    <ArrowBackIos className={classes.learnMoreArrowOne} />
                    <ArrowBackIos className={classes.learnMoreArrowTwo} />
                  </span>
                </Link>
              )}
            </div>
            <IconButton
              size="small"
              className={classes.close}
              onClick={() => setHasMessage(false)}
            >
              <span className={classes.closeAriaLabel}>Close Banner</span>
              <Close />
            </IconButton>
          </div>
        )}
      </BannerContentStyled>
      {children}
    </BannerContext.Provider>
  );
};

export default Content;
