import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

import { Color, colors } from "@/theme/colors";
import { PrimaryButton, PrimaryButtonProps } from "@/components/buttons";
import { spacingMap, Spacing, spacingSmallMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";

const CtaStyled = styled("div", {
  shouldForwardProp: notForwarded(["spacing", "maxWidth"]),
})<Pick<CtaProps, "spacing" | "maxWidth">>(
  ({ theme, spacing = "default", maxWidth }) => ({
    backgroundColor: colors.white01,
    border: `1px solid ${colors.gray04}`,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(spacingMap[spacing], "auto"),
    maxWidth: maxWidth || "auto",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(spacingSmallMap[spacing], "auto"),
    },
    ".cta__title": {
      color: colors.prismaticBlack,
      fontSize: theme.spacing(3),
      fontWeight: 700,
      marginBottom: theme.spacing(0.75),
    },
    ".cta__copy": {
      color: colors.gray08,
      fontSize: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      ".cta__primary": {
        padding: theme.spacing(1.5, 3),
      },
    },
  })
);

interface CtaProps extends Pick<PrimaryButtonProps, "to" | "label"> {
  buttonBackground?: Color | string;
  buttonColor?: Color | string;
  copy?: string;
  maxWidth?: number;
  spacing?: Spacing;
  title?: string;
}

export const Cta = ({
  buttonBackground,
  buttonColor,
  copy,
  label,
  maxWidth,
  spacing,
  title,
  to,
}: CtaProps) => {
  return (
    <CtaStyled maxWidth={maxWidth} spacing={spacing}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={8}>
          <Typography className="cta__title">{title}</Typography>
          <Typography className="cta__copy">{copy}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: { sm: "right" },
          }}
        >
          <PrimaryButton
            component={Link}
            background={buttonBackground}
            color={buttonColor}
            to={to}
            className="cta__primary"
            size="large"
          >
            {label}
          </PrimaryButton>
        </Grid>
      </Grid>
    </CtaStyled>
  );
};
