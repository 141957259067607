import { TagProps } from "@/page-components/blog/Tag";

interface GetTagsProps {
  siteTags: TagProps[];
  postTags: string[];
}

export const getTags = ({ siteTags, postTags }: GetTagsProps): TagProps[] =>
  postTags?.map((tag) => {
    const existingTag = siteTags.find(({ name }) => name === tag);

    return existingTag
      ? { ...existingTag }
      : { name: tag, slug: undefined, color: undefined };
  });
