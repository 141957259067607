import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import clsx from "clsx";

const PREFIX = "SectionHeader";

const classes = {
  large: `${PREFIX}-large`,
  centered: `${PREFIX}-centered`,
  lightBackground: `${PREFIX}-lightBackground`,
};

const SectionHeaderStyled = styled(Typography)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
  fontWeight: 800,
  [theme.breakpoints.down(600)]: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  [`&.${classes.large}`]: {},

  [`&.${classes.centered}`]: {
    maxWidth: 1232,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },

  [`&.${classes.lightBackground}`]: {
    fontWeight: 500,
  },
}));

export const SectionHeader = ({
  direction,
  style = {},
  centered,
  className,
  lightBackground,
  ...props
}) => {
  const styles = { ...style };

  return (
    <SectionHeaderStyled
      variant="h3"
      style={styles}
      className={clsx(
        props.variant === "h1" && classes.large,
        centered && classes.centered,
        lightBackground && classes.lightBackground,
        className
      )}
      {...props}
    />
  );
};
