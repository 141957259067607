import React from "react";
import clsx from "clsx";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useStaticQuery, graphql } from "gatsby";

import CTA from "@/components/CTA";
import { HeroDownload } from "@/components/HeroDownload";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { Section } from "@/components/section";
import { alignment } from "@/theme/alignment";
import { Color, colors } from "@/theme/colors";
import { Spacing, spacingMap } from "@/utils/spacing";
import { notForwarded } from "@/utils/notForwarded";
import { transformColor } from "@/utils/transformColor";

const PREFIX = "Hero";

const classes = {
  section: `${PREFIX}-section`,
  container: `${PREFIX}-container`,
  headline: `${PREFIX}-headline`,
  text: `${PREFIX}-text`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  xlarge: `${PREFIX}-xlarge`,
  hasAnnouncement: `${PREFIX}-hasAnnouncement`,
};

const HeroStyled = styled("div", {
  shouldForwardProp: notForwarded(["spacing", "color"]),
})<Pick<SharedHeroProps, "color" | "spacing">>(
  ({ theme, spacing = "none", color }) => ({
    margin: theme.spacing(spacingMap[spacing], 0),
    color: transformColor(color) || colors.white01,
    ...(spacing === "none"
      ? { "&:first-child": { marginBottom: "0 !important" } }
      : {}),
    [`& .${classes.section}`]: {
      ...alignment,
      margin: "0 auto",
      paddingBottom: theme.spacing(9),
      paddingTop: theme.spacing(9),
      [`&.${classes.small}`]: {
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(6),
        "& $headline": {
          fontSize: 42,
          [theme.breakpoints.down(768)]: {
            fontSize: 28,
          },
        },
        "& $text": {
          fontSize: 22,
          [theme.breakpoints.down(768)]: {
            fontSize: 18,
          },
        },
      },
      [`&.${classes.medium}`]: {
        paddingBottom: theme.spacing(9),
        paddingTop: theme.spacing(9),
        [theme.breakpoints.down(1000)]: {
          paddingBottom: theme.spacing(6),
          paddingTop: theme.spacing(6),
        },
        [`& .${classes.headline}`]: {
          fontSize: 46,
          [theme.breakpoints.down(768)]: {
            fontSize: 28,
          },
        },
        [`& .${classes.text}`]: {
          fontSize: 24,
          [theme.breakpoints.down(768)]: {
            fontSize: 18,
          },
        },
      },
      [`&.${classes.large}`]: {
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(11),
      },
      [`&.${classes.xlarge}`]: {
        paddingBottom: theme.spacing(14),
        paddingTop: theme.spacing(13),
      },
      [`&.${classes.hasAnnouncement}`]: {
        paddingBottom: theme.spacing(18),
        paddingTop: theme.spacing(9),
      },
    },
    [`& .${classes.container}`]: {
      textAlign: "center",
      "& > *:last-child": {
        marginBottom: 0,
      },
    },
    [`& .${classes.headline}`]: {
      color: "currentColor",
      fontSize: 60,
      fontWeight: 600,
      margin: "0 auto .5em",
      [theme.breakpoints.down(1200)]: {
        fontSize: 42,
      },
      [theme.breakpoints.down(768)]: {
        fontSize: 28,
      },
    },
    [`& .${classes.text}`]: {
      fontSize: 25,
      margin: "0 auto 1.25em",
      maxWidth: 800,
      [theme.breakpoints.down(400)]: {
        fontSize: 18,
      },
    },
  })
);

interface SharedHeroProps {
  background?: Color | string;
  backgroundImage?: "primary" | "secondary";
  className?: string;
  color?: Color | string;
  size?: "small" | "medium" | "large" | "xlarge" | "hasAnnouncement";
  spacing?: Spacing;
}

interface HeroChildrenProps extends SharedHeroProps {
  children: React.ReactNode;
}

interface HeroJSXProps extends SharedHeroProps {
  callToAction?: {
    theme: Extract<keyof typeof colors, "white01" | "blueIris" | "deepPurple">;
    primary: {
      text: string;
      link: string;
    };
    secondary: {
      text: string;
      link: string;
    };
  };
  headline: string;
  text: string;
  download?: GraphQLQuery;
}

export type HeroProps = HeroChildrenProps | HeroJSXProps;

const isChildrenHero = (props: HeroProps): props is HeroChildrenProps =>
  "children" in props;

export const Hero = (props: HeroProps) => {
  const {
    background,
    backgroundImage: backgroundImageBase,
    className,
    color,
    size = "medium",
    spacing,
  } = props;
  const data = useStaticQuery(graphql`
    query getBackgroundHeroImages {
      allFile(filter: { relativePath: { glob: "hero-**.png" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 100
                width: 1800
              )
            }
          }
        }
      }
    }
  `);

  const getHeroImage = (imageName) =>
    data?.allFile?.edges.find(({ node }) => node.name === imageName)?.node
      ?.childImageSharp.gatsbyImageData.images.fallback.src;

  const backgroundImage = getHeroImage(`hero-${backgroundImageBase}`) || "";

  return (
    <HeroStyled
      className={clsx("hero", className)}
      spacing={spacing}
      color={color}
    >
      <Section
        color={color}
        background={background}
        backgroundImage={backgroundImage}
        className={clsx(classes.section, classes[size])}
      >
        {isChildrenHero(props) ? (
          props.children
        ) : (
          <div className={classes.container}>
            <Typography
              variant="h2"
              component="h1"
              className={classes.headline}
            >
              {props.headline}
            </Typography>
            <Typography variant="h6" component="p" className={classes.text}>
              {props.text}
            </Typography>
            {props.callToAction?.primary && (
              <CTA
                primary={props.callToAction.primary}
                secondary={props.callToAction.secondary}
                theme={
                  props.callToAction.theme ||
                  (!backgroundImage && !background && colors.blueIris)
                }
              />
            )}
            {props.download ? (
              <HeroDownload
                callToAction={props.download.callToAction}
                headline={props.download.headline}
                image={props.download.image}
                text={props.download.text}
              />
            ) : null}
          </div>
        )}
      </Section>
    </HeroStyled>
  );
};
