import React from "react";
import type { GatsbyBrowser } from "gatsby";
import { datadogLogs, StatusType } from "@datadog/browser-logs";

import PageWrapper from "./src/layout/PageWrapper";
import "./src/css/prism-theme.css";
import "./src/css/roboto.min.css";
import "./src/css/material-icons.min.css";

import { getConsentTrackingPreferences } from "@/utils/getConsentTrackingPreferences";

const version = process.env.GATSBY_BUILD_HASH || undefined;
const clientToken = process.env.GATSBY_DATADOG_CLIENT_TOKEN || "";
const stackId = process.env.GATSBY_STACK_ID || undefined;
const service = "website";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return <PageWrapper {...props}>{element}</PageWrapper>;
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
}) => !location.pathname.startsWith("/legal");

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = (
  { location },
  { eventName }
) => {
  const trackingPreferences = getConsentTrackingPreferences();

  if (trackingPreferences?.custom?.marketingAndAnalytics === false) {
    return;
  }

  if (process.env.NODE_ENV === "production") {
    window.analytics && window.analytics.page(eventName);
  } else {
    if (!window.analytics || typeof window.analytics.page !== "function") {
      console.warn("Unable to locate analytics.js");
      return;
    }

    const args = [{ path: location.pathname }];

    if (eventName) {
      args.unshift(eventName);
    }

    window.analytics.page(...args);
  }
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const utm_medium = searchParams.get("utm_medium");
  const utm_source = searchParams.get("utm_source");
  const utm_campaign = searchParams.get("utm_campaign");
  const utm_campaignname = searchParams.get("utm_campaignname");
  const utm_term = searchParams.get("utm_term");
  const utm_matchtype = searchParams.get("utm_matchtype");
  const utm_device = searchParams.get("utm_device");
  const utm_adversion = searchParams.get("utm_adversion");
  const utm_extensionid = searchParams.get("utm_extensionid");

  const utmData = {
    medium: utm_medium,
    source: utm_source,
    campaign: utm_campaign,
    name: utm_campaignname,
    term: utm_term,
    matchtype: utm_matchtype,
    device: utm_device,
    adversion: utm_adversion,
    extensionid: utm_extensionid,
  };

  const hasData = Object.values(utmData).some((value) => value !== null);
  if (hasData) {
    localStorage.setItem("utm_data", JSON.stringify(utmData));
  }

  const trackingPreferences = getConsentTrackingPreferences();

  if (trackingPreferences?.custom?.functional === false) {
    return;
  }

  datadogLogs.init({
    clientToken,
    service,
    version,
    proxyHost: "api.client-logs.prismatic.io",
    forwardErrorsToLogs: false, // Console errors are almost never actionable.
  });

  datadogLogs.logger.setLevel(StatusType.info);

  datadogLogs.addLoggerGlobalContext("stack_id", stackId);
};
