import React, { Suspense, useState, lazy } from "react";
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import { GraphQLQuery } from "@/types/GraphQLQuery";
import { VideoPreview, VideoPreviewProps } from "@/components/Video/Preview";

const VideoVimeo = lazy(() =>
  import("@/components/Video/types/Vimeo").then((module) => ({
    default: module.VideoVimeo,
  }))
);

const PREFIX = "PlayerComponent";

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
};

const VideoStyled = styled("div")(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    borderRadius: 10,
    display: "flex",
    overflow: "hidden",
    padding: 0,
    paddingTop: "0 !important",
    "& > div": {
      overflow: "hidden",
      "& iframe": {
        height: "50.4vw",
        marginBottom: -5,
        maxHeight: "78.15vh",
        overflowY: "hidden",
        width: "100%",
        [theme.breakpoints.down(800)]: {
          marginBottom: -6,
          width: "100.2%",
        },
      },
    },
  },
}));

export type PlayerComponentTypes = "vimeo";

export const PlayerComponent = (
  videoId: string,
  type: PlayerComponentTypes
) => {
  switch (true) {
    case type === "vimeo":
      return <VideoVimeo vimeoId={videoId} />;
    default:
      return null;
  }
};

export interface VideoProps {
  alt?: string;
  buttonColor?: VideoPreviewProps["buttonColor"];
  buttonOpacity?: VideoPreviewProps["buttonOpacity"];
  hideButton?: VideoPreviewProps["hideButton"];
  image: string | GraphQLQuery;
  type?: PlayerComponentTypes;
  videoId: string;
}

export const Video = ({
  alt = "Video Preview",
  buttonColor,
  buttonOpacity,
  hideButton,
  image,
  type = "vimeo",
  videoId,
}: VideoProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (value) => {
    window.analytics.track(`Viewed Video - ${alt ?? document.title}`, {
      open: value,
    });
    setOpen(value);
  };

  if (!videoId || !image) {
    return null;
  }

  return (
    <VideoStyled>
      <VideoPreview
        alt={alt}
        buttonColor={buttonColor}
        buttonOpacity={buttonOpacity}
        hideButton={hideButton}
        image={image}
        onClick={() => handleOpen(true)}
      />
      <Suspense>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            style: {
              margin: 0,
              maxHeight: "80vh",
              maxWidth: "138.9vh",
              minHeight: "unset",
              overflow: "unset",
              width: "90%",
            },
          }}
        >
          <DialogContent className={classes.dialogContent}>
            {PlayerComponent(videoId, type)}
          </DialogContent>
          <IconButton
            sx={{
              position: "absolute",
              right: -12,
              top: -12,
              backgroundColor: "#ffffff",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
            onClick={() => setOpen(false)}
            size="medium"
          >
            <CloseRounded />
          </IconButton>
        </Dialog>
      </Suspense>
    </VideoStyled>
  );
};
