import React from "react";
import { styled, Box } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { GraphQLQuery } from "@/types/GraphQLQuery";
import { notForwarded } from "@/utils/notForwarded";

const SliderStyled = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  "&::after, &::before": {
    background: "linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0) 100%)",
    content: '""',
    display: "inline-block",
    height: "100%",
    position: "absolute",
    top: 0,
    width: 50,
    zIndex: 1,
  },
  "&::after": {
    right: 0,
    transform: "rotateZ(180deg)",
  },
  "&::before": {
    left: 0,
  },
  "@keyframes sliderScroll": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-1600px)",
    },
  },
  ".container": {
    animation: "sliderScroll 120s linear infinite",
    display: "grid",
    gridTemplateColumns: "repeat(30, minmax(160px, 1fr))",
  },
}));

export interface SliderProps {
  slides?: SlideProps[];
}

export const Slider = ({ slides: slidesBase }: SliderProps) => {
  if (!slidesBase?.length) {
    return null;
  }

  const slides = Array(10).fill(slidesBase).flat().splice(0, 30);

  return (
    <SliderStyled>
      <div className="container">
        {slides.map((slide, idx) => (
          <Slide key={idx} {...slide} />
        ))}
      </div>
    </SliderStyled>
  );
};

const SlideStyled = styled("div", {
  shouldForwardProp: notForwarded(["image"]),
})<{ image: string }>(({ theme, image }) => ({
  ".logo": {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "110px auto",
    backgroundPosition: "center",
    display: "inline-block",
    height: "50px",
    margin: "auto",
    width: "110px",
  },
}));

export interface SlideProps {
  image: string | GraphQLQuery;
  title: string;
}

const Slide = ({ title, image }: SlideProps) => {
  const backgroundImage =
    typeof image === "string" ? image : image.publicURL || null;

  if (!backgroundImage) {
    return null;
  }

  return (
    <SlideStyled image={backgroundImage}>
      <div className="logo"></div>
      <Box sx={visuallyHidden}>{title}</Box>
    </SlideStyled>
  );
};
